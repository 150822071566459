import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { useOpenHarvestYearsOptions } from "~features/initial-resources";

type Props<TValues extends FieldValues> = {
  values: TValues;
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};
export const FieldsFilters = <TValues extends FieldValues>({
  values,
  onChange,
  onClear,
}: Props<TValues>) => {
  const { data: openHarvestYearsOptions } = useOpenHarvestYearsOptions();

  return (
    <Filters.Root values={values} onChange={onChange}>
      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("userId")}
            label={I18n.t("js.carbon.fields.filters.user_id")}
          />
        )}
      />

      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("id")}
            label={I18n.t("js.carbon.fields.filters.field_id")}
          />
        )}
      />

      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("fieldName")}
            label={I18n.t("js.carbon.field_name_or_identifier")}
          />
        )}
      />

      <Filters.Item
        render={({ control }) => (
          <Controller
            name="actualSubmitted"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.carbon.actuals_submitted")}
              >
                <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                {(openHarvestYearsOptions ?? []).map(year => (
                  <Select.Option
                    key={year?.value}
                    value={year?.value.toString()}
                  >
                    {year?.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        )}
      />

      <Filters.Item
        render={({ control }) => (
          <Controller
            name="fallowActualsYears"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.carbon.fields.fallow_actuals")}
              >
                <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                {(openHarvestYearsOptions ?? []).map(year => (
                  <Select.Option
                    key={year?.value.toString()}
                    value={year?.value.toString()}
                  >
                    {year?.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        )}
      />

      <Filters.ShowMoreItems>
        <Filters.Item
          render={({ control }) => (
            <Controller
              name="definitionSubmitted"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.carbon.report_requested")}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              name="activeContract"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  value={field.value ?? ""}
                  label={I18n.t("js.carbon.fields.filters.active_contract")}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              name="expired"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  value={field.value ?? ""}
                  label="Is expired"
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </SelectField>
              )}
            />
          )}
        />
      </Filters.ShowMoreItems>

      <Filters.Reset onClear={onClear} />

      <Filters.ShowMoreToggle />
    </Filters.Root>
  );
};
