import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

const generateOfferAgreement = async ({ id }: { id: string }) => {
  const response = await axios.post(
    `/api/v1/broker-contracts`,
    { offerId: id },
    { apiSource: "node-carbon" },
  );

  return response.data.success!;
};

export const useGenerateOfferAgreement = () =>
  useMutation(generateOfferAgreement, {
    onError: error => ToastNotification.error(error),
  });
