import clsx from "clsx";
import { useCallback } from "react";

import { Icon } from "@ag/design-system/assets";

import { FLAGS, FlagValue } from "./types";

export type FlagSelectProps = {
  value?: FlagValue[];
  options?: FlagValue[];
  isDisabled?: boolean | FlagValue[];
  isInvalid?: boolean;
  onChange: (values: FlagValue[]) => void;
};

export function FlagSelect({
  value = [],
  options = [...FLAGS],
  isInvalid = false,
  isDisabled = false,
  onChange = () => {},
}: FlagSelectProps) {
  const onToggle = useCallback(
    (flag: FlagValue) => {
      if (value.includes(flag)) {
        onChange(value.filter(existingFlag => flag !== existingFlag));
      } else {
        onChange([...value, flag]);
      }
    },
    [value, onChange],
  );

  const isFlagDisabled = useCallback(
    (flag: FlagValue) => {
      if (typeof isDisabled === "boolean") return isDisabled;

      return isDisabled.includes(flag);
    },
    [isDisabled],
  );

  const flagColors = {
    blue: "text-blue-500",
    green: "text-green-200",
    yellow: "text-yellow-500",
    red: "text-red-500",
    orange: "text-orange-500",
    none: "text-grey-300",
  };

  return (
    <div
      className={clsx("grid grid-flow-col rounded border border-grey-700", {
        "border-red-700": isInvalid,
        "border-grey-700": !isInvalid,
      })}
    >
      {options.map((flag, index) => (
        <button
          key={flag}
          type="button"
          disabled={isFlagDisabled(flag)}
          className={clsx(
            "relative inline-grid place-items-center items-center gap-1 px-2.5 py-2 text-h6 capitalize hover:bg-opal-100",
            {
              "bg-green-400 text-white-100 hover:bg-green-500 active:bg-green-700":
                value.includes(flag),
              "cursor-not-allowed bg-grey-200 text-grey-700":
                isFlagDisabled(flag),
              "cursor-pointer active:bg-grey-300": !isFlagDisabled(flag),
              "rounded-l": index === 0,
              "rounded-r": index === options.length - 1,
              "after:absolute after:inset-y-0 after:right-0 after:border-r after:border-grey-700":
                index !== options.length - 1,
            },
          )}
          onClick={() => onToggle(flag)}
        >
          {flag}
          <Icon
            name="circle"
            variant="solid"
            className={`p-0 text-grey-500 ${flagColors[flag]}`}
          />
        </button>
      ))}
    </div>
  );
}
