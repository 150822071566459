import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, InfoBox } from "@ag/design-system/atoms";
import { Tabs } from "@ag/design-system/organisms";
import { stack } from "@ag/design-system/utils";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import Table from "~components/table";
import {
  RejectModal,
  UpdateRequestSummary,
  useCancelUpdateRequest,
  useUpdateRequestGroupsQuery,
  useUpdateRequestGroupsTable,
  useUpdateRequestQuery,
  useUpdateRequestStore,
  useUpdateRequestSummaryQuery,
} from "~features/certificate";
import {
  UserManagementResourceClass,
  useUserManagementPermissions,
} from "~features/permission";

import * as pageStyles from "../../styles.css";
import * as styles from "./review.css";

type Props = {
  onGoNextStep: () => void;
  unblockRoute: () => void;
};

const ReviewStep = ({ onGoNextStep, unblockRoute }: Props) => {
  const navigate = useRef(useNavigate());
  const { requestId } = useParams<{ requestId: string }>();

  const [pagination, updatePagination] = usePagination();

  const { data: updateRequest } = useUpdateRequestQuery(requestId);
  const { data: summary } = useUpdateRequestSummaryQuery(requestId);

  const { data: certificateGroups } = useUpdateRequestGroupsQuery({
    updateRequestId: requestId,
    ...pagination,
  });

  const { data: userManagementPermissions } = useUserManagementPermissions();

  const canViewUser = userManagementPermissions?.read?.includes(
    UserManagementResourceClass.User,
  );

  const table = useUpdateRequestGroupsTable(
    certificateGroups?.items,
    Boolean(canViewUser),
  );

  const cancelUpdateRequest = useCancelUpdateRequest();

  const { isRejectModalVisible, setIsRejectModalVisible } =
    useUpdateRequestStore();

  const handleRejectRequest = () => {
    try {
      unblockRoute();
      cancelUpdateRequest.mutate(requestId, {
        onSuccess: () => {
          navigate.current("/ledger");
          ToastNotification.success("Request rejected successfully");
        },
      });
      setIsRejectModalVisible(false);
    } catch (error) {
      ToastNotification.error(error);
    }
  };

  const handleOpenRejectModal = () => {
    setIsRejectModalVisible(true);
  };

  return (
    <>
      {isRejectModalVisible && (
        <RejectModal onRejectTransfer={handleRejectRequest} />
      )}

      <div className={styles.container}>
        <h2 className={pageStyles.title}>Review your selection</h2>

        <Tabs.Root defaultValue="summary">
          <Tabs.List>
            <Tabs.Trigger value="summary">
              <Tabs.TriggerTitle>Summary table</Tabs.TriggerTitle>
            </Tabs.Trigger>

            <Tabs.Trigger value="detailed">
              <Tabs.TriggerTitle>Detailed table</Tabs.TriggerTitle>
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value="summary">
            {summary && updateRequest && (
              <UpdateRequestSummary
                summary={summary}
                action={updateRequest.action}
              />
            )}
          </Tabs.Content>

          <Tabs.Content value="detailed">
            <div className={stack({ gap: 16 })}>
              <InfoBox icon="circle-info">
                {summary?.quantity ?? 0} total certificates to be{" "}
                {updateRequest?.action === "transfer"
                  ? "transferred"
                  : "retired"}
                . Status will change from Available to{" "}
                {updateRequest?.action === "transfer"
                  ? "Transferred"
                  : "Retired"}
              </InfoBox>

              {/* Additional div prevents the table + pagination to have the gap */}
              <div>
                <Table
                  instance={table}
                  meta={certificateGroups?.meta}
                  pagination={pagination}
                  onPaginationChange={updatePagination}
                />
              </div>
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>

      <footer className={pageStyles.footer}>
        <div className={pageStyles.nextButtonArea}>
          <div className={pageStyles.nextButtonAndRejectButtonArea}>
            <Button
              variant="secondary"
              isDanger
              onClick={handleOpenRejectModal}
            >
              Reject
            </Button>
            <Button
              icon="chevron-right"
              iconPosition="after"
              onClick={onGoNextStep}
            >
              Next
            </Button>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ReviewStep;
