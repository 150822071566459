import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { useYearsDataQuery } from "~features/initial-resources";

import {
  ContractStatus,
  ContractType,
  CreditingPeriodYears,
} from "../entities/contract";
import {
  getContractStatusLabel,
  getContractTypeLabel,
} from "../helpers/get-labels";

type Props<TValues extends FieldValues> = {
  values: TValues;
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};
export const ContractsFilters = <TValues extends FieldValues>({
  values,
  onChange,
  onClear,
}: Props<TValues>) => {
  const { data: yearsData } = useYearsDataQuery();

  return (
    <Filters.Root values={values} onChange={onChange}>
      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("signerEmail")}
            label={I18n.t("js.admin.contracts.filters.signer_email")}
          />
        )}
      />

      <Filters.Item
        render={({ control }) => (
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.admin.contracts.filters.status.title")}
              >
                {Object.values(ContractStatus).map(status => (
                  <Select.Option key={status} value={status}>
                    {getContractStatusLabel(status)}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        )}
      />

      {yearsData && (
        <Filters.Item
          render={({ control }) => (
            <Controller
              name="year"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.admin.contracts.filters.year")}
                >
                  {yearsData.allHarvestYears.map(year => (
                    <Select.Option key={year} value={String(year)}>
                      {year}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}
        />
      )}

      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("docusignEnvelopeId")}
            label={I18n.t("js.admin.contracts.filters.docusign_envelope_id")}
          />
        )}
      />

      <Filters.ShowMoreItems>
        <Filters.Item
          render={({ control }) => (
            <Controller
              name="contractType"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t(
                    "js.admin.contracts.filters.contract_type.title",
                  )}
                >
                  <>
                    <Select.OptionAll>
                      {I18n.t("js.shared.all")}
                    </Select.OptionAll>

                    {Object.values(ContractType).map(type => (
                      <Select.Option key={type} value={type}>
                        {getContractTypeLabel(type)}
                      </Select.Option>
                    ))}
                  </>
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              name="creditingPeriod"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t(
                    "js.admin.contracts.filters.crediting_period.title",
                  )}
                >
                  {CreditingPeriodYears.map(period => (
                    <Select.Option key={period} value={period.toString()}>
                      {I18n.t("js.admin.contracts.crediting_period.years", {
                        years: period,
                      })}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("userId")}
              label={I18n.t("js.shared.user_id")}
            />
          )}
        />

        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("companyVatNumber")}
              label={I18n.t("js.admin.contracts.filters.company_vat_number")}
            />
          )}
        />

        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("signerPhoneNumber")}
              label={I18n.t("js.admin.contracts.filters.signer_phone_number")}
            />
          )}
        />
      </Filters.ShowMoreItems>

      <Filters.Reset onClear={onClear} />

      <Filters.ShowMoreToggle />
    </Filters.Root>
  );
};
