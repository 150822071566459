import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Textarea } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  value: string | undefined;
  label: string;
  tooltip?: string;
  placeholder?: string;
  error?: FieldError;
  isDisabled?: boolean;
  onChange: (value: string) => void;
} & Pick<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  "cols" | "rows" | "maxLength"
>;

export const TextareaField = forwardRef(
  (
    {
      value,
      label,
      tooltip,
      error,
      isDisabled = false,
      onChange,
      ...rest
    }: Props,
    _ref,
  ) => {
    return (
      <FormField.Root>
        <FormField.Label tooltip={tooltip}>{label}</FormField.Label>

        <Textarea
          {...rest}
          value={value}
          characterCount={value?.length ?? 0}
          disabled={isDisabled}
          isInvalid={Boolean(error)}
          onChange={event => onChange(event.target.value)}
          aria-label={label}
          role="textbox"
        />

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

TextareaField.displayName = "TextareaField";
