import { useState } from "react";

import { Icon } from "@ag/design-system/assets";
import { colorsObject } from "@ag/design-system/tokens";
import { cn } from "@ag/design-system/utils";

import IconPolygon from "../assets/icon-polygon";

type Props = {
  activeBoundaryDisplayColor: Record<number, string>;
  onActiveBoundaryDisplayColorChange: (color: Record<number, string>) => void;
  isActiveBoundaryOpacityOn: boolean;
  onActiveBoundaryOpacityChange: (isActiveBoundaryOpacityOn: boolean) => void;
};

const BoundaryAppearanceToggles = ({
  activeBoundaryDisplayColor,
  onActiveBoundaryDisplayColorChange,
  isActiveBoundaryOpacityOn,
  onActiveBoundaryOpacityChange,
}: Props) => {
  const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false);

  const colorOptions = [
    {
      color: "blue",
      object: colorsObject.data.blue,
      bg: "bg-data-blue-400",
      border: "border-data-blue-700",
    },
    {
      color: "purple",
      object: colorsObject.data.purple,
      bg: "bg-data-purple-400",
      border: "border-data-purple-700",
    },
    {
      color: "orange",
      object: colorsObject.data.orange,
      bg: "bg-data-orange-400",
      border: "border-data-orange-700",
    },
  ];

  const colorSelectorButtonBgClass = isColorSelectorOpen
    ? "bg-[#4730DB]"
    : "bg-white-100";

  return (
    <div className="absolute bottom-[44px] left-[18px]">
      <div>
        <div
          className={cn(
            "flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-t border-x-2 border-b border-t-2 border-[#4730DB] p-2",
            isActiveBoundaryOpacityOn ? "bg-[#4730DB]" : "bg-white-100",
          )}
          onClick={() => onActiveBoundaryOpacityChange(true)}
        >
          <IconPolygon
            fillColor="#9281FF"
            strokeColor={isActiveBoundaryOpacityOn ? "#FFFFFF" : "#4730DB"}
          />
        </div>
        <div
          className={cn(
            "flex h-[40px] w-[40px] cursor-pointer items-center justify-center border-x-2 border-b border-t border-[#4730DB] p-2",
            isActiveBoundaryOpacityOn ? "bg-white-100" : "bg-[#4730DB]",
          )}
          onClick={() => onActiveBoundaryOpacityChange(false)}
        >
          <IconPolygon
            fillColor="transparent"
            strokeColor={!isActiveBoundaryOpacityOn ? "#FFFFFF" : "#4730DB"}
          />
        </div>
      </div>
      <div className="flex cursor-pointer flex-row gap-1">
        <div
          onClick={() => setIsColorSelectorOpen(!isColorSelectorOpen)}
          className={cn(
            "flex h-[40px] w-[40px] items-center justify-center rounded-b border-x-2 border-b-2 border-t border-[#4730DB] p-2",
            colorSelectorButtonBgClass,
          )}
        >
          <Icon
            name="fill-drip"
            fontSize={20}
            color={isColorSelectorOpen ? "#FFFFFF" : "#4730DB"}
          />
        </div>
        {isColorSelectorOpen && (
          <div
            className={cn(
              "bordergrey-700 flex items-center justify-center gap-2 rounded-sm bg-white-100 px-2 py-1.5",
            )}
          >
            {colorOptions.map(color => (
              <div
                key={color.color}
                className={cn(
                  "h-[26px] w-[26px] rounded-full p-[3px]",
                  activeBoundaryDisplayColor[100] === color.object[100] &&
                    "bg-[#4730DB]",
                )}
              >
                <div
                  className={cn(
                    "h-[20px] w-[20px] rounded-full border-2",
                    color.bg,
                    color.border,
                  )}
                  onClick={() =>
                    onActiveBoundaryDisplayColorChange(color.object)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BoundaryAppearanceToggles;
