import { useState } from "react";

import { Icon } from "@ag/design-system/assets";
import {
  Button,
  Dropzone,
  FileRejection,
  InfoBox,
} from "@ag/design-system/atoms";

type Props = {
  onFileDropSuccess: (file: File) => void;
  acceptedFileType?: ".zip" | ".csv" | ".pdf";
  fileDropHeaderText: string;
  fileDropInfoText: string;
};

const FileUploader = ({
  onFileDropSuccess,
  acceptedFileType,
  fileDropHeaderText,
  fileDropInfoText,
}: Props) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileErrors, setFileErrors] = useState<string[] | undefined>(undefined);

  const handleFileDropped = (
    acceptedFile: File | undefined,
    fileRejection: FileRejection | undefined,
  ) => {
    setFileErrors(undefined);

    if (fileRejection) {
      const errors = fileRejection.errors.map(error => {
        if (error.code === "file-too-large") {
          return "This file is too big, the maximum file size is 1MB";
        }

        return error.message;
      });

      setFileErrors(errors);

      return;
    }

    if (acceptedFile) {
      setFile(acceptedFile);
      onFileDropSuccess(acceptedFile);
    }
  };

  return (
    <div>
      {fileErrors?.map(fileError => (
        <InfoBox key={fileError} variant="danger" icon="triangle-exclamation">
          {fileError}
        </InfoBox>
      ))}

      <div className="h-77 pt-6">
        <Dropzone
          acceptType={acceptedFileType}
          dropText={"Drop files here"}
          maxSize={1} // max 1MB
          value={file}
          onChange={handleFileDropped}
        >
          <div className="flex flex-col items-center justify-center space-y-4 p-8">
            <Icon name="file-arrow-up" className="bg-gray-200 rounded" />

            <div className="flex flex-col items-center justify-center space-y-1">
              <h5 className="text-h5">{fileDropHeaderText}</h5>

              <h6 className="text-h6 text-grey-700">{fileDropInfoText}</h6>
            </div>

            <span className="text-h5">or</span>

            {/* leave empty onClick method because event will be propagated up to the parent dropzone component */}
            <Button size="small" variant="secondary">
              Select from folder
            </Button>
          </div>
        </Dropzone>
      </div>
    </div>
  );
};

export default FileUploader;
