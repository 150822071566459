import { RowSelectionState } from "@tanstack/react-table";
import { uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useHref, useParams } from "react-router-dom";
import { StringParam, createEnumParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { Button } from "@ag/design-system/atoms";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { usePagination } from "@ag/utils/hooks";

import BackButton from "~components/BackButton";
import Table from "~components/table";
import { env } from "~config";
import { FieldValidationStatus } from "~features/field";
import { AuthorizedSidebar } from "~features/navigation";
import { useSignInAsMutation } from "~features/user";
import {
  ValidationRequestFieldsActions,
  ValidationRequestFieldsFilters,
  useValidationRequestFieldsBulkActions,
  useValidationRequestFieldsQuery,
  useValidationRequestFieldsTable,
  useValidationRequestQuery,
} from "~features/validation-request";
import ListLayout from "~layouts/list-layout";

const ValidationStatusParam = createEnumParam(
  Object.values(FieldValidationStatus),
);

const filtersConfig = {
  carbonFarmId: StringParam,
  fieldName: [StringParam, { isDebounced: true }],
  id: [StringParam, { isDebounced: true }],
  validationStatus: ValidationStatusParam,
} as const;

const ValidationRequestFields = () => {
  const params = useParams<{ validationRequestId: string }>();
  const validationRequestId = params.validationRequestId!;

  const [pagination, setPagination] = usePagination();
  const filters = useFilters(filtersConfig);

  const { selectedFields, setSelectedFields, bulkAction } =
    useValidationRequestFieldsBulkActions(validationRequestId);

  const { data: validationRequest, isLoading: isValidationRequestLoading } =
    useValidationRequestQuery(validationRequestId);

  const userLink = useHref(`/users/${validationRequest?.carbonUser.userId}`);

  const selection = useMemo(() => {
    const lookup: RowSelectionState = {};
    for (const field of selectedFields) {
      lookup[field.id] = true;
    }
    return lookup;
  }, [selectedFields]);

  const { validationStatus, carbonFarmId, id } = filters.values;

  const { data: fieldsData, isLoading: isFieldsLoading } =
    useValidationRequestFieldsQuery(validationRequestId, {
      ...pagination,
      filters: {
        ...filters.values,
        validationStatus,
        carbonFarmId,
        id,
      },
    });

  const signInAsMutation = useSignInAsMutation();

  const handleSignInAs = () => {
    if (!validationRequest) return;

    signInAsMutation.mutate(validationRequest?.carbonUser.userId.toString(), {
      onSuccess: ({ ticket }) => {
        const params = getSearchParams({
          [SIGN_IN_AS_PARAM]: ticket,
        });

        window.open(env.REACT_APP_FARMER_URL + params);
      },
    });
  };

  const farms = useMemo(
    () =>
      uniqBy(
        (validationRequest?.carbonFields ?? []).map(field => field.carbonFarm),
        "id",
      ),
    [validationRequest],
  );

  const isSelectionEnabled = useMemo(
    () => validationRequest?.status === "new",
    [validationRequest?.status],
  );
  const handleSetSelection = useCallback(
    (
      updater:
        | RowSelectionState
        | ((state: RowSelectionState) => RowSelectionState),
    ) => {
      if (!fieldsData) return;

      const lookup =
        typeof updater === "function" ? updater(selection) : updater;

      setSelectedFields(
        fieldsData.items.filter(field => lookup[field.id] === true),
      );
    },
    [fieldsData, selection, setSelectedFields],
  );

  const table = useValidationRequestFieldsTable({
    fields: fieldsData?.items ?? [],
    farms,
    selection,
    isSelectionEnabled,
    setSelection: handleSetSelection,
  });

  return (
    <ListLayout.Root>
      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle
          title={`Validation details - Carbon User ${
            validationRequest?.carbonUser.userId ?? ""
          }`}
        >
          {validationRequest ? (
            <span
              dangerouslySetInnerHTML={{
                __html: `Validation details - Carbon User <a href="${userLink}">${validationRequest.carbonUser.userId}</a>`,
              }}
            />
          ) : null}
        </ListLayout.TopBarTitle>

        <ListLayout.TopBarActions>
          <Button
            onClick={handleSignInAs}
            isLoading={isValidationRequestLoading}
          >
            Sign in as
          </Button>
        </ListLayout.TopBarActions>
      </ListLayout.TopBar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <ValidationRequestFieldsFilters farms={farms} {...filters} />
          </ListLayout.Filters>

          <ListLayout.Actions>
            <ValidationRequestFieldsActions
              selectedFields={selectedFields}
              onBulkReject={() => bulkAction("reject")}
              onBulkValidate={() => bulkAction("validate")}
            />
          </ListLayout.Actions>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fieldsData?.meta}
          pagination={pagination}
          isLoading={isFieldsLoading}
          onPaginationChange={setPagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default ValidationRequestFields;
