import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ActionsCell,
  ActionsCellValue,
  LinkCell,
  LinkCellValue,
} from "@ag/design-system/organisms";

import { ACTIONS_CELL_WIDTH, useTable } from "~components/table";
import {
  UserManagementPermissions,
  UserManagementResourceClass,
} from "~features/permission";

import { User } from "../entities/user";

type TableData = {
  actions: ActionsCellValue;
  id: LinkCellValue;
  company: string | undefined;
  name: string;
  hectares: number | string | null | undefined;
  phone: string | null;
  email: string;
  country: string | undefined;
  address: string | undefined;

  user: User;
};

type UseAssignedUsersTableData = {
  users: User[] | undefined;
  userManagementPermissions: UserManagementPermissions | undefined;
};

type UseAssignedUsersTableActions = {
  onDeleteUserAssignment: (userId: string) => void;
};

export const useAssignedUsersTable = ({
  users,
  userManagementPermissions,
  onDeleteUserAssignment,
}: UseAssignedUsersTableData & UseAssignedUsersTableActions) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(
    () =>
      getRowData(
        { users, userManagementPermissions },
        { onDeleteUserAssignment },
      ),
    [users, userManagementPermissions, onDeleteUserAssignment],
  );

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => String(original.user.id),
    getCoreRowModel: getCoreRowModel(),
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("actions", {
      header: "Actions",
      cell: ActionsCell,
      size: ACTIONS_CELL_WIDTH,
    }),
    columnHelper.accessor("id", {
      header: "Id",
      cell: LinkCell,
    }),
    columnHelper.accessor("company", {
      header: "Company",
    }),
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor("hectares", {
      header: "ha.",
    }),
    columnHelper.accessor("phone", {
      header: "Phone",
    }),
    columnHelper.accessor("email", {
      header: "Email",
    }),
    columnHelper.accessor("country", {
      header: "Country",
    }),
    columnHelper.accessor("address", {
      header: "Address",
    }),
  ];
}

type GetRowDataData = {
  users: User[] | undefined;
  userManagementPermissions: UserManagementPermissions | undefined;
};

type GetRowDataCallbacks = {
  onDeleteUserAssignment: (userId: string) => void;
};

function getRowData(
  { users, userManagementPermissions }: GetRowDataData,
  { onDeleteUserAssignment }: GetRowDataCallbacks,
): TableData[] {
  if (!users) return [];

  const hasDeleteUserAssignmentAccess =
    userManagementPermissions?.delete?.includes(
      UserManagementResourceClass.AdminUserAssignment,
    );

  return users.map(user => ({
    actions: {
      title: "Actions",
      items: [
        ...(hasDeleteUserAssignmentAccess
          ? [
              {
                children: "Remove",
                onClick: () => onDeleteUserAssignment(user.id),
              },
            ]
          : []),
      ],
    },
    id: {
      title: user.id,
      url: `/users/${user.id}`,
    },
    company: user.company?.name,
    name: user.name,
    hectares: user.freightAndTradeSetting?.totalFieldsSizeHa,
    phone: user.profile.phoneNumber,
    email: user.email,
    country: user.company?.address.country.name,
    address: user.company?.address.address,

    user,
  }));
}
