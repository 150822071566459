import { cva } from "class-variance-authority";

import { fixedForwardRef } from "@ag/utils/types";

import { ICONS_REGULAR, ICONS_SOLID, Icon, getIconConfig } from "~assets";
import { cn } from "~utils";

import { ButtonSelectProvider, useButtonSelectContext } from "./context";
import { ButtonSelectValue } from "./types";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
export type RootProps<TValue extends ButtonSelectValue> =
  React.PropsWithChildrenRequired<{
    size?: "medium" | "small";
    testId?: string;
    value?: TValue | null;
    onChange?: (value: TValue) => void;
    isDisabled?: boolean;
    isInvalid?: boolean;
  }>;

export const Root = fixedForwardRef(RootComponent);

function RootComponent<TValue extends ButtonSelectValue>(
  {
    children,
    value,
    size = "medium",
    testId,
    isInvalid = false,
    isDisabled = false,
    onChange = () => {},
  }: RootProps<TValue>,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <ButtonSelectProvider
      value={value}
      isDisabled={Boolean(isDisabled)}
      onChange={onChange}
      isInvalid={Boolean(isInvalid)}
      size={size}
    >
      <div className="inline-flex" data-testid={testId} ref={ref}>
        {children}
      </div>
    </ButtonSelectProvider>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Option
 * -----------------------------------------------------------------------------------------------*/
const buttonVariants = cva(
  [
    "inline-flex items-center gap-2 border border-r-0 border-solid border-grey-700 bg-white-100 text-h4 text-grey-900",
    "first:rounded-l",
    "last:rounded-r last:border-r",
    "cursor-pointer hover:bg-opal-100",
    "disabled:bg-grey-200 disabled:text-grey-700",
    "active:bg-opal-300",
  ],
  {
    variants: {
      size: {
        medium: "px-4 py-2",
        small: "px-4 py-[5px] text-h5",
      },
      isInvalid: {
        true: "border-red-700",
        false: "",
      },
      isSelected: {
        true: "bg-green-400 text-white-100 hover:bg-green-500 active:bg-green-700 disabled:bg-grey-400 disabled:text-white-100",
        false: "",
      },
    },
  },
);

type ButtonSelectOptionIconProps =
  | {
      icon: keyof typeof ICONS_REGULAR;
      iconVariant?: "regular" | undefined;
    }
  | {
      icon: keyof typeof ICONS_SOLID;
      iconVariant: "solid";
    }
  | {
      icon?: never;
      iconVariant?: never;
    };

type ButtonSelectOptionProps<TValue extends ButtonSelectValue> =
  React.PropsWithChildren<{
    value: TValue;
    isDisabled?: boolean;
    "aria-label"?: string;
  }> &
    ButtonSelectOptionIconProps;

export function Option<TValue extends ButtonSelectValue>(
  props: ButtonSelectOptionProps<TValue>,
) {
  const icon = props.icon && getIconConfig(props, "icon", "iconVariant");

  const {
    value: selectedValue,
    isDisabled: isAllDisabled,
    onChange,
    isInvalid,
    size,
  } = useButtonSelectContext<TValue>();

  const isSelected =
    typeof selectedValue !== "undefined" && props.value === selectedValue;

  return (
    <button
      type="button"
      disabled={props.isDisabled || isAllDisabled}
      className={cn(buttonVariants({ isInvalid, size, isSelected }))}
      onClick={() => {
        if (props.value !== selectedValue) {
          onChange(props.value);
        }
      }}
      role="button"
      aria-label={props["aria-label"]}
    >
      {icon && <Icon {...icon} />}
      {props.children}
    </button>
  );
}
