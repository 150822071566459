import {
  OnChangeFn,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { Row } from "react-table";

import {
  ChipCell,
  ChipCellValue,
  LinkCell,
  LinkCellValue,
  TextCell,
} from "@ag/design-system/organisms";

import { useTable } from "~components/table";

import { SalesOffer } from "../entities/sales-offer";
import {
  formatCurrency,
  getRecommendedContractNames,
} from "../helpers/formatters";
import { getOfferStatusVariant } from "../helpers/offer-statuses";
import { getKycStatusVariant } from "../helpers/sales-offers-statuses";

type TableData = {
  userId: LinkCellValue | string;
  id: LinkCellValue;
  signerName: string;
  status: ChipCellValue;
  kycStatus: ChipCellValue;
  contacted: string;
  offerAccepted: string;
  recommendedContract: string;
  totalPurchaseAmount: string;
  totalPayout: string;
  harvestYear: string;
  salesOffer: SalesOffer;
};

export type ActionColumnProps = {
  row: Row<TableData>;
};

type UseSalesOffersState = {
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
};

export const useSalesOffersTable = (
  data: SalesOffer[] | undefined,
  state: UseSalesOffersState,
  hasUserPermissions: boolean,
  query: string,
) => {
  const columns = useMemo(
    () => getColumns(hasUserPermissions),
    [hasUserPermissions],
  );
  const rowData = useMemo(() => getRowData(data, query), [data, query]);
  return useTable<TableData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: original => String(original.salesOffer.id),
    state: {
      sorting: state.sorting,
    },
    onSortingChange: state.setSorting,
    enableMultiSort: false,
  });
};

function getColumns(hasUserPermissions: boolean) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: "Offer ID",
      cell: LinkCell,
      enableSorting: true,
    }),
    columnHelper.accessor("userId", {
      header: "User ID",
      cell: hasUserPermissions ? LinkCell : TextCell,
    }),
    columnHelper.accessor("signerName", {
      header: "Signer Name",
    }),
    columnHelper.accessor("status", {
      header: "Offer Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("kycStatus", {
      header: "KYC Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("contacted", {
      header: "Contacted",
    }),
    columnHelper.accessor("offerAccepted", {
      header: "Offer Accept.",
    }),
    columnHelper.accessor("recommendedContract", {
      header: "Rec. Contract",
    }),
    columnHelper.accessor("totalPurchaseAmount", {
      header: "Tot Pur. Amt",
    }),
    columnHelper.accessor("totalPayout", {
      header: "Tot. Pay. Amt",
    }),
    columnHelper.accessor("harvestYear", {
      header: "Harvest Year",
      enableSorting: true,
    }),
  ];
}

function getRowData(
  salesOffers: SalesOffer[] | undefined,
  query: string,
): TableData[] {
  if (!salesOffers) return [];

  return salesOffers.map(salesOffer => ({
    id: {
      title: salesOffer.id,
      url: `/carbon/agreements/offers/${salesOffer.id}`,
      state: { query },
    },
    userId: {
      title: salesOffer.userId,
      url: `/users/${salesOffer.userId}`,
    },
    signerName: salesOffer.signerDetails.name,
    status: salesOffer.status && getOfferStatusVariant(salesOffer.status),
    kycStatus:
      salesOffer.kycStatus && getKycStatusVariant(salesOffer.kycStatus),
    contacted: salesOffer.contacted ? "Yes" : "No",
    offerAccepted: salesOffer.accepted ? "Yes" : "No",
    recommendedContract: getRecommendedContractNames(
      salesOffer.offerDetails.suggestedContractType,
    ),
    totalPurchaseAmount: formatCurrency(
      salesOffer.offerDetails.totalPurchaseAmount,
    ),
    totalPayout: formatCurrency(salesOffer.offerDetails.totalPayableAmount),
    harvestYear: String(salesOffer.harvestYear) || "",
    salesOffer,
  }));
}
