import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";

type Props<TValues extends FieldValues> = {
  values: TValues;
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};

export const FertilisersFilters = <TValues extends FieldValues>({
  values,
  onChange,
  onClear,
}: Props<TValues>) => {
  const { data: openHarvestYearsOptions } = useOpenHarvestYearsOptions();

  return (
    <Filters.Root values={values} onChange={onChange}>
      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("matchFertiliserName")}
            label={I18n.t("js.carbon.fertilisers.filters.name")}
          />
        )}
      />

      <Filters.Item
        render={({ control }) => (
          <Controller
            name="harvestYear"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.carbon.fertilisers.filters.harvest_year")}
              >
                <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                {(openHarvestYearsOptions ?? []).map(harvestYear => {
                  return (
                    <Select.Option
                      key={harvestYear?.value}
                      value={harvestYear?.value.toString()}
                    >
                      {harvestYear?.label}
                    </Select.Option>
                  );
                })}
              </SelectField>
            )}
          />
        )}
      />

      <Filters.Item
        render={({ control }) => (
          <Controller
            name="methodologyVersion"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.shared.methodology_version")}
              >
                <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>
                {METHODOLOGY_VERSIONS_OPTIONS.map(version => (
                  <Select.Option
                    key={version?.value}
                    value={version?.value.toString()}
                  >
                    {version?.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        )}
      />

      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("matchCoolfarmName")}
            label={I18n.t("js.carbon.fertilisers.filters.coolfarm_name")}
          />
        )}
      />

      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("coolfarmIdentifier")}
            label={I18n.t("js.carbon.fertilisers.filters.coolfarm_id")}
          />
        )}
      />

      <Filters.Reset onClear={onClear} />
    </Filters.Root>
  );
};
