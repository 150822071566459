import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { NumberParam, StringParam, createEnumParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { parseSorting } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import {
  ContractStatus,
  ContractType,
  ContractsFilters,
  CreditingPeriodYears,
  useContractsQuery,
  useContractsTable,
} from "~features/contract";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const ContractTypeParam = createEnumParam(Object.values(ContractType));
const CreditingPeriodParam = createEnumParam(
  CreditingPeriodYears.map(value => value.toString()),
);
const ContractStatusParam = createEnumParam(Object.values(ContractStatus));

const filtersConfig = {
  contractType: ContractTypeParam,
  creditingPeriod: CreditingPeriodParam,
  status: ContractStatusParam,
  year: NumberParam,
  docusignEnvelopeId: [StringParam, { isDebounced: true }],
  companyVatNumber: [StringParam, { isDebounced: true }],
  signerEmail: [StringParam, { isDebounced: true }],
  signerPhoneNumber: [StringParam, { isDebounced: true }],
  userId: [StringParam, { isDebounced: true }],
} as const;

const Contracts = () => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const filters = useFilters(filtersConfig);

  const {
    contractType,
    creditingPeriod,
    status,
    year,
    docusignEnvelopeId,
    companyVatNumber,
    signerEmail,
    signerPhoneNumber,
    userId,
  } = filters.values;

  const { data: contractsData, isLoading } = useContractsQuery({
    ...pagination,
    sort: parseSorting(sorting),
    filters: {
      contractType: contractType ? [contractType] : undefined,
      creditingPeriod: creditingPeriod ? [creditingPeriod] : undefined,
      status: status ? [status] : undefined,
      year: year ? [year] : undefined,
      docusignEnvelopeId: docusignEnvelopeId ? [docusignEnvelopeId] : undefined,
      companyVatNumber: companyVatNumber ? [companyVatNumber] : undefined,
      signerEmail: signerEmail ? [signerEmail] : undefined,
      signerPhoneNumber: signerPhoneNumber ? [signerPhoneNumber] : undefined,
      userId: userId ? [userId] : undefined,
    },
  });

  const table = useContractsTable(contractsData?.items, {
    sorting,
    setSorting,
  });

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Contracts</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <ContractsFilters
              {...filters}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
              }}
              onClear={() => {
                filters.onClear();
                resetPagination();
              }}
            />
          </ListLayout.Filters>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={contractsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Contracts;
