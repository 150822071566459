import styled from "@emotion/styled";

import { H5Typography, style } from "@ag/ui";

export const SBanner = styled.div`
  ${H5Typography};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 8px;

  background: ${style.color.orange[600]};
  color: ${style.color.white[100]};
  border-radius: ${style.borderRadius}px;
`;
