import styled from "@emotion/styled";

import { FormCheckbox } from "@ag/components/form";
import { H6Typography, style } from "@ag/ui";

export const SFallow = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

export const SFormCheckbox = styled(FormCheckbox)`
  margin-left: 16px;
`;

export const SUnsavedIndicator = styled.span`
  ${H6Typography}

  text-transform: uppercase;
  margin-left: 16px;
  color: ${style.color.grey[400]};
`;
