import { SContent } from "./styled";

type Props = React.PropsWithChildrenRequired<{
  padding: boolean | undefined;
}>;

const Content = ({ children, padding }: Props) => (
  <SContent padding={padding}>{children}</SContent>
);

export default Content;
