import styled from "@emotion/styled";

import { style } from "@ag/ui";

type SContentProps = {
  padding: boolean | undefined;
};

export const SContent = styled.main`
  grid-area: content;
  background: ${style.color.white[200]};
  padding: 0 16px;
  overflow-y: auto;

  ${({ padding }: SContentProps): string => {
    let styles = ``;

    if (padding === false) {
      styles += `
        padding: 0;
      `;
    }

    return styles;
  }}
`;
