import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup } from "@ag/components/form";
import { P2, SCol, SFlexDiv } from "@ag/components/styled";
import I18n from "@ag/i18n";
import { getYesNoOptions } from "@ag/utils/helpers";

import { SFormRow, SFormSubmitButtonContainer } from "~forms/styled";

import {
  HistoryOfDeforestationAttribute,
  HistoryOfDeforestationData,
} from "./types";

type Props = {
  isSubmitting: boolean;
  isReadonly?: boolean | undefined;
  isSubmitButtonVisible?: boolean;
  onFormInitialize: (form: FormApi<HistoryOfDeforestationData>) => void;
};

export const HistoryOfDeforestationForm = ({
  isSubmitButtonVisible = true,
  isReadonly = false,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<HistoryOfDeforestationData>();
  const { pristine } = useFormState<HistoryOfDeforestationData>();

  useEffect(() => {
    onFormInitialize(form);
  }, [form, onFormInitialize]);

  return (
    <>
      <SFormRow>
        <SCol>
          <P2>{I18n.t("js.carbon.soil_management_practices_description")}</P2>
        </SCol>
      </SFormRow>

      <SFormRow>
        <SCol>
          <SFlexDiv direction="column">
            <Field
              component={FormButtonsGroup}
              label={I18n.t(
                "js.carbon.has_field_been_converted_in_the_last_20_years",
              )}
              wrapperTestTag="historyOfDeforestation-soilMgmtHistoricConversionButtonGroup"
              name={HistoryOfDeforestationAttribute.SoilMgmtHistoricConversion}
              buttons={getYesNoOptions()}
              hint={I18n.t(
                "js.carbon.soil_mgmt_historic_conversion_checkbox_instruction",
              )}
              disabled={isReadonly}
            />
          </SFlexDiv>
        </SCol>
      </SFormRow>

      {isSubmitButtonVisible && !isReadonly && (
        <SFormSubmitButtonContainer>
          <ActionButton
            HTMLType="submit"
            testTag="historyOfDeforestation-submitButton"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}
    </>
  );
};
