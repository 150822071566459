import get from "lodash/get";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { BaselineVersion } from "@ag/carbon/types";
import { transformInitialFilterValues } from "@ag/components/Filters";
import { ComboBox, MultiSelect, Select } from "@ag/design-system/molecules";
import { stack } from "@ag/design-system/utils";
import {
  ComboBoxField,
  InputField,
  MultiSelectField,
  SelectField,
  ToggleField,
} from "@ag/form-fields";
import I18n from "@ag/i18n";
import { FieldActualsStatus } from "@ag/utils/types";

import { CarbonCountry } from "~features/countries";

import { AssuranceStatus } from "../entities/assurance";
import {
  getAssuranceStatusLabel,
  getFieldActualsStatusLabel,
} from "../helpers/get-labels";
import { AssuranceFilterData } from "../types/filters";
import { FieldsCheckFilters } from "./fields-check-filters";

type Props = {
  values: AssuranceFilterData;
  countries: CarbonCountry[];
  onChange: (
    name: keyof AssuranceFilterData,
    value: AssuranceFilterData[keyof AssuranceFilterData],
  ) => void;
  onClear: (name?: keyof AssuranceFilterData) => void;
};

export const FieldsFilters = ({
  values,
  countries,
  onChange,
  onClear,
}: Props) => {
  /**
   * React hook form has a different way of working with array data, so we need to omit "flags" in this case.
   * Maybe later if/when we rework flag filters, we can use _their_ way instead of this little hack.
   */
  const form = useForm<AssuranceFilterData>({
    values: transformInitialFilterValues(values),
  });
  const { control, register } = form;

  useEffect(() => {
    const subscription = form.watch((newValues, options) => {
      const name = options.name as keyof AssuranceFilterData;

      if (!name) return;

      // react-hook-form parses the name with a dot to an object, but the stringified format is still in the values object
      if (name.includes(".")) {
        delete newValues[name];
      }

      const newValue = get(newValues, name);

      // Get rid of "false" values for some keys - API doesn't support them
      if (
        ["allGreenFlag", "hasComments"].includes(name) &&
        newValue === false
      ) {
        onChange(name, undefined);
        return;
      }

      onChange(name, newValue as any);
    });

    return () => subscription.unsubscribe();
  }, [form, onChange]);

  return (
    <div className={stack({ gap: 16 })}>
      <InputField
        {...register("userId")}
        type="number"
        label={getAssuranceFilterLabel("userId")}
      />

      <InputField
        {...register("id")}
        label={getAssuranceFilterLabel("id")}
        type="number"
      />

      <Controller
        name="countryId"
        control={control}
        render={({ field, fieldState }) => (
          <ComboBoxField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getAssuranceFilterLabel("countryId")}
            loadingText={I18n.t("js.shared.loading")}
            emptyText={I18n.t("js.shared.no_matching_results")}
          >
            {countries.map(country => (
              <ComboBox.Item key={country.id}>{country.name}</ComboBox.Item>
            ))}
          </ComboBoxField>
        )}
      />

      <Controller
        name="actualStatus"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getAssuranceFilterLabel("actualStatus")}
          >
            <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

            {Object.entries(FieldActualsStatus).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {getFieldActualsStatusLabel(value)}
              </Select.Option>
            ))}
          </SelectField>
        )}
      />

      <Controller
        name="qaStatus"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getAssuranceFilterLabel("qaStatus")}
          >
            <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

            {Object.entries(AssuranceStatus).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {getAssuranceStatusLabel(value)}
              </Select.Option>
            ))}

            <Select.Option key="none" value="none">
              {I18n.t("js.shared.none")}
            </Select.Option>
          </SelectField>
        )}
      />

      <Controller
        name="baselineVersion"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getAssuranceFilterLabel("baselineVersion")}
          >
            <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

            {Object.entries(BaselineVersion).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {value}
              </Select.Option>
            ))}
          </SelectField>
        )}
      />

      <MultiSelectField
        control={control}
        name="hummingbirdDataAvailability"
        label={getAssuranceFilterLabel("hummingbirdDataAvailability")}
        placeholder={I18n.t("js.shared.select")}
        renderSelection={selection =>
          I18n.t("js.shared.multi_select.selection", {
            count: selection.length,
          })
        }
        variant="full-width"
      >
        {[0, 0.25, 0.5, 0.75, 1].map(value => (
          <MultiSelect.Item key={value} textValue={String(value)}>
            {value * 100}%
          </MultiSelect.Item>
        ))}
      </MultiSelectField>

      <ToggleField
        {...register("allGreenFlag")}
        label={getAssuranceFilterLabel("allGreenFlag")}
      />

      <ToggleField
        {...register("hasComments")}
        label={getAssuranceFilterLabel("hasComments")}
      />

      <hr style={{ width: "100%" }} />

      <FieldsCheckFilters
        form={form}
        filtersValues={values}
        onClear={onClear}
      />
    </div>
  );
};

export function getAssuranceFilterLabel(key: keyof AssuranceFilterData) {
  const lookup: Partial<Record<keyof AssuranceFilterData, string>> = {
    userId: I18n.t("js.admin.assurance.filters.user_id"),
    id: I18n.t("js.admin.assurance.filters.field_id"),
    allGreenFlag: I18n.t("js.admin.assurance.filters.green_flags_only"),
    actualStatus: I18n.t("js.admin.assurance.filters.actuals_status"),
    qaStatus: I18n.t("js.admin.assurance.filters.status"),
    countryId: I18n.t("js.admin.assurance.filters.country"),
    baselineVersion: I18n.t("js.admin.assurance.filters.baseline"),
    hummingbirdDataAvailability: I18n.t(
      "js.carbon.qa.hummingbird_data_availability",
    ),
    hasComments: I18n.t("js.admin.assurance.has_comments"),

    "deforestation.value": I18n.t("js.admin.assurance.category.deforestation"),
    "deforestation.flag": I18n.t("js.admin.assurance.deforestation_flag"),

    "duplication.flag": I18n.t("js.admin.assurance.duplication_flag"),

    "cover_crop.value": I18n.t("js.admin.assurance.category.cover_crop"),
    "cover_crop.flag": I18n.t("js.admin.assurance.cover_crop_flag"),

    "soil_disturbance.value": I18n.t(
      "js.admin.assurance.category.soil_disturbance",
    ),
    "soil_disturbance.flag": I18n.t("js.admin.assurance.soil_disturbance_flag"),

    "field_size_reported_size.flag": I18n.t(
      "js.admin.assurance.reported_size_flag",
    ),
    "field_size_reported_size.value.min": I18n.t(
      "js.admin.assurance.reported_size_min",
    ),
    "field_size_reported_size.value.max": I18n.t(
      "js.admin.assurance.reported_size_max",
    ),

    // TODO: rename `intersection_sum` -> `intersection`
    "intersection_sum.flag": I18n.t("js.admin.assurance.intersection_flag"),
    "intersection_sum.value.min": I18n.t("js.admin.assurance.intersection_min"),
    "intersection_sum.value.max": I18n.t("js.admin.assurance.intersection_max"),
  };

  return lookup[key] ?? key;
}

export function getAssuranceFilterValueLabel(
  key: keyof AssuranceFilterData,
  value: AssuranceFilterData[keyof AssuranceFilterData],
  options: {
    countries: CarbonCountry[] | undefined;
  },
) {
  if (value == null) return null;

  if (key === "countryId") {
    const countryMatch = options.countries?.find(
      country => country.id === Number(value),
    );
    return countryMatch?.name ?? value;
  }

  if (key === "qaStatus") {
    return value === "none"
      ? I18n.t("js.shared.none") // special filter to show fields without any status.
      : getAssuranceStatusLabel(value as AssuranceStatus);
  }

  if (key === "actualStatus")
    return getFieldActualsStatusLabel(value as FieldActualsStatus);

  if (key === "hummingbirdDataAvailability") {
    return (value as (string | null)[]).map(item => `${Number(item) * 100}%`);
  }

  if (typeof value === "boolean") {
    return value ? I18n.t("js.shared.yes") : I18n.t("js.shared.no");
  }

  return value;
}
