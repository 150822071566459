import { SBackButton, SButton, SNavbar, STitle } from "./styled";
import { NavbarBackButton, NavbarButton } from "./types";

type Props = {
  title: string | undefined;
  button: NavbarButton | undefined;
  backButton: NavbarBackButton | undefined;
};

const Navbar = ({ title, button, backButton }: Props) => (
  <SNavbar>
    {backButton && (
      <SBackButton
        icon="chevron-left"
        iconVariant="regular"
        size="small"
        variant="text"
        onClick={() => null}
        to={backButton.to}
      >
        {backButton.label}
      </SBackButton>
    )}

    <STitle>{title || "Admin"}</STitle>

    <div>
      {button && <SButton onClick={button.onClick}>{button.label}</SButton>}
    </div>
  </SNavbar>
);

export default Navbar;
