import { Modal } from "@ag/components/Modal";
import { Confirmation } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

type DeleteConfirmationProps = {
  isOpen: boolean;
  isDeleting?: boolean;
  userToDelete: {
    email: string;
  } | null;
  onHide: () => void;
  onConfirm: () => void;
};

export const DeleteUserConfirmation = ({
  isOpen,
  userToDelete,
  isDeleting,
  onHide,
  onConfirm,
}: DeleteConfirmationProps) => (
  <Modal isOpen={isOpen} noPadding onRequestClose={onHide}>
    <Confirmation.Root variant="warning">
      <Confirmation.Title>{I18n.t("js.users.delete_user")}</Confirmation.Title>

      <Confirmation.Description>
        {I18n.t("js.users.delete_user_confirmation", {
          user: userToDelete?.email,
        })}
      </Confirmation.Description>

      <Confirmation.Actions>
        <Confirmation.Cancel onClick={onHide}>
          {I18n.t("js.shared.cancel")}
        </Confirmation.Cancel>

        <Confirmation.Confirm
          isLoading={isDeleting}
          onClick={() => onConfirm()}
        >
          {I18n.t("js.shared.delete")}
        </Confirmation.Confirm>
      </Confirmation.Actions>
    </Confirmation.Root>
  </Modal>
);
