import { cva } from "class-variance-authority";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { findComponents } from "@ag/utils/helpers";
import { withProvider } from "@ag/utils/hocs";

import { Icon } from "~assets";
import { Button } from "~atoms";
import { cn } from "~utils";

import { ModalHeaderAlignment, ModalSize } from "./types";

const onRequestCloseAtom = atom<(() => void) | undefined>(undefined);

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
type ModalProps = React.PropsWithChildrenRequired<
  {
    size?: ModalSize;
    className?: string;
    isCentered?: boolean;
    isOpen?: boolean;
    testTag?: string;
  } & (
    | {
        isLocked: true;
        onRequestClose?: never;
      }
    | {
        isLocked?: false;
        onRequestClose: () => void;
      }
  )
>;

const rootVariants = cva(
  [
    "z-50 flex flex-col gap-4 rounded bg-white-100 shadow-200",
    "my-12 p-6",
    "h-fit max-h-[calc(100vh-theme(spacing.12)*2)] max-w-full",
  ],
  {
    variants: {
      isShakeAnimationActive: {
        true: "animate-[shakeX_600ms]",
      },
      size: {
        "extra-small": "w-[368px]",
        small: "w-[504px]",
        medium: "w-[600px]",
        large: "w-[800px]",
        "extra-large": "w-[1000px]",
      },
    },
  },
);

const headerVariants = cva(
  ["grid grid-cols-[1fr_auto] gap-4 ", "items-start justify-between", "w-full"],
  {
    variants: {
      align: {
        left: "justify-start [grid-template-areas:'title_close']",
        center:
          "grid-cols-[theme(spacing.6)_1fr_theme(spacing.6)] [grid-template-areas:'._title_close']",
      },
    },

    defaultVariants: {
      align: "left",
    },
  },
);

const Modal = ({
  className,
  children,
  size = "medium",
  isOpen,
  isCentered = false,
  isLocked,
  onRequestClose,
}: ModalProps) => {
  const [isShakeAnimationActive, setIsShakeAnimationActive] = useState(false);

  const [HeaderElement] = findComponents<typeof Header>(children, Header);
  const [ContentElement] = findComponents<typeof Content>(children, Content);
  const [FooterElement] = findComponents<typeof Footer>(children, Footer);

  const [, setOnRequestClose] = useAtom(onRequestCloseAtom);

  useEffect(() => {
    setOnRequestClose(() => onRequestClose);
  }, [onRequestClose, setOnRequestClose]);

  if (isOpen === false) return null;

  const onOverlayClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isLocked) {
      setIsShakeAnimationActive(true);

      return;
    }

    onRequestClose();
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onRequestClose) onRequestClose();
  };

  return createPortal(
    <div
      className={cn(
        "fixed inset-0 z-modal flex h-full w-full justify-center overflow-y-auto bg-black-900/30",
        isCentered && "items-center",
      )}
      onClick={onOverlayClick}
    >
      <div
        className={clsx(
          rootVariants({ size, isShakeAnimationActive }),
          className,
        )}
        onAnimationEnd={() => {
          setIsShakeAnimationActive(false);
        }}
        onClick={event => {
          event.stopPropagation();
        }}
        data-testid="modal"
      >
        <header
          className={headerVariants({ align: HeaderElement?.props.align })}
        >
          {HeaderElement}

          {!isLocked && (
            <Icon
              className="justify-self-end text-h3 text-grey-700 [grid-area:close] hover:cursor-pointer hover:text-grey-500"
              name="close"
              onClick={handleCloseClick}
              data-testid="closeModalButton"
            />
          )}
        </header>

        {ContentElement}

        {FooterElement}
      </div>
    </div>,
    document.body,
  );
};

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/

type HeaderProps = React.PropsWithChildren<{
  align?: ModalHeaderAlignment;
}>;

const Header = ({ children, align = "left" }: HeaderProps) => {
  const [TitleElement] = findComponents<typeof Title>(children, Title);
  const [SubTitleElement] = findComponents<typeof SubTitle>(children, SubTitle);

  return (
    <div
      className={cn(
        "[grid-area:title]",
        "flex flex-col",
        "w-full",
        align === "left" ? "items-start" : "items-center",
      )}
    >
      {TitleElement}
      {SubTitleElement}
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Title
 * -----------------------------------------------------------------------------------------------*/
type TitleProps = {
  children: string;
} & Omit<React.HTMLAttributes<HTMLHeadingElement>, "className" | "children">;

const Title = ({ children, ...props }: TitleProps) => (
  <h3 {...props} className="text-h3 text-grey-900">
    {children}
  </h3>
);

/* -------------------------------------------------------------------------------------------------
 * Subtitle
 * -----------------------------------------------------------------------------------------------*/
type SubtitleProps = {
  children: string;
};

const SubTitle = ({ children }: SubtitleProps) => (
  <h6 className="text-h6 text-grey-800">{children}</h6>
);

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
const Content = ({ children }: React.PropsWithChildrenRequired) => {
  return (
    <div className="flex min-h-0 flex-col gap-4 overflow-y-auto">
      {children}
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/
type FooterProps = {
  render: (
    onClose?: () => void,
  ) => React.ReactElement<typeof Button>[] | React.ReactElement<typeof Button>;
};

const Footer = ({ render }: FooterProps) => {
  const [onRequestClose] = useAtom(onRequestCloseAtom);

  return (
    <footer className="flex w-full items-center justify-end gap-4">
      {render(onRequestClose)}
    </footer>
  );
};

const Root = withProvider(Modal);

export { Root, Header, Title, SubTitle, Content, Footer };
