import {
  faCheck,
  faChevronDown,
  faLock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-collapse";
import { useFormState } from "react-final-form";

import { SFlexDiv } from "@ag/components/styled";
import I18n from "@ag/i18n";

import {
  SCheckIcon,
  SCollapseBoxContent,
  SCollapseBoxHeader,
  SCollapseBoxTitle,
  SGreenIconWrapper,
  SInProgressIcon,
  SLockIcon,
  SPartialBox,
  SProgressIconContainer,
  STitleWrapper,
  SToggleButton,
  SUnsavedIndicator,
} from "./styled";
import { FieldPartialStatus } from "./types";

type Props = React.PropsWithChildren<{
  title: string;
  status?: FieldPartialStatus;
  className?: string;
  headerTestTag?: string;
  headerComponent?: React.ReactNode | null;
  isOpen: boolean;
  isReadonly?: boolean;
  onToggle?: (isOpen: boolean) => void;
}>;

export const PartialBox = ({
  children,
  title,
  className,
  headerTestTag,
  status,
  headerComponent: HeaderComponent,
  isOpen,
  isReadonly = false,
  onToggle,
}: Props) => {
  const formState = useFormState();

  const getStatusIcon = (
    status: FieldPartialStatus,
    isReadonly: boolean,
  ): React.ReactNode => {
    if (isReadonly) {
      return (
        <SGreenIconWrapper>
          <SLockIcon data-testid={`${headerTestTag}-readOnly`} icon={faLock} />
        </SGreenIconWrapper>
      );
    }

    const statusComponent = {
      [FieldPartialStatus.NotStarted]: null,
      [FieldPartialStatus.InProgress]: (
        <SProgressIconContainer>
          <SInProgressIcon />
        </SProgressIconContainer>
      ),
      [FieldPartialStatus.Done]: (
        <SGreenIconWrapper>
          <SCheckIcon icon={faCheck} />
        </SGreenIconWrapper>
      ),
    };

    return (
      <span data-testid={`${headerTestTag}-status-${status}`}>
        {statusComponent[status] || null}
      </span>
    );
  };

  return (
    <SPartialBox
      boxShadow
      className={className}
      data-testid={headerTestTag}
      isErrorVisible={
        status !== FieldPartialStatus.Done &&
        formState.submitFailed &&
        !formState.submitting
      }
    >
      <SCollapseBoxHeader
        data-testid={`${headerTestTag}-header`}
        onClick={() => {
          if (onToggle) {
            onToggle(!isOpen);
          }
        }}
      >
        <STitleWrapper>
          <SCollapseBoxTitle>{title}</SCollapseBoxTitle>

          {HeaderComponent && isOpen ? HeaderComponent : null}
        </STitleWrapper>

        <SFlexDiv alignItems="center">
          {formState.dirty && (
            <SUnsavedIndicator
              data-testid={`${headerTestTag}-unsavedIndicator`}
            >
              {I18n.t("js.shared.unsaved")}
            </SUnsavedIndicator>
          )}

          {status && getStatusIcon(status, isReadonly)}

          <SToggleButton type="button" isOpened={isOpen}>
            <FontAwesomeIcon icon={faChevronDown} />
          </SToggleButton>
        </SFlexDiv>
      </SCollapseBoxHeader>

      <Collapse theme={{ collapse: "collapse-box-collapse" }} isOpened={isOpen}>
        <SCollapseBoxContent>{children}</SCollapseBoxContent>
      </Collapse>
    </SPartialBox>
  );
};
