import ActionAgreementButton, {
  OnActionAgreement,
} from "../action-agreement-button";

type Props = {
  isVoiding: boolean;
  onVoid: OnActionAgreement;
};

const VoidAgreementButton = ({ isVoiding, onVoid }: Props) => (
  <ActionAgreementButton
    buttonActionText="Void agreement"
    textAreaLabel="Enter reason for voiding agreement"
    buttonModalText="Add reason and void"
    isLoading={isVoiding}
    onActionAgreement={onVoid}
    aria-label="Void agreement"
  />
);

export default VoidAgreementButton;
