import { z } from "zod";

import { GeoJSONWithoutPropertiesSchema } from "@ag/map/types";

import { FieldBoundariesTypes } from "~features/field/entities/field-boundaries";

export enum IssueSeverityFlagTypes {
  RED = "red",
  YELLOW = "yellow",
  NONE = "none",
}

export const IOUCheckResultSchema = z.object({
  carbonFieldId: z.string(),
  carbonFieldMrvBoundaryId: z.string(),
  createdAt: z.string(),
  flag: z.nativeEnum(IssueSeverityFlagTypes),
  id: z.string(),
  result: z.object({
    iou: z.number(),
    sizeRatio: z.number(),
  }),
  type: z.string(),
  updatedAt: z.string(),
});

export const OverlapCheckResultSchema = z.object({
  flag: z.nativeEnum(IssueSeverityFlagTypes),
  overlap: z.number(),
  overlappingFieldId: z.string(),
  sizeHa: z.string(),
});

export const CheckResultsSchema = z.object({
  overlap: z.array(OverlapCheckResultSchema),
  iou: IOUCheckResultSchema.optional(),
});

export const FieldBoundariesWithChecksResultsSchema = z.object({
  id: z.string(),
  carbonFieldId: z.string(),
  boundaries: GeoJSONWithoutPropertiesSchema,
  type: z.nativeEnum(FieldBoundariesTypes),
  createdAt: z.string(),
  updatedAt: z.string(),
  activeAt: z.string().nullable(),
  isActive: z.boolean(),
  checkResults: CheckResultsSchema.optional(),
});

export const FieldWithAllBoundariesSchema = z.object({
  carbonFieldId: z.string(),
  boundaries: z.array(FieldBoundariesWithChecksResultsSchema),
  editable: z.boolean(),
});

export type FieldWithAllBoundaries = z.infer<
  typeof FieldWithAllBoundariesSchema
>;

export type OverlapCheckResult = z.infer<typeof OverlapCheckResultSchema>;
export type IOUCheckResult = z.infer<typeof IOUCheckResultSchema>;
export type CheckResults = z.infer<typeof CheckResultsSchema>;

export type FieldBoundariesWithChecksResults = z.infer<
  typeof FieldBoundariesWithChecksResultsSchema
>;
