import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup, FormInput } from "@ag/components/form";
import { SCol, SFlexDiv, SSpan } from "@ag/components/styled";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { SFormRow, SFormSubmitButtonContainer } from "~forms/styled";

import {
  SAddApplicationButton,
  SApplicationRoundTitle,
  SApplicationWrapper,
} from "./styled";
import {
  CropProtectionAttribute,
  CropProtectionCategory,
  CropProtectionDetailsAttribute,
  CropProtectionDetailsData,
  CropProtectionType,
  CropProtectionV2022Data,
} from "./types";

type Props = {
  protectionTypes: LabelValue<string>[];
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<CropProtectionV2022Data>) => void;
};

export const CropProtectionForm = ({
  protectionTypes,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<CropProtectionV2022Data>();
  const { values, pristine } = useFormState<CropProtectionV2022Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const handleAddApplicationClicked = (fields: {
    push: (value: CropProtectionDetailsData) => void;
  }) => {
    const emptyCropProtectionDetailsData = {
      [CropProtectionDetailsAttribute.Type]: null,
      [CropProtectionDetailsAttribute.Category]: null,
      [CropProtectionDetailsAttribute.ApplicationRate]: null,
    };

    fields.push(emptyCropProtectionDetailsData);
  };

  const numberOfApplications =
    values[CropProtectionAttribute.CropProtections].length;
  const hasApplications = numberOfApplications > 0;

  return (
    <>
      <FieldArray name={CropProtectionAttribute.CropProtections}>
        {({ fields }) => (
          <div>
            {fields.map((name, index) => {
              const selectedType =
                fields.value[index][CropProtectionDetailsAttribute.Type];

              return (
                <SApplicationWrapper key={name}>
                  <SFormRow>
                    <SCol>
                      <SFlexDiv
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <SApplicationRoundTitle
                          data-testid={`cropProtection-applicationRoundTitle-${index}`}
                        >
                          {I18n.t("js.carbon.crop_protection_application")}

                          <SSpan marginLeft={4}>{index + 1}</SSpan>
                        </SApplicationRoundTitle>

                        {!isReadonly && (
                          <ActionButton
                            type="secondary"
                            variant="danger"
                            size="extra-small"
                            testTag={`cropProtection-deleteBtn-${index}`}
                            onClick={() => fields.remove(index)}
                          >
                            {I18n.t("js.shared.delete")}
                          </ActionButton>
                        )}
                      </SFlexDiv>
                    </SCol>
                  </SFormRow>

                  <SFormRow>
                    <SCol>
                      <Field
                        carbon
                        name={`${name}.${CropProtectionDetailsAttribute.Type}`}
                        label={I18n.t("js.carbon.type")}
                        wrapperTestTag={`cropProtection-typeButtonGroup-${index}`}
                        component={FormButtonsGroup}
                        buttons={protectionTypes}
                        disabled={isReadonly}
                        onChangeCustom={(type: CropProtectionType): void =>
                          fields.update(index, {
                            [CropProtectionDetailsAttribute.Type]: type,
                            [CropProtectionDetailsAttribute.Category]:
                              CropProtectionCategory.PostEmergence,
                          })
                        }
                      />
                    </SCol>
                  </SFormRow>

                  <SFormRow>
                    <SCol col={4} md={12}>
                      <Field
                        name={`${name}.${CropProtectionDetailsAttribute.ApplicationRate}`}
                        label={I18n.t(
                          "js.carbon.field_details.application_of_product",
                        )}
                        testTag={`cropProtection-numberOfDosesInput-${index}`}
                        type="number"
                        component={FormInput}
                        disabled={isReadonly || !selectedType}
                      />
                    </SCol>
                  </SFormRow>
                </SApplicationWrapper>
              );
            })}

            {!isReadonly && (
              <SAddApplicationButton
                testTag="cropProtection-addApplicationRoundButton"
                type="text"
                margin={fields?.value?.length > 0}
                onClick={() => handleAddApplicationClicked(fields)}
              >
                + {I18n.t("js.carbon.add_crop_protection_application")}
              </SAddApplicationButton>
            )}
          </div>
        )}
      </FieldArray>

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          {!hasApplications && (
            <ActionButton
              HTMLType="submit"
              type="text"
              testTag="cropProtection-notUsingCropProtectionBtn"
              isLoading={isSubmitting}
              onClick={form.submit}
            >
              {I18n.t("js.carbon.not_using_crop_protection")}
            </ActionButton>
          )}

          <ActionButton
            HTMLType="submit"
            testTag="cropProtection-submitButton"
            disabled={pristine}
            isLoading={hasApplications && isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}

      {isReadonly && !hasApplications && (
        <div>{I18n.t("js.carbon.no_crop_protections_added")}</div>
      )}
    </>
  );
};
