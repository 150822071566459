import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import { HistoryStack } from "~hooks/use-multi-polygon-paths-history";

import { getActiveVertexPath, getActiveVertexPosition } from "../../helpers";
import { useMultiPolygonBoundariesStore } from "../multiPolygonStore";
import * as styles from "./multi-polygon-map-actions-tool.css";

type Props = {
  history: HistoryStack;
  onVertexDelete: (polygonId: number, path: number) => void;
};

const MultiPolygonMapActionsTool = ({ history, onVertexDelete }: Props) => {
  const {
    activeVertex,
    polygonInstance,
    outerPath,
    innerPaths,
    setActiveVertex,
    setOuterPath,
    setInnerPaths,
  } = useMultiPolygonBoundariesStore();

  if (!outerPath) return null;

  const activeVertexPath =
    activeVertex &&
    getActiveVertexPath(
      activeVertex,
      outerPath[activeVertex.polygonId],
      innerPaths[activeVertex.polygonId],
    );
  const activeVertexPosition =
    activeVertex &&
    getActiveVertexPosition(
      activeVertex,
      outerPath[activeVertex.polygonId],
      innerPaths[activeVertex.polygonId],
    );
  // Allow deleting vertex if polygon has more than 3 vertices
  const isDeleteVertexAvailable = Boolean(
    activeVertexPosition &&
      activeVertexPath?.length &&
      activeVertexPath.length > 3,
  );
  const isUndoAvailable = history.itemsAmount > 0;

  const handleDeleteVertexClicked = () => {
    if (!activeVertex || !polygonInstance) return;

    const { path, vertex } = activeVertex;

    polygonInstance[activeVertex.polygonId]
      .getPaths()
      .getAt(path)
      .removeAt(vertex);

    onVertexDelete(activeVertex.polygonId, path);

    setActiveVertex(undefined);
  };

  const handleUndoClicked = () => {
    const previousChange = history.pop();

    if (previousChange) {
      const { outerPath, innerPaths } = previousChange;

      setOuterPath(outerPath);
      setInnerPaths(innerPaths);
      setActiveVertex(undefined);
    }
  };

  return (
    <div className={styles.root}>
      <Button
        className={styles.button}
        icon="rotate-left"
        size="small"
        disabled={!isUndoAvailable}
        onClick={handleUndoClicked}
      >
        {I18n.t("js.shared.undo")}
      </Button>

      <Button
        icon="trash-can"
        variant="primary"
        size="small"
        isDanger
        disabled={!isDeleteVertexAvailable}
        onClick={handleDeleteVertexClicked}
      >
        {I18n.t("js.shared.delete")}
      </Button>
    </div>
  );
};

export default MultiPolygonMapActionsTool;
