import capitalize from "lodash/capitalize";
import { z } from "zod";

import { MethodologyVersion } from "@ag/carbon/types";
import { Locale } from "@ag/i18n";
import { RemapWithPrefix } from "@ag/utils/types";

type LocaleNames = RemapWithPrefix<
  Record<Locale, z.ZodNullable<z.ZodString>>,
  "name"
>;

const localeNames = Object.values(Locale).reduce(
  (acc, value) => ({
    ...acc,
    [`name${capitalize(value)}`]: z.string().nullable(),
  }),
  {} as LocaleNames,
);

export const CropTypeSchema = z
  .object({
    id: z.number(),
    coolfarmIdentifier: z.number(),
    name: z.string(),
    identifier: z.string(),
    harvestYear: z.number(),
    methodologyVersion: z.nativeEnum(MethodologyVersion),
    netGross: z.number().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    coolfarmName: z.string().nullable(),
    residueAmount: z.number().nullable(),
    showResidue: z.boolean(),
    grossYieldMin: z.number(),
    grossYieldMax: z.number(),
    kgNHaMin: z.number(),
    kgNHaMax: z.number(),
    dryMatterFraction: z.number(),
    slope: z.number(),
    intercept: z.number(),
    readonly: z.boolean(),
  })
  .merge(z.object(localeNames));

export type CropType = z.infer<typeof CropTypeSchema>;
