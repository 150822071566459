import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { useAdminRolesQuery } from "../api/get-admin-roles";

type Props<TValues extends FieldValues> = {
  values: TValues;
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};
export const AdminsFilters = <TValues extends FieldValues>({
  values,
  onChange,
  onClear,
}: Props<TValues>) => {
  const { data: adminRoles } = useAdminRolesQuery();

  return (
    <>
      <Filters.Root values={values} onChange={onChange}>
        {adminRoles && (
          <Filters.Item
            render={({ control }) => (
              <Controller
                name="role"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectField
                    {...field}
                    error={fieldState.error}
                    label={I18n.t("js.admin.admins.filters.role.title")}
                  >
                    <>
                      <Select.OptionAll>
                        {I18n.t("js.shared.all")}
                      </Select.OptionAll>

                      {adminRoles.map(role => (
                        <Select.Option key={role.id} value={role.name}>
                          {role.name}
                        </Select.Option>
                      ))}
                    </>
                  </SelectField>
                )}
              />
            )}
          />
        )}

        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("email")}
              label={I18n.t("js.shared.email")}
            />
          )}
        />

        <Filters.Reset onClear={onClear} />
      </Filters.Root>
    </>
  );
};
