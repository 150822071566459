import { Helmet } from "react-helmet";

import { cn } from "@ag/design-system/utils";
import { createChildValidator } from "@ag/utils/helpers";

/* -------------------------------------------------------------------------------------------------
 * OverviewLayout
 * -----------------------------------------------------------------------------------------------*/
const layoutChildValidator = createChildValidator("OverviewLayout", {
  Sidebar: OverviewLayoutSidebar,
  TopBar: OverviewLayoutTopBar,
  Content: OverviewLayoutContent,
});

type OverviewLayoutProps = {
  children: React.ReactElement<
    typeof OverviewLayoutTopBar | typeof OverviewLayoutContent
  >[];
};

function OverviewLayout({ children }: OverviewLayoutProps) {
  const childError = layoutChildValidator(children);
  if (childError) throw new Error(childError);

  return (
    <div className="grid h-screen grid-cols-[auto,1fr] grid-rows-[64px,1fr] [grid-template-areas:'sidebar_topbar''sidebar_content']">
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * OverviewLayoutTopBar
 * -----------------------------------------------------------------------------------------------*/
type OverviewLayoutTopBarProps = React.PropsWithChildren<unknown>;

function OverviewLayoutTopBar({ children }: OverviewLayoutTopBarProps) {
  return (
    <div
      className={cn(
        "grid grid-cols-[1fr,auto,1fr] items-center [grid-area:topbar] [grid-template-areas:'back_title_actions']",
        "border-b border-grey-100 bg-white-100 px-4",
        "[&>button]:justify-self-start [&>button]:[grid-area:back]",
      )}
    >
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * OverviewLayoutTopBarTitle
 * -----------------------------------------------------------------------------------------------*/
function OverviewLayoutTopBarTitle({
  children,
}: React.PropsWithChildrenRequired) {
  return (
    <>
      <h4 className="text-center text-h4 [grid-area:title]">{children}</h4>
      <Helmet>{<title>{`${children} - Agreena`}</title>}</Helmet>
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * OverviewLayoutContent
 * -----------------------------------------------------------------------------------------------*/
function OverviewLayoutContent({
  children,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & React.PropsWithChildrenRequired) {
  return (
    <main className="mx-auto box-border w-full overflow-y-auto p-8" {...rest}>
      {children}
    </main>
  );
}

/* -------------------------------------------------------------------------------------------------
 * OverviewLayoutSidebar
 * -----------------------------------------------------------------------------------------------*/
function OverviewLayoutSidebar({ children }: React.PropsWithChildrenRequired) {
  return (
    <section className="overflow-y-auto [grid-area:sidebar]">
      {children}
    </section>
  );
}

const Root = OverviewLayout;
const Sidebar = OverviewLayoutSidebar;
const TopBar = OverviewLayoutTopBar;
const TopBarTitle = OverviewLayoutTopBarTitle;
const Content = OverviewLayoutContent;

export { Root, TopBar, TopBarTitle, Sidebar, Content };
