import { Icon } from "@ag/design-system/assets";
import I18n from "@ag/i18n";

import { usePolygonBoundariesStore } from "../boundaries-drawing-polygon/polygonStore";
import * as styles from "./action-tips-banner.css";

const ActionTipsBanner = () => {
  const { editAction } = usePolygonBoundariesStore();

  const isEditMode = editAction === "edit";

  return (
    <div className={styles.root}>
      <div
        className={styles.banner({
          type: "info",
        })}
      >
        <Icon className={styles.icon} name="lightbulb-exclamation" />
        {isEditMode
          ? I18n.t(
              "js.carbon.field.field_boundaries_edit.map.edit_mode_tip_info",
            )
          : I18n.t(
              "js.carbon.field.field_boundaries_edit.map.cut_mode_tip_info",
            )}
      </div>

      {!isEditMode && (
        <div
          className={styles.banner({
            type: "warning",
          })}
        >
          <Icon className={styles.icon} name="triangle-exclamation" />
          {I18n.t(
            "js.carbon.field.field_boundaries_edit.map.cut_mode_exit_tip_info",
          )}
        </div>
      )}
    </div>
  );
};

export default ActionTipsBanner;
