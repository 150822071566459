import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import { ChipCell, ChipCellValue } from "@ag/design-system/organisms";

import { useList } from "~components/list";
import { ResaleAgreement } from "~features/resale-agreements";
import { useUserQuery } from "~features/user";

import { getStatusChip } from "../helpers/contract-statuses";
import { formatCurrency } from "../helpers/formatters";

type ListData = {
  userId: string;
  id: string;
  userName: string;
  company: string;
  status: ChipCellValue;
  year: number;
  country: string;
  totalPurchaseAmount: string;
};

export const useSalesOfferAgreementList = (
  data: ResaleAgreement | undefined,
  totalPurchaseAmount: string | undefined,
) => {
  const user = useUserQuery(data?.userId);

  const columns = useMemo(() => getColumns(), []);
  const rowData = useMemo(
    () => getRowData(data, user.data?.name, totalPurchaseAmount),
    [data, user.data?.name, totalPurchaseAmount],
  );
  return useList<ListData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
  });

  function getColumns() {
    const columnHelper = createColumnHelper<ListData>();

    return [
      columnHelper.accessor("id", {
        header: "Agreement Id",
      }),
      columnHelper.accessor("userId", {
        header: "User Id",
      }),
      columnHelper.accessor("userName", {
        header: "User Name",
      }),
      columnHelper.accessor("company", {
        header: "Company",
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ChipCell,
      }),
      columnHelper.accessor("year", {
        header: "Year",
      }),
      columnHelper.accessor("country", {
        header: "Country",
      }),
      columnHelper.accessor("totalPurchaseAmount", {
        header: "Tot. Pur. Amount",
      }),
    ];
  }

  function getRowData(
    agreement: ResaleAgreement | undefined,
    userName: string | undefined,
    totalPurchaseAmount: string | undefined,
  ): ListData[] {
    if (!agreement) return [];

    return [
      {
        id: agreement.id,
        userId: agreement.userId,
        userName: userName ?? "-",
        company: agreement.companyName ?? "-",
        status: agreement.status ? getStatusChip(agreement.status) : null,
        year: agreement.year,
        country: agreement.countryName ?? "-",
        totalPurchaseAmount: totalPurchaseAmount
          ? formatCurrency(totalPurchaseAmount)
          : "-",
      },
    ];
  }
};
