import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Icon } from "@ag/design-system/assets";
import { Button, InfoBox } from "@ag/design-system/atoms";
import { Card, List } from "@ag/design-system/organisms";

import { AuthorizedSidebar } from "~features/navigation";
import { useResaleAgreementDetailsQuery } from "~features/resale-agreements";
import {
  AgreementType,
  useSalesOfferAgreementList,
} from "~features/sales-offers";
import OverviewLayout from "~layouts/overview-layout";

const agreementLabel = {
  [AgreementType.Resale]: "Resale",
  [AgreementType.Buyout]: "Buyout",
};

const AgreementConfirmationPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("No agreement ID provided");
  }

  const agreementType: AgreementType | null = state?.type;

  if (!agreementType) {
    throw new Error("No agreement type set");
  }

  // TODO => GET agreement based on agreementType
  const { data, isLoading } = useResaleAgreementDetailsQuery(id);

  const list = useSalesOfferAgreementList(data, state?.totalPurchaseAmount);

  return (
    <OverviewLayout.Root>
      <OverviewLayout.Sidebar>
        <AuthorizedSidebar />
      </OverviewLayout.Sidebar>

      <OverviewLayout.TopBar>
        <OverviewLayout.TopBarTitle>
          Offers Agreements
        </OverviewLayout.TopBarTitle>
      </OverviewLayout.TopBar>

      <div className="p-8">
        <div className="flex flex-col items-center justify-center gap-4">
          <Icon
            name="circle-check"
            className="text-[56px] text-green-500"
            variant="light"
          />

          <h3 className="text-h3 text-green-600">
            {agreementLabel[agreementType]} Agreement created
          </h3>
        </div>

        <div className="my-4">
          <InfoBox icon="check" variant="success">
            The agreement has been successfully sent through Docusign to the
            signing parties.
          </InfoBox>
        </div>

        <h5 className="mb-4 text-h5 text-grey-700">
          {agreementLabel[agreementType]} agreement information
        </h5>

        <List
          instance={list}
          isLoading={isLoading}
          className="w-full"
          onRowClick={({ id }) =>
            data && navigate(`/carbon/agreements/${agreementType}/${id}`)
          }
        />

        <Card className="mt-7 flex w-full items-center justify-between gap-6">
          <>
            <div>
              <h5 className="w-full max-w-md text-h4">Offers list</h5>
              <p className="text-p1">
                Go back to review offers and continue where you left off
              </p>
            </div>

            <Button
              size="x-small"
              to={`/carbon/agreements/offers${
                state?.query ? `?${state.query}` : ""
              }`}
              role="link"
              aria-label="Go to offers"
            >
              Go to offers
            </Button>
          </>
        </Card>
      </div>
    </OverviewLayout.Root>
  );
};

export default AgreementConfirmationPage;
