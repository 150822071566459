import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import capitalize from "lodash/capitalize";
import { useEffect, useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import { Field, useForm } from "react-final-form";

import { FormButtonsGroup, FormInput, FormSelect } from "@ag/components/form";
import { SCol, SFlexDiv } from "@ag/components/styled";
import I18n, { Locale, getLocales } from "@ag/i18n";
import {
  getYesNoOptions,
  parseInputNumber,
  parseStringInput,
} from "@ag/utils/helpers";

import Card from "~components/card";
import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";
import { SGridContainer } from "~pages/_legacy/carbon/pages/Fertilisers/styled";

import {
  EditFertiliserFormAttribute,
  EditFertiliserFormData,
  EditFertiliserOptionalFormAttribute,
} from "../types";
import { SRow, SToggleButton } from "./styled";

const Form = () => {
  const form = useForm<EditFertiliserFormData>();

  const availableLocales = useMemo(
    () => getLocales().filter(locale => locale !== Locale.En),
    [],
  );

  const {
    data: openHarvestYearsOptions,
    isLoading: isOpenHarvestYearsOptionsLoading,
  } = useOpenHarvestYearsOptions();

  const [isTranslationSectionVisible, setIsTranslationSectionVisible] =
    useState(false);

  const formState = form.getState();

  useEffect(() => {
    if (
      formState.submitFailed &&
      formState.errors?.nameEn &&
      !isTranslationSectionVisible
    ) {
      setIsTranslationSectionVisible(true);
    }
  }, [formState, isTranslationSectionVisible]);

  const yesNoOptions = getYesNoOptions();

  return (
    <Card.Root>
      <SRow>
        <SCol>
          <Field
            name={EditFertiliserFormAttribute.NameEn}
            label={`${I18n.t(
              "js.carbon.fertilisers.attribute.name",
            )} (${Locale.En.toUpperCase()})`}
            component={FormInput}
            required
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.DescriptionEn}
            label={`${I18n.t(
              "js.carbon.fertilisers.attribute.description",
            )} (${Locale.En.toUpperCase()})`}
            component={FormInput}
            disabled
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol col={6}>
          <Field
            name={EditFertiliserFormAttribute.Identifier}
            label={I18n.t("js.carbon.fertilisers.attribute.identifier")}
            component={FormInput}
            disabled
          />
        </SCol>

        <SCol col={3}>
          <Field
            name={EditFertiliserFormAttribute.Id}
            label={I18n.t("js.carbon.fertilisers.attribute.id")}
            component={FormInput}
            disabled
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol>
          <Field
            name={EditFertiliserFormAttribute.CoolfarmIdentifier}
            label={I18n.t("js.carbon.fertilisers.attribute.coolfarm_id")}
            type="number"
            component={FormInput}
            required
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserFormAttribute.CoolfarmName}
            label={I18n.t("js.carbon.fertilisers.attribute.coolfarm_name")}
            component={FormInput}
            required
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserFormAttribute.HarvestYear}
            label={I18n.t("js.carbon.fertilisers.attribute.harvest_year")}
            component={FormSelect}
            type="number"
            options={openHarvestYearsOptions}
            parse={parseInputNumber}
            isLoading={isOpenHarvestYearsOptionsLoading}
            isDisabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserFormAttribute.MethodologyVersion}
            label={I18n.t(
              "js.carbon.fertilisers.attribute.methodology_version",
            )}
            component={FormSelect}
            options={METHODOLOGY_VERSIONS_OPTIONS}
            isDisabled
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.OrganicCarbon}
            label={I18n.t("js.carbon.fertilisers.attribute.organic_carbon")}
            type="number"
            component={FormInput}
            required
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.NitrogenTotal}
            label={I18n.t("js.carbon.fertilisers.attribute.nitrogen_total")}
            type="number"
            component={FormInput}
            required
            disabled
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.NitrogenUrea}
            label={I18n.t("js.carbon.fertilisers.attribute.nitrogen_urea")}
            type="number"
            component={FormInput}
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.NitrateNitrogen}
            label={I18n.t("js.carbon.fertilisers.attribute.nitrate_nitrogen")}
            type="number"
            component={FormInput}
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.Ammonium}
            label={I18n.t("js.carbon.fertilisers.attribute.ammonium")}
            type="number"
            component={FormInput}
            disabled
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.Phosphorus}
            label={I18n.t("js.carbon.fertilisers.attribute.phosphorus")}
            type="number"
            component={FormInput}
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.PhosphorusPentoxide}
            label={I18n.t(
              "js.carbon.fertilisers.attribute.phosphorus_pentoxide",
            )}
            type="number"
            component={FormInput}
            disabled
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.Potassium}
            label={I18n.t("js.carbon.fertilisers.attribute.potassium")}
            type="number"
            component={FormInput}
            disabled
          />
        </SCol>

        <SCol>
          <Field
            name={EditFertiliserOptionalFormAttribute.PotassiumOxide}
            label={I18n.t("js.carbon.fertilisers.attribute.potassium_oxide")}
            type="number"
            component={FormInput}
            disabled
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol col={3}>
          <Field
            name={EditFertiliserFormAttribute.Organic}
            label={`${I18n.t("js.carbon.fertilisers.attribute.organic")}`}
            component={FormButtonsGroup}
            buttons={yesNoOptions}
            required
            disabled
          />
        </SCol>

        <SCol col={3}>
          <Field
            name={EditFertiliserFormAttribute.NitrificationAdded}
            label={I18n.t(
              "js.admin.carbon.fertiliser_attribute.nitrification_added",
            )}
            component={FormButtonsGroup}
            buttons={yesNoOptions}
            required
            disabled
          />
        </SCol>

        <SCol col={3}>
          <Field
            name={EditFertiliserFormAttribute.ChemicalSpecification}
            label={I18n.t(
              "js.carbon.fertilisers.attribute.chemical_specification",
            )}
            component={FormButtonsGroup}
            buttons={yesNoOptions}
            disabled
          />
        </SCol>
      </SRow>

      <SFlexDiv>
        <SToggleButton
          type="text"
          icon={isTranslationSectionVisible ? faChevronUp : faChevronDown}
          iconPosition="after"
          onClick={() =>
            setIsTranslationSectionVisible(!isTranslationSectionVisible)
          }
        >
          {isTranslationSectionVisible
            ? I18n.t("js.shared.hide_translations")
            : I18n.t("js.shared.show_translations")}
        </SToggleButton>
      </SFlexDiv>

      <Collapse isOpened={isTranslationSectionVisible}>
        <SFlexDiv marginTop={16} direction="column">
          <SRow style={{ marginBottom: 16 }}>
            <SGridContainer>
              {availableLocales.map(locale => (
                <Field
                  key={locale}
                  name={`name${capitalize(locale)}`}
                  label={`${I18n.t(
                    "js.carbon.crop_type_attribute.name",
                  )} (${locale})`}
                  component={FormInput}
                  parse={parseStringInput}
                  disabled
                />
              ))}
            </SGridContainer>
          </SRow>
        </SFlexDiv>
      </Collapse>
    </Card.Root>
  );
};

export default Form;
