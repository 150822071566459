import { z } from "zod";

import { ContractStatus } from "./buyout-agreement";

export enum PaymentStatusFilter {
  NotReady = "not-ready",
  Paid = "paid",
  Withdrawn = "withdrawn",
}

export const BuyoutAgreementsFiltersSchema = z
  .object({
    userId: z.string(),
    status: z.nativeEnum(ContractStatus).or(z.literal("")),
    year: z.string(),
  })
  .partial();

export type BuyoutAgreementsFilters = z.infer<
  typeof BuyoutAgreementsFiltersSchema
>;
