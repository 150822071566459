import { cva } from "class-variance-authority";
import React from "react";

import { Icon } from "~assets";
import { cn } from "~utils";

import { ICONS_REGULAR } from "../../assets/Icon";

export type InfoBoxVariant =
  | "success"
  | "warning"
  | "neutral"
  | "info"
  | "danger";

type Props = React.PropsWithChildrenRequired<{
  icon?: keyof typeof ICONS_REGULAR;
  variant?: InfoBoxVariant;
  onClose?: () => void;
}>;

const variantClasses = cva(
  "grid grid-flow-col grid-cols-[auto_1fr] gap-1 rounded px-4 py-2 text-h7 [&_a]:underline",
  {
    variants: {
      variant: {
        success:
          "border-positive-border bg-positive-background text-positive-foreground",
        warning:
          "border-warning-border bg-warning-background text-warning-foreground",
        neutral:
          "border-neutral-border bg-neutral-background text-neutral-foreground",
        info: "border-info-border bg-info-background text-info-foreground",
        danger:
          "border-danger-border bg-danger-background text-danger-foreground",
      },
    },
    defaultVariants: {
      variant: "neutral",
    },
  },
);

export const InfoBox = ({
  variant = "neutral",
  icon,
  children,
  onClose,
}: Props) => (
  <div className={variantClasses({ variant })}>
    {icon && (
      <Icon
        className={cn("text-h5", { "-mt-px": variant === "danger" })}
        name={icon}
      />
    )}

    {children}

    {onClose && (
      <button
        onClick={onClose}
        className="-m-2 flex items-center border-none bg-transparent bg-none p-2 outline-transparent hover:cursor-pointer"
      >
        <Icon name="close" />
      </button>
    )}
  </div>
);
