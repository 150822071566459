import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";
import { Row } from "react-table";

import {
  ActionsCell,
  ActionsCellValue,
  ChipCell,
  ChipCellValue,
  DateCell,
  DateCellValue,
  LinkCell,
  LinkCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { ACTIONS_CELL_WIDTH, useTable } from "~components/table";
import {
  CarbonResourceClass,
  UserManagementResourceClass,
  useCarbonPermissions,
  useUserManagementPermissions,
} from "~features/permission";

import { User } from "../entities/user";

type TableData = {
  actions: ActionsCellValue;
  id: LinkCellValue;
  company: string | undefined;
  name: string;
  hectares: number | string | null | undefined;
  phone: string | null;
  email: string;
  country: string | undefined;
  validated: ChipCellValue;
  vatNumber: string | undefined;
  address: string | undefined;
  lastActivityAt: DateCellValue;
  confirmedAt: DateCellValue;
  createdAt: DateCellValue;

  user: User;
};

export type ActionColumnProps = {
  row: Row<TableData>;
};

type UseUsersTableData = {
  users: User[] | undefined;
};

type UseUsersTableActions = {
  onValidate: (userId: string) => void;
  onDelete: (user: { email: string; id: string }) => void;
  onSignIn: (userId: string) => void;
  onHubspotCarbonStatsSync: (userId: string) => void;
};

export const useUsersTable = ({
  users,
  onValidate,
  onDelete,
  onSignIn,
  onHubspotCarbonStatsSync,
}: UseUsersTableData & UseUsersTableActions) => {
  const { data: userManagementPermissions } = useUserManagementPermissions();
  const { data: carbonPermissions } = useCarbonPermissions();

  const canDeleteUser = Boolean(
    userManagementPermissions?.delete?.includes(
      UserManagementResourceClass.User,
    ),
  );
  const canValidateUser = Boolean(
    userManagementPermissions?.validateUser?.includes(
      UserManagementResourceClass.User,
    ),
  );
  const canSyncHubspotCarbonStats = Boolean(
    carbonPermissions?.sync?.includes(CarbonResourceClass.HubspotCarbonStats),
  );

  const tableData = useMemo(
    () =>
      getRowData({
        users,
        canDeleteUser,
        canValidateUser,
        canSyncHubspotCarbonStats,
        onDelete,
        onSignIn,
        onValidate,
        onHubspotCarbonStatsSync,
      }),
    [
      users,
      canDeleteUser,
      canSyncHubspotCarbonStats,
      canValidateUser,
      onDelete,
      onSignIn,
      onValidate,
      onHubspotCarbonStatsSync,
    ],
  );

  return useTable<TableData>({
    columns: getColumns(),
    data: tableData,
    getRowId: original => String(original.user.id),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: ["actions"],
      },
    },
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("actions", {
      header: I18n.t("js.shared.actions"),
      cell: ActionsCell,
      size: ACTIONS_CELL_WIDTH,
    }),
    columnHelper.accessor("id", {
      header: I18n.t("js.users.id"),
      cell: LinkCell,
    }),
    columnHelper.accessor("company", {
      header: I18n.t("js.users.company"),
    }),
    columnHelper.accessor("name", {
      header: I18n.t("js.users.name"),
    }),
    columnHelper.accessor("hectares", {
      header: I18n.t("js.users.hectares"),
    }),
    columnHelper.accessor("phone", {
      header: I18n.t("js.users.phone"),
    }),
    columnHelper.accessor("email", {
      header: I18n.t("js.users.email"),
    }),
    columnHelper.accessor("country", {
      header: I18n.t("js.users.country"),
    }),
    columnHelper.accessor("validated", {
      header: I18n.t("js.users.validated"),
      cell: ChipCell,
    }),
    columnHelper.accessor("vatNumber", {
      header: I18n.t("js.users.vat_number"),
    }),
    columnHelper.accessor("address", {
      header: I18n.t("js.users.address"),
    }),
    columnHelper.accessor("lastActivityAt", {
      header: I18n.t("js.users.last_activity"),
      cell: DateCell,
    }),
    columnHelper.accessor("confirmedAt", {
      header: I18n.t("js.users.confirmed_at"),
      cell: DateCell,
    }),
    columnHelper.accessor("createdAt", {
      header: I18n.t("js.users.created_at"),
      cell: DateCell,
    }),
  ];
}

function getRowData({
  users,
  canDeleteUser,
  canValidateUser,
  canSyncHubspotCarbonStats,
  onValidate,
  onDelete,
  onSignIn,
  onHubspotCarbonStatsSync,
}: UseUsersTableData &
  UseUsersTableActions & {
    canDeleteUser: boolean;
    canValidateUser: boolean;
    canSyncHubspotCarbonStats: boolean;
  }): TableData[] {
  if (!users) return [];

  return users.map(user => ({
    actions: {
      title: I18n.t("js.shared.actions"),
      items: [
        {
          children: I18n.t("js.admin.users.show.sign_in_as"),
          onClick: () => onSignIn(user.id),
        },
        {
          children: I18n.t("js.users.hubspot_contact"),
          onClick: () => {
            if (user.hubspotContactUrl) {
              window.open(user.hubspotContactUrl, "_blank");
            } else {
              ToastNotification.error(
                I18n.t("js.users.hubspot_contact_url_missing"),
              );
            }
          },
        },
        ...(canSyncHubspotCarbonStats
          ? [
              {
                children: "Sync Carbon Stats with Hubspot",
                onClick: () => onHubspotCarbonStatsSync(user.id),
              },
            ]
          : []),
        ...(canValidateUser && !user.validatedAt
          ? [
              {
                children: I18n.t("js.admin.users.index.validate"),
                onClick: () => onValidate(user.id),
              },
            ]
          : []),
        ...(canDeleteUser
          ? [
              {
                children: I18n.t("js.shared.delete"),
                isDanger: true,
                onClick: () => onDelete({ id: user.id, email: user.email }),
              },
            ]
          : []),
      ],
    },
    id: {
      title: user.id,
      url: `/users/${user.id}`,
    },
    company: user.company?.name,
    name: user.name,
    hectares: user.freightAndTradeSetting?.totalFieldsSizeHa,
    phone: user.profile.phoneNumber,
    email: user.email,
    country: user.company?.address.country.name,
    validated: user.validatedAt
      ? { label: I18n.t("js.users.validated"), variant: "success" }
      : { label: I18n.t("js.users.not_validated"), variant: "danger" },
    vatNumber: user.company?.vatNumber,
    address: user.company?.address.address,
    lastActivityAt: user.lastActivityAt,
    confirmedAt: user.confirmedAt,
    createdAt: user.createdAt,

    user,
  }));
}
