import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import {
  BuyoutAgreement,
  BuyoutAgreementSchema,
} from "../entities/buyout-agreement";

export const generateBuyoutAgreementQueryKey = (id: string) =>
  ["buyout-agreement", id].filter(Boolean);

const getBuyoutAgreement = async (id: string) => {
  const response = await axios.get<CommonResponse<BuyoutAgreement>>(
    `/api/v1/carbon-sales-agreements/${id}`,
    {
      schema: BuyoutAgreementSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useBuyoutAgreementQuery = (id: string) =>
  useQuery(generateBuyoutAgreementQueryKey(id), () => getBuyoutAgreement(id));
