import styled from "@emotion/styled";

import { style } from "@ag/ui";

type SLayoutProps = {
  isSidebarVisible: boolean;
};

export const size = {
  sidebar: {
    width: 192,
  },
  navbar: {
    height: 64,
  },
};

export const SLayout = styled.section`
  display: grid;
  grid-template-areas:
    "sidebar   navbar"
    "sidebar  content";
  grid-template-columns: auto 1fr;
  grid-template-rows: ${size.navbar.height}px 1fr;
  height: 100vh;

  ${({ isSidebarVisible }: SLayoutProps): string => {
    let styles = ``;

    if (!isSidebarVisible) {
      styles += `
        grid-template-areas:
          "navbar"
          "content";
        grid-template-columns: 1fr;
        grid-template-rows: ${size.navbar.height}px 1fr;
      `;
    }

    return styles;
  }}
`;

export const STableLayout = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 100%;
  height: 100%;

  background-color: ${style.color.white[100]};
`;
