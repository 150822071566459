import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Select } from "@ag/design-system/molecules";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  value?: string | null;
  name?: string;
  children: React.ReactNode;
  optionsClassName?: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  variant?: "default" | "full-width" | "compact";
  error?: FieldError;
  isDisabled?: boolean;
  isRequired?: boolean;
  testId?: string;
  onChange?: (value: string) => void;
};

// forwardRef is used to allow spreading the field prop (that includes ref) from the form context in the consumer
export const SelectField = forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      value,
      tooltip,
      placeholder,
      testId,
      children,
      error,
      isDisabled,
      isRequired,
      optionsClassName,
      onChange,
      ...rest
    },
    ref,
  ) => (
    <FormField.Root>
      {label && (
        <FormField.Label tooltip={tooltip}>
          {label}
          {isRequired && " *"}
        </FormField.Label>
      )}

      <Select.Root
        value={value}
        placeholder={placeholder}
        optionsClassName={optionsClassName}
        isDisabled={isDisabled}
        isInvalid={Boolean(error)}
        onChange={onChange}
        testId={testId}
        ref={ref}
        {...rest}
      >
        {children}
      </Select.Root>

      {error && <FormField.Error>{error.message}</FormField.Error>}
    </FormField.Root>
  ),
);

SelectField.displayName = "SelectField";
