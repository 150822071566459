import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { BasicStepper } from "@ag/design-system/organisms";
import { usePrompt } from "@ag/utils/hooks";

import BackButton from "~components/BackButton";
import { ProgressLostModal } from "~features/certificate";
import { useImportVerraVcuStore } from "~features/import-vcu";
import { AuthorizedSidebar } from "~features/navigation";
import { Page, withPageAccess } from "~features/permission";
import ListLayout from "~layouts/list-layout";

import * as pageStyles from "../styles.css";
import ImportBatch from "./steps/import-batch";
import SelectVerraDistribution from "./steps/select-distribution";

const ImportVerraCertificates = () => {
  const navigate = useNavigate();
  const [isShowProgressLostModal, setIsShowProgressLostModal] = useState(false);

  const { resetState, isFormValid } = useImportVerraVcuStore();

  const { blockedRoute, unblockRoute } = usePrompt(
    true,
    setIsShowProgressLostModal,
  );

  const handleExitClick = () => {
    if (blockedRoute) {
      unblockRoute();
      navigate(blockedRoute);
    } else {
      history.back();
    }
    resetState();
  };

  const handleCancel = () => {
    setIsShowProgressLostModal(false);
  };

  return (
    <ListLayout.Root>
      <ProgressLostModal
        isOpen={isShowProgressLostModal}
        onClose={handleCancel}
        onExitClick={handleExitClick}
      />
      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.TopBar>
        <BackButton onClick={() => navigate("/ledger/import")} />

        <ListLayout.TopBarTitle>Import and distribute</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Content>
        <div className={pageStyles.root}>
          <BasicStepper.Root>
            <BasicStepper.List>
              <BasicStepper.Trigger title="Import VCU batch" value="batch" />

              <BasicStepper.Trigger
                title="Select distribution file"
                value="file"
                isDisabled={!isFormValid}
              />
            </BasicStepper.List>

            <BasicStepper.Content value="batch">
              {({ goNextStep }) => <ImportBatch onGoNextStep={goNextStep} />}
            </BasicStepper.Content>

            <BasicStepper.Content value="file">
              {({ goPrevStep }) => (
                <SelectVerraDistribution
                  onGoPrevStep={goPrevStep}
                  unblockRoute={unblockRoute}
                />
              )}
            </BasicStepper.Content>
          </BasicStepper.Root>
        </div>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withPageAccess(Page.ImportVerraCertificates)(
  ImportVerraCertificates,
);
