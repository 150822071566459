import clsx from "clsx";
import { forwardRef } from "react";

import { Icon, IconConfig, IconProps, Spinner } from "~assets";

import { ActionButtonProps, ButtonSize, ButtonVariant } from "../types";
import * as styles from "./icon-button.css";

type BaseIconButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  theme?: "light" | "dark";
  isDanger?: boolean;
  isLoading?: boolean;
} & IconConfig<"icon", "iconVariant">;

export type IconButtonProps = BaseIconButtonProps & ActionButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      icon,
      iconVariant = "regular",
      variant = "primary",
      size = "medium",
      theme = "light",
      isDanger = false,
      isLoading = false,
      ...rest
    },
    ref,
  ) => {
    const rootClass = clsx(
      styles.root({
        variant,
        size,
        theme,
        isDanger,
        isLoading,
      }),
      className,
    );

    return (
      <button
        ref={ref}
        {...rest}
        disabled={rest.disabled ?? isLoading}
        className={rootClass}
      >
        {isLoading && <Spinner className={styles.spinner} />}
        {/* the union type is lost from props to assigning, so have to use "as" here */}
        <Icon
          {...({ name: icon, variant: iconVariant } as IconProps)}
          className={styles.icon}
        />
      </button>
    );
  },
);

IconButton.displayName = "IconButton";
