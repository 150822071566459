import { LinkProps as RouterLinkProps } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";
import { ADMIN_AUTH_DATA_STORAGE_KEY } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { AuthData } from "@ag/utils/schemas";
import { loadFromLocalStorage } from "@ag/utils/storage";

import { env } from "~config";
import { Page } from "~features/permission";

type Link = (
  | {
      to: RouterLinkProps["to"];
      href?: never;
    }
  | {
      to?: never;
      href: string;
    }
) & { title: string; isSystemAdminOnly?: boolean };

type Section = {
  title: string;
  links: Partial<Record<Page, Link>>;
  isSystemAdminOnly?: boolean;
};

export const buildAPIUrl = (path: string) => {
  // TODO: Refactor external services auth once agreed
  const authData = loadFromLocalStorage<AuthData>(ADMIN_AUTH_DATA_STORAGE_KEY);

  const params = getSearchParams({
    "Admin-Authorization": `Bearer ${authData?.token}`,
  });

  return `${env.REACT_APP_API_RUBY_BASE_URL}/${path}${params}`;
};

export const getNavigationSections = () => {
  const sections: Section[] = [
    {
      title: I18n.t("js.layout.sidebar.users_section.title"),
      links: {
        [Page.UserValidation]: {
          title: I18n.t("js.admin.layout.users"),
          to: "/users",
        },
        [Page.AssignedUsers]: {
          title: "Assigned users",
          to: "/assigned-users",
        },
        [Page.Admins]: {
          title: I18n.t("js.layout.sidebar.users_section.admins"),
          to: "/admins",
        },
      },
    },
    {
      title: I18n.t("js.layout.sidebar.carbon_operations_section.title"),
      links: {
        [Page.Fields]: {
          title: I18n.t("js.admin.layout.fields"),
          to: "/carbon/fields",
        },
        [Page.ValidationRequests]: {
          title: I18n.t("js.admin.validation_requests.list.title"),
          to: "/carbon/validation-requests",
        },
        [Page.CarbonContracts]: {
          title: I18n.t("js.admin.contracts.title"),
          to: "/carbon/contracts",
        },
      },
    },
    {
      title: "Sales Agreements",
      links: {
        [Page.SalesOffers]: {
          title: "Offers",
          to: "/carbon/agreements/offers",
        },
        [Page.ResaleAgreements]: {
          title: "Resale",
          to: "/carbon/agreements/resale",
        },
        [Page.BuyoutContracts]: {
          title: "Buyout",
          to: "/carbon/agreements/buyout",
        },
      },
    },
    {
      title: I18n.t("js.layout.sidebar.carbon_settings_section.title"),
      links: {
        [Page.CropTypes]: {
          title: I18n.t("js.carbon.crop_types.title"),
          to: "/carbon/crop-types",
        },
        [Page.Fertilisers]: {
          title: I18n.t("js.admin.fertilisers"),
          to: "/carbon/fertilisers",
        },
      },
    },
    {
      title: I18n.t("js.layout.sidebar.carbon_mrv_section.title"),
      links: {
        [Page.QualityAssurance]: {
          title: I18n.t("js.layout.sidebar.assurance.title"),
          to: "/carbon/assurance",
        },
        [Page.QualityControl]: {
          title: I18n.t("js.layout.sidebar.cq_control.title"),
          to: "/carbon/cq-control?sizeHa.min=4",
        },
        // TODO: uncomment when feature is ready for release
        // [Page.BoundaryVerification]: {
        //   title: "Boundary verification",
        //   to: "/carbon/boundary-verification",
        // },
      },
    },
    /* Temporary functionality before the final Ledger */
    {
      title: "Ledger",
      links: {
        [Page.Ledger]: {
          title: I18n.t("js.ledger.certificate_groups"),
          to: "/ledger",
        },
      },
    },
    /* End of temp */
    {
      isSystemAdminOnly: true,
      title: I18n.t("js.layout.sidebar.settings_section.title"),
      links: {
        [Page.ApplicationSettings]: {
          title: I18n.t("js.admin.application_settings"),
          to: "/settings/application",
        },
        [Page.Translations]: {
          title: I18n.t("js.layout.sidebar.settings_section.translations"),
          to: "/settings/translations",
        },
      },
    },
  ];

  return sections;
};
