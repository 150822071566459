import { EmptyState } from "@ag/design-system/organisms";

import * as styles from "./no-certificates-state.css";

type Props = {
  onStartImport: () => void;
};

export const NoCertificatesState = ({ onStartImport }: Props) => {
  return (
    <EmptyState.Root className={styles.emptyStateWrapper}>
      <EmptyState.Image />

      <EmptyState.Title>Start a new VCU import</EmptyState.Title>

      <EmptyState.Description>
        Import Verra VCU file to populate the ledger
      </EmptyState.Description>

      <EmptyState.PrimaryButton onClick={onStartImport}>
        Start new import
      </EmptyState.PrimaryButton>
    </EmptyState.Root>
  );
};
