
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/components/boundaries-drawing-manager/action-tips-banner/action-tips-banner.css.ts", "@ag/carbon");
      import { style } from "@vanilla-extract/css";
import { recipe } from "@vanilla-extract/recipes";
import { colors, radius, typography } from "@ag/design-system/tokens";
export const root = style({
  position: "absolute",
  top: 38,
  left: "50%",
  transform: "translate(-50%, 0)",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  width: "calc(100% - 32px)" // add margin 2x16px for absolute element
}, "root");
export const banner = recipe({
  base: [typography.h5, {
    padding: "8px 16px",
    borderRadius: radius[100],
    color: colors.black[900],
    textAlign: "center"
  }],
  variants: {
    type: {
      info: {
        backgroundColor: colors.white[100]
      },
      warning: {
        backgroundColor: colors.white[100]
      }
    }
  }
}, "banner");
export const icon = style({
  marginRight: 8
}, "icon");
      endFileScope();
      
    