import { GoogleMap } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import { Field, withTypes } from "react-final-form";

import { Modal, ModalSizes } from "@ag/components/Modal";
import { ActionButton } from "@ag/components/buttons";
import { FormInput } from "@ag/components/form";
import { SCard, SCol, SFlexDiv, SRow } from "@ag/components/styled";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { FieldDetails, useUpdateFieldMutation } from "~queries/carbon";

import CommentModal from "../CommentModal";
import Marker from "./Marker";
import { MAP_OPTIONS } from "./constants";
import { validate } from "./helpers";
import { SForm, SMap, SModalMap } from "./styled";
import { EditFieldAttribute, EditFieldData } from "./types";

const { Form: FinalForm } = withTypes<EditFieldData>();

type Props = {
  field: FieldDetails;
};

const FieldDataBox = ({ field }: Props) => {
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const [fieldDataToSubmit, setFieldDataToSubmit] = useState<
    EditFieldData | undefined
  >();

  const initialValues: EditFieldData = useMemo(
    () => ({
      name: field.name,
      sizeHa: field.sizeHa,
      latitude: field.position.lat,
      longitude: field.position.lng,
    }),
    [field],
  );

  const updateFieldMutation = useUpdateFieldMutation();

  const handleSubmitFieldData = (comment: string) => {
    if (!fieldDataToSubmit) return;

    updateFieldMutation.mutate(
      {
        fieldId: field.id,
        fieldData: {
          name: fieldDataToSubmit.name,
          sizeHa: fieldDataToSubmit.sizeHa,
          position: {
            lat: fieldDataToSubmit?.latitude,
            lng: fieldDataToSubmit?.longitude,
          },
          comment: {
            text: comment,
          },
        },
      },
      {
        onSuccess: async () => {
          ToastNotification.success(
            I18n.t("js.admin.field_details.field_updated_success"),
          );
        },
      },
    );

    setFieldDataToSubmit(undefined);
  };

  const isCommentModalVisible = Boolean(fieldDataToSubmit);

  return (
    <SCard boxShadow>
      <FinalForm
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <SRow>
            <SCol>
              <SForm>
                <SRow>
                  <SCol col={8}>
                    <Field
                      name={EditFieldAttribute.Name}
                      label={I18n.t("js.carbon.field_name")}
                      component={FormInput}
                    />
                  </SCol>

                  <SCol>
                    <Field
                      name={EditFieldAttribute.SizeHa}
                      label={I18n.t("js.carbon.field.size")}
                      rightElement={I18n.t("js.carbon.field.ha")}
                      component={FormInput}
                    />
                  </SCol>
                </SRow>

                <SRow>
                  <SCol>
                    <Field
                      name={EditFieldAttribute.Latitude}
                      label={I18n.t("js.carbon.latitude")}
                      component={FormInput}
                    />
                  </SCol>

                  <SCol>
                    <Field
                      name={EditFieldAttribute.Longitude}
                      label={I18n.t("js.carbon.longitude")}
                      component={FormInput}
                    />
                  </SCol>
                </SRow>

                <SFlexDiv justifyContent="end">
                  <ActionButton HTMLType="submit" onClick={handleSubmit}>
                    {I18n.t("js.shared.save")}
                  </ActionButton>
                </SFlexDiv>
              </SForm>
            </SCol>

            <SCol>
              <SMap>
                <GoogleMap
                  mapContainerClassName="field-map"
                  zoom={15}
                  center={{ lat: values.latitude, lng: values.longitude }}
                  options={MAP_OPTIONS}
                  onClick={() => setIsMapModalVisible(true)}
                >
                  <Marker />
                </GoogleMap>

                <Modal
                  size={ModalSizes.ExtraLarge}
                  noPadding
                  isOpen={isMapModalVisible}
                  onRequestClose={() => setIsMapModalVisible(false)}
                >
                  <SModalMap>
                    <GoogleMap
                      mapContainerClassName="field-map"
                      zoom={15}
                      center={{
                        lat: values.latitude,
                        lng: values.longitude,
                      }}
                      options={MAP_OPTIONS}
                      onClick={() => setIsMapModalVisible(true)}
                    >
                      <Marker />
                    </GoogleMap>
                  </SModalMap>
                </Modal>
              </SMap>
            </SCol>
          </SRow>
        )}
        onSubmit={(values: EditFieldData) => setFieldDataToSubmit(values)}
      />

      <CommentModal
        isOpen={isCommentModalVisible}
        isSubmitting={updateFieldMutation.isLoading}
        onClose={() => setFieldDataToSubmit(undefined)}
        onSubmit={handleSubmitFieldData}
      />
    </SCard>
  );
};

export default FieldDataBox;
