import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useField, useForm } from "react-final-form";

import { H8 } from "@ag/components/styled";
import I18n from "@ag/i18n";

import { SFallow, SFormCheckbox, SUnsavedIndicator } from "./styled";
import { FallowAttribute, FallowV2022Data } from "./types";

type Props = {
  isFallow?: never;
  isReadonly: boolean;
  onFormInitialize?: (form: FormApi<FallowV2022Data>) => void;
};

export const FallowForm = ({ isReadonly, onFormInitialize }: Props) => {
  const form = useForm<FallowV2022Data>();
  const { meta: fallowMeta } = useField(FallowAttribute.Fallow);

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  return (
    <SFallow>
      <H8>{I18n.t("js.carbon.field_details.set_aside")}</H8>

      <Field
        type="checkbox"
        label={I18n.t(
          "js.carbon.field_details.fallow_drought_biodiversity_grazing",
        )}
        name={FallowAttribute.Fallow}
        component={SFormCheckbox}
        tooltip={I18n.t("js.carbon.field_details.fallow_or_drought_tooltip")}
        labelBold
        disabled={isReadonly}
      />

      {fallowMeta.dirty && (
        <SUnsavedIndicator>{I18n.t("js.shared.unsaved")}</SUnsavedIndicator>
      )}
    </SFallow>
  );
};
