import { style } from "@ag/ui";

export const FARMER_REPORTED_OR_LEGACY_DETECTED_FIELD_POLYGON_OPTIONS: google.maps.PolygonOptions =
  {
    strokeColor: style.color.blue[500],
    strokeWeight: 2,
    fillColor: style.color.blue[500],
    fillOpacity: 0.3,
  };

export const MRV_REPORTED_FIELD_POLYGON_OPTIONS: google.maps.PolygonOptions = {
  strokeColor: style.color.orange[500],
  strokeWeight: 2,
  fillColor: style.color.orange[500],
  fillOpacity: 0.2,
};
