import { format } from "date-fns";

import { Chip } from "@ag/design-system/atoms";
import { cluster, grid } from "@ag/design-system/utils";
import I18n from "@ag/i18n";

import DataCard from "~components/data-card";

import { User } from "../entities/user";
import { UserOverviewSkeleton } from "./user-overview-skeleton";
import * as styles from "./user-overview.css";

type Props = {
  user: User | undefined;
  isLoading: boolean;
};

export const UserOverview = ({ user, isLoading }: Props) => {
  if (isLoading) return <UserOverviewSkeleton />;

  if (!user) return null;

  return (
    <>
      <div className={grid()}>
        <DataCard.Root>
          <DataCard.Title>
            {I18n.t("js.user_details.user_details")}
          </DataCard.Title>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.id")}
            </DataCard.Label>
            <DataCard.Value>{user.id}</DataCard.Value>
          </DataCard.Row>

          <DataCard.Row highlight>
            <DataCard.Label>
              {I18n.t("js.users.details.user.email")}
            </DataCard.Label>
            <DataCard.Value className={styles.emailValue}>
              {user.email}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.name")}
            </DataCard.Label>
            <DataCard.Value>{user.name}</DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.phone_number")}
            </DataCard.Label>
            <DataCard.Value>{user.profile.phoneNumber}</DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.admin.admins.roles")}</DataCard.Label>
            <DataCard.Value>
              <div className={cluster({ gap: 4 })}>
                {user.roles.map(role => (
                  <Chip key={role}>{role}</Chip>
                ))}
              </div>
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.accepted_newsletter")}
            </DataCard.Label>
            <DataCard.Value>
              {user.freightAndTradeSetting?.acceptedNewsletter
                ? I18n.t("js.shared.yes")
                : I18n.t("js.shared.no")}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.total_ha_under_management")}
            </DataCard.Label>
            <DataCard.Value>
              {user.freightAndTradeSetting?.totalFieldsSizeHa ?? "-"}
            </DataCard.Value>
          </DataCard.Row>
        </DataCard.Root>

        <DataCard.Root>
          <DataCard.Title>
            {I18n.t("js.user_details.company_details")}
          </DataCard.Title>

          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.shared.name")}</DataCard.Label>
            <DataCard.Value>{user.company?.name ?? "-"}</DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.country")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.address.country.name ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.address")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.address.address ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.vat_number")}
            </DataCard.Label>
            <DataCard.Value>{user.company?.vatNumber ?? "-"}</DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.registration_number")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.registrationNumber ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.country")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.address.country.name ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.address")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.address.address ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.city")}
            </DataCard.Label>
            <DataCard.Value>{user.company?.address.city ?? "-"}</DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.zip_code")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.address.zipCode ?? "-"}
            </DataCard.Value>
          </DataCard.Row>
        </DataCard.Root>

        <DataCard.Root>
          <DataCard.Title>{I18n.t("js.user_details.activity")}</DataCard.Title>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.created_at")}
            </DataCard.Label>
            <DataCard.Value>
              {format(new Date(user.createdAt), "dd.MM.yyyy")}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.confirmed_at")}
            </DataCard.Label>
            <DataCard.Value>
              {user.confirmedAt
                ? format(new Date(user.confirmedAt), "dd.MM.yyyy")
                : "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.validated_at")}
            </DataCard.Label>
            <DataCard.Value>
              {user.validatedAt
                ? format(new Date(user.validatedAt), "dd.MM.yyyy")
                : "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.last_sign_in_at")}
            </DataCard.Label>
            <DataCard.Value>
              {user.lastSignInAt
                ? format(new Date(user.lastSignInAt), "dd.MM.yyyy")
                : "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.users.details.user.last_activity_at")}
            </DataCard.Label>
            <DataCard.Value>
              {user.lastActivityAt
                ? format(new Date(user.lastActivityAt), "dd.MM.yyyy")
                : "-"}
            </DataCard.Value>
          </DataCard.Row>
        </DataCard.Root>
      </div>

      <DataCard.Root>
        <DataCard.Title>
          {I18n.t("js.admin.contracts.contract_signer_details")}
        </DataCard.Title>

        <div className={grid({ gap: 4 })}>
          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.user_details.last_name")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.contractSignerLastName ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.shared.email")}</DataCard.Label>
            <DataCard.Value className={styles.emailValue}>
              {user.company?.contractSignerEmail ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.user_details.phone_number")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.contractSignerPhoneNumber ?? "-"}
            </DataCard.Value>
          </DataCard.Row>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.user_details.country_code")}
            </DataCard.Label>
            <DataCard.Value>
              {user.company?.contractSignerCountryCode ?? "-"}
            </DataCard.Value>
          </DataCard.Row>
        </div>
      </DataCard.Root>
    </>
  );
};
