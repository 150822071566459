import { format } from "date-fns";
import React from "react";

import { Chip } from "@ag/design-system/atoms";
import { Card } from "@ag/design-system/organisms";

import { formatCurrency } from "~features/sales-offers/helpers/formatters";

import { BuyoutAgreement } from "../entities/buyout-agreement";
import {
  getContractStatusChipText,
  getContractStatusChipVariant,
  getDocusignStatusChipVariant,
  getDocusignStatusText,
} from "../helpers/buyout-agreement-statuses";

type DetailsCardProps = {
  title: string;
  details: { label: string; value: string | number | React.ReactElement }[];
  testid?: string;
};

const DetailsCard = ({ title, details, testid }: DetailsCardProps) => (
  <Card>
    <div className="flex min-h-[420px] flex-col gap-2" data-testid={testid}>
      <h5 className="text-h5">{title}</h5>

      <div className="flex-1 rounded bg-white-100 py-2">
        {details.map(({ label, value }) => (
          <>
            <p className="pb-1 text-p2 text-grey-800">{label}</p>
            <p className="pb-2 text-p2 text-grey-900">{value}</p>
          </>
        ))}
      </div>
    </div>
  </Card>
);

export const BuyoutAgreementOverview = ({
  userId,
  id,
  carbonCountry,
  year,
  status,
  statusReason,
  estimatedGhgCertificates,
  pricePerCertificateCents,
  totalPriceCents,
  docusignEnvelope,
}: BuyoutAgreement) => (
  <div>
    {/* TODO [ASS-87] => Add Payment status selection  */}
    <h4 className="block pb-4 text-h4">Agreement details</h4>

    <section className="grid grid-cols-2 gap-8 xl:grid-cols-[repeat(4,1fr)]">
      <DetailsCard
        title="User details"
        details={[
          {
            label: "User ID",
            value: (
              <a
                href={`/users/${userId}`}
                target="_blank"
                rel="noreferrer noopener"
                className="text-h5 text-green-800 underline"
              >
                {userId}
              </a>
            ),
          },
          { label: "Country", value: carbonCountry },
        ]}
      />
      <DetailsCard
        title="Buyout agreement details"
        details={[
          { label: "ID", value: id },
          { label: "Harvest year", value: year },
          {
            label: "Status",
            value: status ? (
              <Chip variant={getContractStatusChipVariant(status)}>
                {getContractStatusChipText(status)}
              </Chip>
            ) : (
              "-"
            ),
          },
          { label: "Reason", value: statusReason ?? "-" },
        ]}
        testid="buyout-agreement-details"
      />
      <DetailsCard
        title="Certificates"
        details={[
          {
            label: "Number of certificates",
            value: estimatedGhgCertificates ?? "-",
          },
          {
            label: "Price per certificate",
            value: pricePerCertificateCents
              ? formatCurrency(String(pricePerCertificateCents / 100))
              : "-",
          },
          {
            label: "Total certificates price",
            value: totalPriceCents
              ? formatCurrency(String(totalPriceCents / 100))
              : "-",
          },
        ]}
      />
      <DetailsCard
        title="Docusign"
        details={[
          {
            label: "ID",
            value: docusignEnvelope?.docusignEnvelopeId ?? "-",
          },
          {
            label: "Docusign Status",
            value: docusignEnvelope?.docusignStatus ? (
              <Chip
                variant={getDocusignStatusChipVariant(
                  docusignEnvelope.docusignStatus,
                )}
              >
                {getDocusignStatusText(docusignEnvelope.docusignStatus)}
              </Chip>
            ) : (
              "-"
            ),
          },
          {
            label: "Sent at",
            value: docusignEnvelope?.sentAt
              ? format(new Date(docusignEnvelope.sentAt), "yyyy-MM-dd")
              : "-",
          },
          {
            label: "Delivered at",
            value: docusignEnvelope?.deliveredAt
              ? format(new Date(docusignEnvelope.deliveredAt), "yyyy-MM-dd")
              : "-",
          },
          {
            label: "Signed at",
            value: docusignEnvelope?.signedAt
              ? format(new Date(docusignEnvelope.signedAt), "yyyy-MM-dd")
              : "-",
          },
          {
            label: "Expires at",
            value: docusignEnvelope?.expiresAt
              ? format(new Date(docusignEnvelope.expiresAt), "yyyy-MM-dd")
              : "-",
          },
        ]}
        testid="docusign-details"
      />
    </section>
  </div>
);
