import "src/tokens/colors.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/colors.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4WX3W4jKRCF7+cpIu21peYfZq4KqngPb8abjcZjR06i0Wi1775qFySmAa+58MV3uptzuoDqr5fz+e3hny8PD7vd4/l4vuyeLofDaSeW5evDHwlIJ/jWUXmlwSUXUk/VlVoDzoue6itV0is7uNZc6bKYZEJPbaXaDO7sCtWgfU99oSor6mmoVCl5S88v+2MJA4kyxQ5yFimRJdFBjiImRHQd5CQgokrYQQ4iQFqi7yDn4ENUAB3kGJwLGHorvrwb71xvhUMwygmrb+HbvhZEDlm1GayMI8iKXOtyZZwAJYypuycHQCblKLeM/SMmBcuWsf0kIfhuLuweXFic3TI275Nztnsee3fCkG7K48/j+6GYj4RA2EF2D3otgQ6yfR8jYe4g+3cqLm1uV8gBGACZ+is5AW19iP2VHIHSbgn9bDkDaYxvF+cVcgjCq9wGdL7sT081hpxpSWaASxlklBAGWBWcjMcB1gXH4PQAm4KBTBxgDoSkz+3rK5gjSdYJLQaYQ4FgrBoZC6VuFMlmuf0+HI/nXx+xZEA1wDWWrMENcI2FsF2RBddYyJrRs2ssCFIPMMeCMToxuppjiS4YIQeYYwnKmWU0tbJkwNDSFP7l8P0jE0y0bFkNBALQltU0/GbzWlmNwmnntqzmYGJbHiurIWjfnhorq4WhhenuWatCGaO2rJaEBL09xX5X824dHWT3lNfRQbaPeh0d1GXbW0cHOYC4rKOD5bzAdXSQI7Cwjg76stGso4McglTruIW//n5++9wt1l+70+wff3wcuOvvln7fv+13L++Xl2O9AyHF9g63Go4TAe1cw6kmE2muKYcxgpxrypksvZhrStTKKdIzTTmhhdXJzjQcvElGgphpOEMtFDjZaTZ7djZ5qqkLkmAw583ujaM5b7fwOJjzZh8POJ+zrQs9DGqj2dFJ2zTX+LK4Dc01nCEIjQPNbS9MLuc8k5Qq1DmTmkhKKwg540zC+YWYIeJEwvE5Q6LtvG8ktlROzA4mEg5P5JDtbC6cnTAO9WwuZQmjXVQ/l5fn04/PhgHyoIhXRSk9QDcI7qoohWeAkhsrStkt3kAcKzg1iib5wcJdFeWMQKXMxEs5KYIweuKFI4tyEbKP7Koop4ZeFtnf46bTJJVhsMXctJtpyWqmqD0n+UxjRWk8BfpBRd90nxqSHbyXmxZUiahw4qV8j+UQYhgr6jeZdW7ipR4TmmzzlJ+H19f90/PpaXe4XM6XWmlEkuQ9Xd3GIEG6pyvblHfC3X1u6aCCSOLu/cqbp+1x96n7tb+c1v+PDTvQ/yg/O8e2z++VxU2Mue0SeyX7weBc29P1SnZkrUxLGCufT3+d67ezxJDvydiLS2Bo4uUqKx9F6A35O7LyBURWt9W7kZXiAiVxYvb1/fHx8Ppal6XIYlZeVclGwJJsm9teWbz4BH5SYlVZ7PiAZlJkVVk+4Iy3Cr99+fc/qB8std4RAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/elevation.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/elevation.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzFnbW1heDYwOiAwIDRweCA4cHggcmdiYSgwLDAsMCwwLjA1KTsKICAtLV8xZ21tYXg2MTogMCA4cHggOHB4IHJnYmEoMCwwLDAsMC4xKTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/radius.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/radius.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tcmFkaXVzLTEwMDogNHB4OwogIC0tcmFkaXVzLTIwMDogOHB4Owp9\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/spacing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/spacing.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzEzYTYxd3QwOiA0cHg7CiAgLS1fMTNhNjF3dDE6IDhweDsKICAtLV8xM2E2MXd0MjogMTZweDsKICAtLV8xM2E2MXd0MzogMjRweDsKICAtLV8xM2E2MXd0NDogMzJweDsKICAtLV8xM2E2MXd0NTogNDBweDsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/typography.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/typography.css.ts.vanilla.css\",\"source\":\"Ll83YnVxdGIwIHsKICBmb250LXNpemU6IDMycHg7CiAgbGluZS1oZWlnaHQ6IDQ4cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjEgewogIGZvbnQtc2l6ZTogMjRweDsKICBsaW5lLWhlaWdodDogMzZweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiMiB7CiAgZm9udC1zaXplOiAyMHB4OwogIGxpbmUtaGVpZ2h0OiAzMHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl83YnVxdGIzIHsKICBmb250LXNpemU6IDE2cHg7CiAgbGluZS1oZWlnaHQ6IDI0cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjQgewogIGZvbnQtc2l6ZTogMTRweDsKICBsaW5lLWhlaWdodDogMjBweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiNSB7CiAgZm9udC1zaXplOiAxMnB4OwogIGxpbmUtaGVpZ2h0OiAyMHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl83YnVxdGI2IHsKICBmb250LXNpemU6IDExcHg7CiAgbGluZS1oZWlnaHQ6IDE2cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjcgewogIGZvbnQtc2l6ZTogMTBweDsKICBsaW5lLWhlaWdodDogMTRweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiOCB7CiAgZm9udC1zaXplOiAxNnB4OwogIGxpbmUtaGVpZ2h0OiAyNHB4OwogIGZvbnQtd2VpZ2h0OiA0MDA7Cn0KLl83YnVxdGI5IHsKICBmb250LXNpemU6IDE0cHg7CiAgbGluZS1oZWlnaHQ6IDIwcHg7CiAgZm9udC13ZWlnaHQ6IDQwMDsKfQouXzdidXF0YmEgewogIGZvbnQtc2l6ZTogMTJweDsKICBsaW5lLWhlaWdodDogMjBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fN2J1cXRiYiB7CiAgZm9udC1zaXplOiAxNHB4OwogIGxpbmUtaGVpZ2h0OiAyMHB4OwogIGZvbnQtd2VpZ2h0OiA2MDA7Cn0=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/animations.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/animations.css.ts.vanilla.css\",\"source\":\"QGtleWZyYW1lcyBfMXNybzl5NzAgewogIGZyb20gewogICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC0xMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzEgewogIHRvIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgtMTAwJSk7CiAgfQp9CkBrZXlmcmFtZXMgXzFzcm85eTcyIHsKICBmcm9tIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzMgewogIHRvIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzQgewogIGZyb20gewogICAgb3BhY2l0eTogMDsKICB9CiAgdG8gewogICAgb3BhY2l0eTogMTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzUgewogIGZyb20gewogICAgb3BhY2l0eTogMTsKICB9CiAgdG8gewogICAgb3BhY2l0eTogMDsKICB9Cn0=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/TableSettings/table-settings.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/TableSettings/table-settings.css.ts.vanilla.css\",\"source\":\"Ll8xMThvc2E1MCB7CiAgZGlzcGxheTogZ3JpZDsKICBncmlkLWF1dG8tcm93czogYXV0bzsKfQouXzExOG9zYTUxIHsKICBtYXJnaW4tYm90dG9tOiAwLjVyZW07Cn0KLl8xMThvc2E1MiB7CiAgZGlzcGxheTogZ3JpZDsKICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciBhdXRvOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAgZ2FwOiAxNnB4OwogIHBhZGRpbmctYmxvY2s6IDhweDsKfQouXzExOG9zYTUyICsgLl8xMThvc2E1MiB7CiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHZhcigtLWNvbG9yLWdyZXktMjAwKTsKfQouXzExOG9zYTUzIHsKICBkaXNwbGF5OiBmbGV4OwogIGdhcDogMTBweDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIG1hcmdpbjogMDsKfQouXzExOG9zYTU0IHsKICBtYXJnaW4tdG9wOiAtNHB4OwogIG1hcmdpbi1yaWdodDogNHB4OwogIGZvbnQtc2l6ZTogMTZweDsKICBmb250LXdlaWdodDogNTAwOwogIGNvbG9yOiB2YXIoLS1jb2xvci1ncmV5LTcwMCk7Cn0KLl8xMThvc2E1NSB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGJvcmRlcjogbm9uZTsKICBiYWNrZ3JvdW5kOiBub25lOwogIG91dGxpbmU6IG5vbmU7CiAgY3Vyc29yOiBwb2ludGVyOwogIGJvcmRlci1sZWZ0OiAxcHggc29saWQgdmFyKC0tY29sb3ItZ3JleS0yMDApOwogIHBhZGRpbmctbGVmdDogOHB4Owp9Ci5fMTE4b3NhNTYgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBib3JkZXI6IG5vbmU7CiAgYmFja2dyb3VuZDogbm9uZTsKICBvdXRsaW5lOiBub25lOwogIGN1cnNvcjogcG9pbnRlcjsKICBib3JkZXItbGVmdDogMXB4IHNvbGlkIHZhcigtLWNvbG9yLWdyZXktMjAwKTsKICBwYWRkaW5nLWxlZnQ6IDhweDsKfQouXzExOG9zYTU3IHsKICBmb250LXNpemU6IDEwcHg7Cn0=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var item = '_118osa52 _7buqtb9';
export var label = '_118osa53';
export var nestedIcon = '_118osa54';
export var pinnedDirectionIcon = '_118osa57';
export var pinnedToggle = '_118osa55';
export var root = _7a468({defaultClassName:'_118osa50',variantClassNames:{},defaultVariants:{},compoundVariants:[]});
export var sectionHeader = '_118osa51 _7buqtb3';
export var sizeToggle = '_118osa56';