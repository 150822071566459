import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";

type Props<TValues extends FieldValues> = {
  values: TValues;
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};
export const CropTypesFilters = <TValues extends FieldValues>({
  values,
  onChange,
  onClear,
}: Props<TValues>) => {
  const { data: openHarvestYearsOptions } = useOpenHarvestYearsOptions();

  return (
    <Filters.Root values={values} onChange={onChange}>
      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("name")}
            label={I18n.t("js.carbon.crop_type_attribute.name")}
          />
        )}
      />

      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("coolfarmName")}
            label={I18n.t("js.carbon.crop_type_attribute.coolfarm_name")}
          />
        )}
      />

      <Filters.Item
        render={({ register }) => (
          <InputField
            {...register("coolfarmIdentifier")}
            label={I18n.t("js.carbon.crop_type_attribute.coolfarm_identifier")}
          />
        )}
      />

      {openHarvestYearsOptions && (
        <Filters.Item
          render={({ control }) => (
            <Controller
              name="harvestYear"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.carbon.crop_type_attribute.harvest_year")}
                >
                  <>
                    <Select.OptionAll>
                      {I18n.t("js.shared.all")}
                    </Select.OptionAll>

                    {openHarvestYearsOptions.map(harvestYearOption => (
                      <Select.Option
                        key={harvestYearOption.value}
                        value={String(harvestYearOption.value)}
                      >
                        {harvestYearOption.label}
                      </Select.Option>
                    ))}
                  </>
                </SelectField>
              )}
            />
          )}
        />
      )}

      <Filters.Item
        render={({ control }) => (
          <Controller
            name="methodologyVersion"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.shared.methodology_version")}
              >
                {METHODOLOGY_VERSIONS_OPTIONS.map(methodologyVersionOption => (
                  <Select.Option
                    key={methodologyVersionOption.value}
                    value={String(methodologyVersionOption.value)}
                  >
                    {methodologyVersionOption.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        )}
      />

      <Filters.Reset onClear={onClear} />
    </Filters.Root>
  );
};
