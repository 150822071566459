import { useMemo } from "react";
import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { UserValidationState } from "@ag/utils/types";

import { TimeRange } from "~constants/date";
import { Country } from "~features/countries";

import { MOST_USED_COUNTRY_CODES, TOTAL_HA_VALUES } from "./constants";

type Props<TValues extends FieldValues> = {
  values: TValues;
  countries: Country[];
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};

export const UserListFilters = <TValues extends FieldValues>({
  values,
  countries,
  onChange,
  onClear,
}: Props<TValues>) => {
  const countriesOptions = useMemo(() => {
    const mostUsedCountries = MOST_USED_COUNTRY_CODES.map(countryCode =>
      countries.find(country => country.code === countryCode),
    ).filter(Boolean) as typeof countries;

    const remainingCountries = countries.filter(
      country => !mostUsedCountries.includes(country),
    );

    return [
      ...mostUsedCountries,
      {
        code: "separator",
        name: "-----",
      } as Country,
      ...remainingCountries,
    ];
  }, [countries]);

  return (
    <>
      <Filters.Root values={values} onChange={onChange}>
        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("id")}
              type="number"
              label={I18n.t("js.admin.users.index.id")}
            />
          )}
        />

        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("search")}
              label={I18n.t("js.admin.users.index.search")}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              name="countryCode"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.admin.users.index.country")}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                  {countriesOptions.map(({ name, code }) => (
                    <Select.Option key={code} value={code}>
                      {name}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              name="validationState"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.admin.validation_status")}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                  <Select.Option
                    key={UserValidationState.NotValidated}
                    value={UserValidationState.NotValidated}
                  >
                    {I18n.t("js.user.user_validation_state.non_validated")}
                  </Select.Option>

                  <Select.Option
                    key={UserValidationState.Validated}
                    value={UserValidationState.Validated}
                  >
                    {I18n.t("js.user.user_validation_state.validated")}
                  </Select.Option>
                </SelectField>
              )}
            />
          )}
        />

        <Filters.ShowMoreItems>
          <Filters.Item
            render={({ control }) => (
              <Controller
                name="haUnderManagementRange"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectField
                    {...field}
                    error={fieldState.error}
                    label={I18n.t("js.users.total_ha_under_management")}
                  >
                    <Select.OptionAll>
                      {I18n.t("js.shared.all")}
                    </Select.OptionAll>

                    {Object.keys(TOTAL_HA_VALUES).map(label => (
                      <Select.Option key={label} value={label}>
                        {label}
                      </Select.Option>
                    ))}
                  </SelectField>
                )}
              />
            )}
          />

          <Filters.Item
            render={({ control }) => (
              <Controller
                name="lastActivity"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectField
                    {...field}
                    error={fieldState.error}
                    label={I18n.t("js.users.last_activity")}
                  >
                    <Select.Option key="none" value={"none"}>
                      {I18n.t("js.admin.users.none")}
                    </Select.Option>

                    <Select.Option
                      key={TimeRange.Today}
                      value={TimeRange.Today}
                    >
                      {I18n.t("js.admin.users.today")}
                    </Select.Option>

                    <Select.Option
                      key={TimeRange.SinceYesterday}
                      value={TimeRange.SinceYesterday}
                    >
                      {I18n.t("js.admin.users.since_yesterday")}
                    </Select.Option>

                    <Select.Option
                      key={TimeRange.Last7Days}
                      value={TimeRange.Last7Days}
                    >
                      {I18n.t("js.admin.users.last_seven_days")}
                    </Select.Option>

                    <Select.Option
                      key={TimeRange.Last14Days}
                      value={TimeRange.Last14Days}
                    >
                      {I18n.t("js.admin.users.last_fourteen_days")}
                    </Select.Option>

                    <Select.Option
                      key={TimeRange.LastMonth}
                      value={TimeRange.LastMonth}
                    >
                      {I18n.t("js.admin.users.last_month")}
                    </Select.Option>

                    <Select.Option
                      key={TimeRange.Last3Months}
                      value={TimeRange.Last3Months}
                    >
                      {I18n.t("js.admin.users.last_three_months")}
                    </Select.Option>

                    <Select.Option
                      key={TimeRange.Last6Months}
                      value={TimeRange.Last6Months}
                    >
                      {I18n.t("js.admin.users.last_six_months")}
                    </Select.Option>
                  </SelectField>
                )}
              />
            )}
          />
        </Filters.ShowMoreItems>

        <Filters.Reset onClear={onClear} />

        <Filters.ShowMoreToggle />
      </Filters.Root>
    </>
  );
};
