import { useCallback, useState } from "react";
import { createEnumParam } from "serialize-query-params";
import { StringParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";
import { UserValidationState } from "@ag/utils/types";

import Table from "~components/table";
import { env } from "~config";
import { TimeRange } from "~constants/date";
import { useCountries } from "~features/countries";
import { useSyncHubspotCarbonStatsMutation } from "~features/hubspot";
import { AuthorizedSidebar } from "~features/navigation";
import {
  DeleteUserConfirmation,
  TOTAL_HA_VALUES,
  TotalHaValue,
  UserListFilters,
  getDateRangeFromTimeRange,
  useDeleteUserMutation,
  useSignInAsMutation,
  useUsersQuery,
  useUsersTable,
  useValidateUserMutation,
} from "~features/user";
import ListLayout from "~layouts/list-layout";

const ValidationStateParam = createEnumParam(
  Object.values(UserValidationState),
);
const TotalHaParam = createEnumParam(Object.values(TotalHaValue));

const filtersConfig = {
  validationState: ValidationStateParam,
  id: [StringParam, { isDebounced: true }],
  search: [StringParam, { isDebounced: true }],
  haUnderManagementRange: TotalHaParam,
  countryCode: StringParam,
  lastActivity: StringParam,
} as const;

const Users = () => {
  const [userToDelete, setUserToDelete] = useState<{
    id: string;
    email: string;
  } | null>(null);

  const { data: countries } = useCountries();

  const filters = useFilters(filtersConfig);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const {
    id,
    search,
    validationState,
    haUnderManagementRange,
    countryCode,
    lastActivity,
  } = filters.values;

  const lastActivityAtRange =
    lastActivity && lastActivity !== "none"
      ? getDateRangeFromTimeRange(lastActivity as TimeRange)
      : undefined;

  const { data: usersData, isLoading } = useUsersQuery({
    ...pagination,
    filters: {
      validationStates: validationState ? [validationState] : undefined,
      countryCodes:
        !countryCode || countryCode === "separator" ? undefined : [countryCode],
      ids: id ? [Number(id)] : undefined,
      search,
      haUnderManagementRange: haUnderManagementRange
        ? TOTAL_HA_VALUES[haUnderManagementRange]
        : undefined,
      lastActivityAtRange,
    },
  });

  const { mutate: validateUser } = useValidateUserMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const { mutate: signInAs } = useSignInAsMutation();
  const { mutate: syncHubspotCarbonStats } =
    useSyncHubspotCarbonStatsMutation();

  const handleSignInAs = useCallback(
    (userId: string) => {
      signInAs(userId, {
        onSuccess: ({ ticket }) => {
          const params = getSearchParams({
            [SIGN_IN_AS_PARAM]: ticket,
          });

          window.open(env.REACT_APP_FARMER_URL + params);
        },
      });
    },
    [signInAs],
  );

  const handleValidate = useCallback(
    (userId: string) => {
      validateUser(userId, {
        onSuccess: () =>
          ToastNotification.success(I18n.t("js.users.user_validated")),
      });
    },
    [validateUser],
  );

  const handleSyncHubspotCarbonStats = useCallback(
    (userId: string) => {
      syncHubspotCarbonStats([userId], {
        onSuccess: () => {
          ToastNotification.success("Carbon Stats sync is initiated");
        },
      });
    },
    [syncHubspotCarbonStats],
  );

  const table = useUsersTable({
    users: usersData?.items,
    onDelete: setUserToDelete,
    onValidate: handleValidate,
    onSignIn: handleSignInAs,
    onHubspotCarbonStatsSync: handleSyncHubspotCarbonStats,
  });

  const handleDeleteUser = useCallback(() => {
    if (!userToDelete) return;

    deleteUserMutation.mutate(userToDelete.id, {
      onSettled: () => setUserToDelete(null),
      onSuccess: () =>
        ToastNotification.success(I18n.t("js.users.user_deleted")),
    });
  }, [userToDelete, deleteUserMutation]);

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>
          {I18n.t("js.admin.users.index.users")}
        </ListLayout.TopBarTitle>

        <ListLayout.TopBarActions>
          <Button to="/users/add">{I18n.t("js.shared.new")}</Button>
        </ListLayout.TopBarActions>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <UserListFilters
              countries={countries || []}
              values={filters.values}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
              }}
              onClear={() => {
                filters.onClear();
                resetPagination();
              }}
            />
          </ListLayout.Filters>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={usersData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>

      <DeleteUserConfirmation
        userToDelete={userToDelete}
        isOpen={Boolean(userToDelete)}
        isDeleting={deleteUserMutation.isLoading}
        onHide={() => setUserToDelete(null)}
        onConfirm={handleDeleteUser}
      />
    </ListLayout.Root>
  );
};

export default Users;
