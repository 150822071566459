import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { transformInitialFilterValues } from "@ag/components/Filters";
import { Button, InfoBox } from "@ag/design-system/atoms";
import { ComboBox, FloatingMenu, Select } from "@ag/design-system/molecules";
import { ComboBoxField, InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { usePagination } from "@ag/utils/hooks";

import { Filters, useSearchParamForm } from "~components/filters";
import Table, { EmptyTableState } from "~components/table";
import { useSessionContext } from "~features/auth";
import {
  CertificateGroupStandard,
  CertificateGroupStatus,
  CertificateGroupType,
  CertificateGroupsFilters,
  CertificateGroupsFiltersSchema,
  NoCertificatesState,
  SuccessSubmitModal,
  UpdateRequestAction,
  useCertificateGroupsQuery,
  useCertificateGroupsTable,
  useCreateDraftUpdateRequestMutation,
  useExportCertificateGroupsQuery,
  useUpdateRequestStore,
  useUpdateRequestsQuery,
} from "~features/certificate";
import { useCarbonCountries } from "~features/countries";
import { AuthorizedSidebar } from "~features/navigation";
import {
  MarketsResourceClass,
  Page,
  UserManagementResourceClass,
  useMarketsPermissions,
  useUserManagementPermissions,
  withPageAccess,
} from "~features/permission";
import { transformEnumToLabels, transformedLabelValue } from "~helpers";
import { trimAndDispatchInput } from "~helpers/input";
import ListLayout from "~layouts/list-layout";

const Ledger = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    certificateId: withDefault(StringParam, undefined),
    type: withDefault(
      createEnumParam(Object.values(CertificateGroupType)),
      undefined,
    ),
    status: withDefault(
      createEnumParam(Object.values(CertificateGroupStatus)),
      undefined,
    ),
    standard: withDefault(
      createEnumParam(Object.values(CertificateGroupStandard)),
      undefined,
    ),
    country: withDefault(StringParam, undefined),
    vintageYear: withDefault(StringParam, undefined),
  });

  const { certificateId, type, status, standard, country, vintageYear } = query;

  const { data: countries } = useCarbonCountries();

  const noQueryParams = !query;

  const { register, control, handleSubmit, reset } =
    useForm<CertificateGroupsFilters>({
      values: transformInitialFilterValues(query as CertificateGroupsFilters),
      resolver: zodResolver(CertificateGroupsFiltersSchema),
    });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<CertificateGroupsFilters>(
    query,
    setQuery,
    resetPagination,
  );

  const navigate = useNavigate();

  const { currentAdmin } = useSessionContext();
  const { data: marketsPermissions } = useMarketsPermissions();
  const { data: userManagementPermissions } = useUserManagementPermissions();
  const { refetch: fetchCertificatesCsv } = useExportCertificateGroupsQuery({
    filters: {
      certificateId,
      type: type ? [type] : undefined,
      status: status ? [status] : undefined,
      standard: standard ? [standard] : undefined,
      country: country ? [country] : undefined,
      vintageYear: vintageYear ? [vintageYear] : undefined,
    },
  });
  const { data: certificateGroups, isLoading } = useCertificateGroupsQuery({
    ...pagination,
    filters: {
      certificateId,
      type: type ? [type] : undefined,
      status: status ? [status] : undefined,
      standard: standard ? [standard] : undefined,
      country: country ? [country] : undefined,
      vintageYear: vintageYear ? [vintageYear] : undefined,
    },
  });

  const { data: updateRequests, isLoading: isUpdateRequestsLoading } =
    useUpdateRequestsQuery(
      {
        filters: {
          status: "pending",
        },
      },
      {
        enabled: marketsPermissions?.read?.includes(
          MarketsResourceClass.CertificateUpdateRequest,
        ),
      },
    );

  // TODO: Replace this temporary solution with proper filter on the API side
  const updateRequestsToApprove = useMemo(
    () =>
      updateRequests?.items.filter(
        request => request.requesterAdminId !== currentAdmin?.id,
      ),
    [currentAdmin?.id, updateRequests?.items],
  );

  const createDraftUpdateRequestMutation =
    useCreateDraftUpdateRequestMutation();

  const { successModalAction } = useUpdateRequestStore();

  const handleCreateUpdateRequest = async (action: UpdateRequestAction) => {
    const { id } = await createDraftUpdateRequestMutation.mutateAsync({
      action,
    });

    navigate(`/ledger/update-request/${id}`);
  };

  const renderFilterBarItem = (
    key: keyof CertificateGroupsFilters,
    value: CertificateGroupsFilters[keyof CertificateGroupsFilters],
  ) => {
    const label = (
      {
        certificateId: I18n.t("js.ledger.certificate_id"),
        type: I18n.t("js.ledger.type"),
        status: I18n.t("js.ledger.status"),
        standard: I18n.t("js.ledger.standard"),
        country: I18n.t("js.ledger.country"),
        vintageYear: I18n.t("js.ledger.vintage_year"),
      } as Record<keyof CertificateGroupsFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const groupTypeLabels = transformEnumToLabels(CertificateGroupType);
  const groupStatusLabels = transformEnumToLabels(CertificateGroupStatus);
  const groupStandardLabels = transformEnumToLabels(CertificateGroupStandard);

  const exportCertificateGroups = async () => {
    const csvData = await fetchCertificatesCsv();

    // Create a csv blob and trigger download
    const blob = new Blob([csvData.data?.data ?? ""], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    const currentDateTime = format(new Date(), "yyyy-MM-dd_hh-mm");

    a.href = url;
    a.download = `certificates-export-${currentDateTime}.csv`;
    a.click();

    window.URL.revokeObjectURL(url);
  };

  const canViewUser = userManagementPermissions?.read?.includes(
    UserManagementResourceClass.User,
  );

  const table = useCertificateGroupsTable({
    data: certificateGroups?.items,
    hasUserPermissions: Boolean(canViewUser),
  });

  const hasImportPermissions = marketsPermissions?.create?.includes(
    MarketsResourceClass.VerifiedCreditUnit,
  );
  const hasDistributePermissions = marketsPermissions?.create?.includes(
    MarketsResourceClass.CertificateGroup,
  );
  const hasApproveUpdateRequestPermissions =
    marketsPermissions?.update?.includes(
      MarketsResourceClass.CertificateUpdateRequest,
    );
  const hasUpdateRequestPermissions = marketsPermissions?.create?.includes(
    MarketsResourceClass.CertificateUpdateRequest,
  );

  const isUpdateRequestPending =
    updateRequests && updateRequests.meta.totalItems > 0;

  const canApproveUpdateRequest =
    hasApproveUpdateRequestPermissions &&
    updateRequestsToApprove &&
    updateRequestsToApprove.length > 0;

  const handleStartImport = () => {
    navigate("/ledger/import");
  };

  const isTableEmpty =
    !isLoading && noQueryParams && table.getRowModel().rows.length === 0;

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  return (
    <>
      {successModalAction && <SuccessSubmitModal />}

      <ListLayout.Root>
        <ListLayout.TopBar>
          <ListLayout.TopBarTitle>Certificate groups</ListLayout.TopBarTitle>
        </ListLayout.TopBar>

        <ListLayout.Sidebar>
          <AuthorizedSidebar />
          <Filters.Drawer
            isOpen={isFiltersOpen}
            onSubmit={handleSubmit(handleSubmitFilters)}
            onClose={() => setIsFiltersOpen(false)}
            onClear={handleFormFiltersClear}
          >
            <Controller
              name="certificateId"
              control={control}
              render={({ field, fieldState }) => (
                <InputField
                  label="Certificate ID"
                  description='Type the value (single number "1" or a range "500-600")'
                  {...field}
                  {...fieldState}
                  onChange={e =>
                    trimAndDispatchInput(e.target.value, field.onChange)
                  }
                />
              )}
            />

            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  {...fieldState}
                  value={field.value ?? ""}
                  label="Type"
                  optionsClassName="z-modal"
                >
                  <Select.OptionAll>All</Select.OptionAll>

                  {Object.values(CertificateGroupType).map(type => (
                    <Select.Option key={type} value={type}>
                      {groupTypeLabels[type]}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />

            <Controller
              name="status"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  {...fieldState}
                  value={field.value ?? ""}
                  label="Status"
                  optionsClassName="z-modal"
                >
                  <Select.OptionAll>All</Select.OptionAll>

                  {Object.values(CertificateGroupStatus).map(type => (
                    <Select.Option key={type} value={type}>
                      {groupStatusLabels[type]}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />

            <Controller
              name="standard"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  {...fieldState}
                  value={field.value ?? ""}
                  label="Standard"
                  optionsClassName="z-modal"
                >
                  <Select.OptionAll>All</Select.OptionAll>

                  {Object.values(CertificateGroupStandard).map(type => (
                    <Select.Option key={type} value={type}>
                      {groupStandardLabels[type]}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />

            <Controller
              name="country"
              control={control}
              render={({ field, fieldState }) => (
                <ComboBoxField
                  {...field}
                  {...fieldState}
                  value={field.value ?? ""}
                  label="Country"
                  loadingText="Loading..."
                  emptyText="No matching results"
                  optionsClassName="z-modal"
                >
                  {countries?.map(country => (
                    <ComboBox.Item key={country.code}>
                      {country.name}
                    </ComboBox.Item>
                  ))}
                </ComboBoxField>
              )}
            />

            <InputField {...register("vintageYear")} label="Vintage year" />
          </Filters.Drawer>
        </ListLayout.Sidebar>

        <ListLayout.Content>
          {isTableEmpty ? (
            hasImportPermissions ? (
              <NoCertificatesState onStartImport={handleStartImport} />
            ) : (
              <EmptyTableState />
            )
          ) : (
            <>
              <ListLayout.Header variant="slim">
                <Filters.Bar
                  values={query}
                  renderItem={renderFilterBarItem}
                  onToggleOpen={() => setIsFiltersOpen(value => !value)}
                  onExportTableClick={exportCertificateGroups}
                  onClear={handleClearFiltersBar}
                />

                <ListLayout.Actions>
                  {canApproveUpdateRequest && (
                    <Button
                      variant="secondary"
                      isLoading={isUpdateRequestsLoading}
                      to={`/ledger/update-request/${updateRequestsToApprove?.[0].id}/approve`}
                    >
                      {updateRequestsToApprove[0].action === "transfer"
                        ? "Approve transfer"
                        : "Approve retirement"}
                    </Button>
                  )}

                  {hasImportPermissions && hasDistributePermissions && (
                    <Button
                      variant="secondary"
                      onClick={handleStartImport}
                      disabled={isUpdateRequestPending}
                    >
                      Start new import
                    </Button>
                  )}

                  <FloatingMenu.Root
                    title="Actions"
                    isDisabled={!hasUpdateRequestPermissions}
                  >
                    <>
                      <FloatingMenu.Option
                        isDisabled={isUpdateRequestPending}
                        onClick={() => handleCreateUpdateRequest("transfer")}
                      >
                        Transfer
                      </FloatingMenu.Option>

                      <FloatingMenu.Option
                        isDisabled={isUpdateRequestPending}
                        onClick={() => handleCreateUpdateRequest("retirement")}
                      >
                        Retire
                      </FloatingMenu.Option>
                    </>
                  </FloatingMenu.Root>
                </ListLayout.Actions>

                {isUpdateRequestPending && (
                  <div style={{ gridColumn: "span 2" }}>
                    <InfoBox icon="circle-exclamation" variant="info">
                      There is a pending approval of pre-submitted update
                      request. To start a new set of Actions please ensure an
                      Approver completes the pre-submitted update request.
                    </InfoBox>
                  </div>
                )}
              </ListLayout.Header>

              <Table
                instance={table}
                meta={certificateGroups?.meta}
                pagination={pagination}
                isLoading={isLoading}
                onPaginationChange={updatePagination}
              />
            </>
          )}
        </ListLayout.Content>
      </ListLayout.Root>
    </>
  );
};

export default withPageAccess(Page.Ledger)(Ledger);
