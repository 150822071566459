import { EmptyState } from "@ag/design-system/organisms";

export const EmptyTableState = () => (
  <EmptyState.Root className="flex h-full justify-center">
    <EmptyState.Image />

    <EmptyState.Title>No results to displays</EmptyState.Title>

    <EmptyState.Description>
      Your search does not match any data
    </EmptyState.Description>
  </EmptyState.Root>
);

export default EmptyTableState;
