import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { ComboBox, Select } from "@ag/design-system/molecules";
import { ComboBoxField, InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { FieldValidationStatus } from "~features/field";

import { getValidationRequestFieldStatusLabel } from "../helpers/lookups";

type Props<TValues extends FieldValues> = {
  values: TValues;
  farms: { id: string; name: string }[];
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};
export const ValidationRequestFieldsFilters = <TValues extends FieldValues>({
  farms,
  values,
  onChange,
  onClear,
}: Props<TValues>) => {
  // "New" is not relevant for this case, as all fields are at least in progress at this point.
  const fieldValidationStatuses = Object.values(FieldValidationStatus).filter(
    value => value !== FieldValidationStatus.New,
  );

  return (
    <>
      <Filters.Root values={values} onChange={onChange}>
        <Filters.Item
          render={({ control }) => (
            <Controller
              control={control}
              name="carbonFarmId"
              render={({ field, fieldState }) => (
                <ComboBoxField
                  {...field}
                  error={fieldState.error}
                  loadingText={I18n.t("js.shared.loading")}
                  emptyText={I18n.t("js.shared.no_matching_results")}
                  label={I18n.t("js.admin.validation_request.fields.farm_name")}
                >
                  {farms.map(farm => (
                    <ComboBox.Item key={farm.id}>{farm.name}</ComboBox.Item>
                  ))}
                </ComboBoxField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("fieldName")}
              label={I18n.t("js.admin.validation_request.fields.field_name")}
            />
          )}
        />

        <Filters.Item
          render={({ register }) => (
            <InputField
              {...register("id")}
              type="number"
              label={I18n.t("js.admin.validation_request.fields.field_id")}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              control={control}
              name="validationStatus"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t(
                    "js.admin.validation_request.fields.field_validation_status",
                  )}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                  {fieldValidationStatuses.map(status => (
                    <Select.Option key={status} value={status}>
                      {getValidationRequestFieldStatusLabel(status)}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Reset onClear={() => onClear()} />
      </Filters.Root>
    </>
  );
};
