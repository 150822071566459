import { CQC_EDIT_MODE_PARAM } from "@ag/carbon/constants";
import { SoilCultivationPracticeQuestionnaireData } from "@ag/carbon/forms/baseline/iso";
import { ACTIVE_TAB_KEY } from "@ag/components/Tabs";
import I18n from "@ag/i18n";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { LabelValue } from "@ag/utils/types";

import { env } from "~config";
import { CropType } from "~queries/carbon/crop-types";
import { FieldValidationStatus } from "~queries/carbon/fields";
import { CarbonValidationStatus } from "~queries/carbon/users";

import {
  CoverCrops,
  CropResidueManagementValue,
  FieldStepTabId,
  TillingPracticeValue,
} from "./types";

// TODO: move to feature
export enum DocusignStatus {
  Sent = "sent",
  Delivered = "delivered",
  Completed = "completed",
  Declined = "declined",
  Voided = "voided",
}

type GetIsCarbonFieldDefinitionUnlockableArgs = {
  activeCarbonContractId: string | null;
  fieldDefinitionSubmittedAt: string | null;
  validationStatus: FieldValidationStatus;
};

export const getCoverCropsLabel = (coverCropsValue: CoverCrops) => {
  const coverCropsLabel: Record<CoverCrops, string> = {
    [CoverCrops.None]: I18n.t("js.carbon.cover_crop_types.none"),
    [CoverCrops.Catch]: I18n.t("js.carbon.cover_crop_types.catch"),
    [CoverCrops.Spring]: I18n.t("js.carbon.cover_crop_types.spring"),
    [CoverCrops.Winter]: I18n.t("js.carbon.cover_crop_types.winter"),
  };

  return coverCropsLabel[coverCropsValue] || "";
};

export const getLabelByCarbonValidationStatus = () => ({
  [CarbonValidationStatus.New]: I18n.t(
    "js.carbon.carbon_validation_status.new",
  ),
  [FieldValidationStatus.Validated]: I18n.t(
    "js.carbon.carbon_validation_status.validated",
  ),
  [FieldValidationStatus.Rejected]: I18n.t(
    "js.carbon.carbon_validation_status.rejected",
  ),
});

export const getValidationStatusLabel = () => ({
  [FieldValidationStatus.New]: I18n.t("js.carbon.validation_status.new"),
  [FieldValidationStatus.InProgress]: I18n.t(
    "js.carbon.validation_status.in_progress",
  ),
  [FieldValidationStatus.Validated]: I18n.t(
    "js.carbon.validation_status.validated",
  ),
  [FieldValidationStatus.Rejected]: I18n.t(
    "js.carbon.validation_status.rejected",
  ),
});

export const getCropResidueManagementLabel = () => ({
  [CropResidueManagementValue.Burned]: I18n.t(
    "js.carbon.crop_residue_management.burned",
  ),
  [CropResidueManagementValue.DontKnow]: I18n.t(
    "js.carbon.crop_residue_management.dont_know",
  ),
  [CropResidueManagementValue.Mulched]: I18n.t(
    "js.carbon.crop_residue_management.mulched",
  ),
  [CropResidueManagementValue.Removed]: I18n.t(
    "js.carbon.crop_residue_management.removed",
  ),
});

export const getLabelByDocusignStatus = () => ({
  [DocusignStatus.Sent]: I18n.t("js.carbon.docusign_status.sent"),
  [DocusignStatus.Completed]: I18n.t("js.carbon.docusign_status.completed"),
  [DocusignStatus.Declined]: I18n.t("js.carbon.docusign_status.declined"),
  [DocusignStatus.Delivered]: I18n.t("js.carbon.docusign_status.delivered"),
  [DocusignStatus.Voided]: I18n.t("js.carbon.docusign_status.voided"),
});

export const getTillingCoverCropsOptions = (): LabelValue<CoverCrops>[] => [
  {
    label: I18n.t("js.carbon.cover_crop_types.none"),
    value: CoverCrops.None,
  },
  {
    label: I18n.t("js.carbon.cover_crop_types.catch"),
    value: CoverCrops.Catch,
  },
  {
    label: I18n.t("js.carbon.cover_crop_types.spring"),
    value: CoverCrops.Spring,
  },
  {
    label: I18n.t("js.carbon.cover_crop_types.winter"),
    value: CoverCrops.Winter,
  },
];

export const getTillingPracticeValues = (): LabelValue<number>[] => [
  {
    label: I18n.t("js.admin.carbon.tilling_practice.no_till"),
    value: TillingPracticeValue.NoTill,
  },
  {
    label: I18n.t("js.admin.carbon.tilling_practice.reduced_tillage"),
    value: TillingPracticeValue.ReducedTillage,
  },
  {
    label: I18n.t("js.admin.carbon.tilling_practice.conventional"),
    value: TillingPracticeValue.Conventional,
  },
];

export const convertCropTypesToLabelValues = (
  cropTypes: CropType[] | undefined,
): LabelValue<number>[] => {
  if (!cropTypes) {
    return [];
  }

  return cropTypes.map(cropType => ({
    label: cropType.nameEn,
    value: cropType.id,
  }));
};

export const getTillagePracticeBasedOnAnswers = (
  questionnaire: SoilCultivationPracticeQuestionnaireData,
) => {
  if (questionnaire.tillageConventionalInAnyOfTheLastFiveYears) {
    return I18n.t("js.carbon.conventional");
  }

  if (questionnaire.tillageReducedInAnyTheLastFiveYears) {
    return I18n.t("js.carbon.reduced");
  }

  if (questionnaire.tillageNoTillConsistentlyTheLastFiveYears) {
    return I18n.t("js.carbon.no-till");
  }

  return "-";
};

export const getResidueManagementBasedOnAnswers = (
  questionnaire: SoilCultivationPracticeQuestionnaireData,
) => {
  if (questionnaire.cropResidueBurnedInAnyOfTheLastFiveYears) {
    return I18n.t("js.carbon.burned");
  }

  if (questionnaire.cropResidueRemovedInAnyTheLastFiveYears) {
    return I18n.t("js.carbon.removed");
  }

  if (questionnaire.cropResidueMulchedConsistentlyTheLastFiveYears) {
    return I18n.t("js.carbon.mulched");
  }

  return "-";
};

export const getCoverCropBasedOnAnswers = (
  questionnaire: SoilCultivationPracticeQuestionnaireData,
) => {
  if (questionnaire.noCoverCropGrownInTheLastFiveYears) {
    return I18n.t("js.carbon.cover_crop.no");
  }

  if (questionnaire.coverCropWinterConsistentlyTheLastFiveYears) {
    return I18n.t("js.carbon.cover_crop.until_winter");
  }

  if (questionnaire.coverCropSpringConsistentlyTheLastFiveYears) {
    return I18n.t("js.carbon.cover_crop.until_spring");
  }

  if (questionnaire.coverCropSpringConsistentlyTheLastFiveYears) {
    return I18n.t("js.carbon.cover_crop.catch_crops");
  }

  return "-";
};

export const getFertiliserTypeBasedOnAnswers = (
  questionnaire: SoilCultivationPracticeQuestionnaireData,
) => {
  if (questionnaire.fertilizersSyntheticNitrogenInAnyOfTheLastFiveYears) {
    return I18n.t("js.carbon.synthetic");
  }

  if (questionnaire.fertilizersMixedNitrogenInAnyOfTheLastFiveYears) {
    return I18n.t("js.carbon.mixed");
  }

  if (questionnaire.fertilizersOrganicNitrogenConsistentlyTheLastFiveYears) {
    return I18n.t("js.carbon.organic");
  }

  return "-";
};

export const getIsCarbonFieldDefinitionUnlockable = ({
  activeCarbonContractId,
  fieldDefinitionSubmittedAt,
  validationStatus,
}: GetIsCarbonFieldDefinitionUnlockableArgs) => {
  const hasFieldActiveContract = Boolean(activeCarbonContractId);
  const isFieldDefinitionSubmitted = Boolean(fieldDefinitionSubmittedAt);
  const isFieldDefinitionValidated =
    validationStatus === FieldValidationStatus.Validated;

  return (
    isFieldDefinitionSubmitted &&
    !isFieldDefinitionValidated &&
    !hasFieldActiveContract
  );
};

type RedirectToFarmerFieldAppArgs = {
  ticket: string;
  fieldId: string;
  harvestYear: number | undefined;
  activeFieldDetailsTab: FieldStepTabId;
  isCQCEditMode?: boolean;
};

export const redirectToAppFarmerFieldDetailsPage = ({
  ticket,
  fieldId,
  harvestYear,
  activeFieldDetailsTab,
  isCQCEditMode,
}: RedirectToFarmerFieldAppArgs) => {
  const qsParams = getSearchParams({
    ...(isCQCEditMode ? { [CQC_EDIT_MODE_PARAM]: 1 } : {}), // TODO: Fix casing on both apps
    [ACTIVE_TAB_KEY]: activeFieldDetailsTab, // TODO: Fix casing on both apps
    harvest_year: harvestYear, // TODO: Fix casing on both apps
    [SIGN_IN_AS_PARAM]: ticket,
  });

  const appFarmerFieldDetailsPage = `${env.REACT_APP_FARMER_URL}/carbon/farmer/field/${fieldId}${qsParams}`;

  window.open(appFarmerFieldDetailsPage, "_blank");
};
