import { z } from "zod";

export enum ContractStatus {
  Signed = "signed",
  Sent = "sent",
  Withdrawn = "withdrawn",
  Terminated = "terminated",
}

// TODO: extract this one into shared type for all admin usages
export enum DocusignStatus {
  Sent = "sent",
  Delivered = "delivered",
  Completed = "completed",
  Declined = "declined",
  Voided = "voided",
}

export enum PaymentStatus {
  Paid = "paid",
  InProcess = "in-process",
  Withdrawn = "withdrawn",
  NoPayment = "no-payment",
}

export enum TerminateReasons {
  OTHER = "other",
}

export const BuyoutAgreementSchema = z.object({
  id: z.string(),
  userId: z.string(),
  userName: z.string(),
  companyName: z.string(),
  status: z.nativeEnum(ContractStatus).nullable(),
  statusReason: z.string().nullable(),
  year: z.number(),
  carbonCountry: z.string(),
  paymentStatus: z.nativeEnum(PaymentStatus),
  estimatedGhgCertificates: z.number(),
  pricePerCertificateCents: z.number(),
  totalPriceCents: z.number(),
  docusignEnvelope: z
    .object({
      deliveredAt: z.string().nullable(),
      docusignEnvelopeId: z.string().nullable(),
      docusignStatus: z.nativeEnum(DocusignStatus).nullable(),
      expiresAt: z.string().nullable(),
      id: z.string(),
      sentAt: z.string().nullable(),
      signedAt: z.string().nullable(),
    })
    .nullable(),
  transferAgreementUrl: z.string().nullable().optional(),
  transferAppendixUrl: z.string().nullable().optional(),
});

export type BuyoutAgreement = z.infer<typeof BuyoutAgreementSchema>;
