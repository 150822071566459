import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { ActionButton } from "@ag/components/buttons";
import { FormTextarea } from "@ag/components/form";
import { SCol, SRow } from "@ag/components/styled";
import I18n from "@ag/i18n";

import { SFormSubmitButtonContainer } from "~forms/styled";

import {
  AdditionalInformationAttribute,
  AdditionalInformationData,
} from "./types";

type Props = {
  isReadonly?: boolean;
  isSubmitButtonVisible?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<AdditionalInformationData>) => void;
};

export const AdditionalInformationForm = ({
  isSubmitButtonVisible = true,
  isReadonly = false,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<AdditionalInformationData>();
  const { pristine } = useFormState<AdditionalInformationData>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  return (
    <>
      <SRow>
        <SCol>
          <Field
            label={I18n.t("js.carbon.other_feedback")}
            testTag="additionalInformation-commentInput"
            name={AdditionalInformationAttribute.Comment}
            component={FormTextarea}
            disabled={isReadonly}
            parse={value => value ?? null}
          />
        </SCol>
      </SRow>

      {isSubmitButtonVisible && !isReadonly && (
        <SFormSubmitButtonContainer>
          <ActionButton
            HTMLType="submit"
            testTag="additionalInformation-submitButton"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}
    </>
  );
};
