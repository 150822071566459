import { DrawingManager, OverlayView } from "@react-google-maps/api";
import { forEach } from "lodash";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useKey } from "rooks";

import { Polygon } from "@ag/map/components";
import { getGeojsonGeometryGoogleMapsLatLngCoordinates } from "@ag/map/helpers";
import { GeoJSONGeometry } from "@ag/map/types";

import { useMultiPolygonPathsHistory } from "~hooks/use-multi-polygon-paths-history";

import ActionTipsBanner from "../action-tips-banner";
import * as styles from "../boundaries-drawing-manager.css";
import { DRAW_MANAGER_OPTIONS, POLYGON_OPTIONS } from "../constants";
import { getActiveVertexPosition } from "../helpers";
import MultiPolygonMapActionsTool from "./multi-polygon-map-actions-tool";
import { useMultiPolygonBoundariesStore } from "./multiPolygonStore";
import { useMultiPolygonDrawingManagerHandlers } from "./useMultiPolygonDrawingManagerHandlers";
import { useMultiPolygonHandlers } from "./useMultiPolygonHandlers";

type Props = {
  boundaries: GeoJSONGeometry | undefined;
  polygonOptions?: google.maps.PolygonOptions;
  onError: (error: string) => void;
};

export const BoundariesDrawingMultiPolygon = ({
  boundaries,
  polygonOptions,
  onError,
}: Props) => {
  const {
    drawingManagerInstance,
    activeVertex,
    outerPath,
    innerPaths,
    setEditAction,
    setOuterPath,
    setInnerPaths,
  } = useMultiPolygonBoundariesStore();

  useEffect(() => {
    const newCoordinates = getGeojsonGeometryGoogleMapsLatLngCoordinates(
      boundaries,
    ) as google.maps.LatLng[][][];

    if (!newCoordinates) return;

    const multiPolygonOuterPath: google.maps.LatLng[][] = [];
    const multiPolygonInnerPaths: google.maps.LatLng[][][] = [];

    forEach(newCoordinates, coordinate => {
      const [outerPath, ...innerPaths] = coordinate;

      multiPolygonOuterPath.push(outerPath);
      multiPolygonInnerPaths.push(innerPaths);
    });

    setOuterPath(multiPolygonOuterPath);
    setInnerPaths(multiPolygonInnerPaths);
  }, [boundaries, setInnerPaths, setOuterPath]);

  const history = useMultiPolygonPathsHistory();

  // Set the edit mode on escape press
  useKey(["Escape"], () => {
    forEach(drawingManagerInstance, instance => instance?.setDrawingMode(null));
    setEditAction("edit");
  });

  const {
    handlePathUpdate: handlePolygonPathUpdate,
    handleMouseUp: handlePolygonMouseUp,
    handleLoad: handlePolygonLoad,
    handleUnmount: handlePolygonUnmount,
  } = useMultiPolygonHandlers({
    history,
    onError,
  });

  const {
    handleLoad: handleDrawingManagerLoad,
    handlePolygonComplete: handleDrawingManagerPolygonComplete,
  } = useMultiPolygonDrawingManagerHandlers({
    history,
    onError,
  });

  if (isEmpty(outerPath)) return null;

  return (
    <>
      <ActionTipsBanner />

      {Array.from(Array(outerPath.length)).map((_, index) => {
        return (
          <div key={index}>
            <Polygon
              options={{ ...POLYGON_OPTIONS, ...polygonOptions }}
              paths={[outerPath[index], ...(innerPaths[index] || [])]}
              onLoad={polygon => handlePolygonLoad(index, polygon)}
              onUnmount={() => handlePolygonUnmount()}
              onMouseUp={event => handlePolygonMouseUp(index, event)}
            />

            <DrawingManager
              options={DRAW_MANAGER_OPTIONS}
              onLoad={drawingManager =>
                handleDrawingManagerLoad(index, drawingManager)
              }
              onPolygonComplete={(polygon: google.maps.Polygon) =>
                handleDrawingManagerPolygonComplete(index, polygon)
              }
            />

            {activeVertex && activeVertex.polygonId === index && (
              <OverlayView
                position={getActiveVertexPosition(
                  activeVertex,
                  outerPath[activeVertex.polygonId],
                  innerPaths[activeVertex.polygonId],
                )}
                mapPaneName={OverlayView.MAP_PANE}
                getPixelPositionOffset={() => ({
                  x: -1 * (styles.size.activeVertex / 2),
                  y: -1 * (styles.size.activeVertex / 2),
                })}
              >
                <div className={styles.activeVertex} />
              </OverlayView>
            )}
          </div>
        );
      })}

      <MultiPolygonMapActionsTool
        history={history}
        onVertexDelete={handlePolygonPathUpdate}
      />
    </>
  );
};
