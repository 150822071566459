import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { StringParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { parseSorting } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import {
  FertilisersFilters,
  useFertilisersQuery,
  useFertilisersTable,
} from "~features/fertiliser";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const filtersConfig = {
  id: [StringParam, { isDebounced: true }],
  matchFertiliserName: [StringParam, { isDebounced: true }],
  harvestYear: StringParam,
  methodologyVersion: StringParam,
  matchCoolfarmName: [StringParam, { isDebounced: true }],
  coolfarmIdentifier: [StringParam, { isDebounced: true }],
} as const;

const Fertilisers = () => {
  const [pagination, updatePagination, resetPagination] = usePagination();

  const filters = useFilters(filtersConfig);

  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);

  const { data: fertilisersData, isLoading } = useFertilisersQuery({
    ...pagination,
    filters: filters.values,
    sort: parseSorting(sorting),
  });

  const table = useFertilisersTable(fertilisersData?.data, {
    sorting,
    onSortingChange: setSorting,
  });

  const handlePaginationChanged = (value: {
    limit?: number;
    page?: number;
  }) => {
    updatePagination(value);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Fertilisers</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <FertilisersFilters
              {...filters}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
              }}
              onClear={() => {
                filters.onClear();
                resetPagination();
              }}
            />
          </ListLayout.Filters>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fertilisersData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChanged}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Fertilisers;
