import { AxiosRequestConfig, AxiosResponse } from "axios";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import log from "loglevel";
import { ZodSchema } from "zod";

import { RequestCacheManagerInstance } from "@ag/utils/storage";

/**
 * WARNING
 * Function mutate inputs
 * */
export const handleNoContentStatusCode = (response: AxiosResponse): void => {
  const { params, paramsSerializer, url = "" } = response.config;

  // Checksums are added on the fly to params so we have to remove them before we serialize params
  if (params) {
    delete params["cacheChecksum"];
  }

  const requestUrl =
    params && paramsSerializer ? `${url}?${paramsSerializer(params)}` : url;
  const cachedResponse = RequestCacheManagerInstance.readResponse(requestUrl);

  if (cachedResponse) {
    response.data = {
      result: cachedResponse,
    };

    log.info("Data loaded from cache", {
      URL: requestUrl,
      DATA: response.data,
    });
  }
};

export const handleSuccessStatusCode = ({
  data,
  config,
}: AxiosResponse): void => {
  const { params, paramsSerializer, url = "" } = config;

  const requestUrl =
    params && paramsSerializer ? `${url}?${paramsSerializer(params)}` : url;

  const checksum = data.result.meta?.cacheChecksum;
  if (checksum) {
    RequestCacheManagerInstance.saveChecksum(requestUrl, checksum);
  }

  const response = data.result;
  if (response) {
    RequestCacheManagerInstance.saveResponse(requestUrl, response);
  }
};

export const validateResponseSchema = (
  response: AxiosResponse,
  schema: ZodSchema,
  apiSource:
    | "ruby"
    | "node-auth"
    | "node-user-mgt"
    | "node-carbon"
    | "node-markets",
): void => {
  const statusCodes = {
    success: 200,
    noContent: 204,
  };

  const URLWithoutBase = response.config.url?.replace(
    response.config.baseURL || "",
    "",
  );

  function handleSuccessStatusCode() {
    try {
      const data = {
        ruby: response.data.result?.data,
        "node-auth": response.data.success?.data,
        "node-user-mgt": response.data.success?.data,
        "node-carbon": response.data.success?.data,
        "node-markets": response.data.success?.data,
      };

      schema.safeParseAsync(data[apiSource]).then(result => {
        if (result.success === false) {
          log.warn(
            `[APISchemaValidator] ${URLWithoutBase} doesn't match schema`,
            result.error.issues,
            data[apiSource],
          );
        }
      });
    } catch (error) {
      log.error(
        "[APISchemaValidator] Problem with validating, data no provided",
        error,
      );
    }
  }

  function handleNoDataToValidate() {
    log.warn("[APISchemaValidator] Response has no content to validate");
  }

  const actions = {
    [statusCodes.noContent]: handleNoDataToValidate,
    [statusCodes.success]: handleSuccessStatusCode,
  };

  const handler = actions[response.status];

  if (handler) {
    handler();
  }
};

export const handleAppReferer = (config: AxiosRequestConfig) => {
  const { url, paramsSerializer } = config;

  // TODO: Adjust when implementing sign in as
  const RESTRICTED_REFERER_PARAMS = ["user-auth", "admin-auth"];

  // If the request is made to external API `config.url` contains full url
  const isExternalRequest = url?.includes("http");

  /**
   * Internal referer header solution
   * - add header if request made to internal API
   * - filter query params based on restricted referrer array
   */
  if (!isExternalRequest && paramsSerializer) {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsObject = Object.fromEntries(searchParams);
    const searchParamsWithoutRestrictedParams = omit(
      searchParamsObject,
      RESTRICTED_REFERER_PARAMS,
    );

    const browserUrl = window.location.origin + window.location.pathname;

    const browserUrlWithoutRestrictedParams = isEmpty(
      searchParamsWithoutRestrictedParams,
    )
      ? browserUrl
      : `${browserUrl}?${paramsSerializer(
          searchParamsWithoutRestrictedParams,
        )}`;

    config.headers["App-Referer"] = browserUrlWithoutRestrictedParams;
  }

  return config;
};
