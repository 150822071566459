import { ChipVariant } from "@ag/design-system/atoms";
import { ChipCellValue } from "@ag/design-system/organisms";

import {
  ContractStatus,
  DocusignStatus,
  PaymentStatus,
} from "../entities/buyout-agreement";

export function getDocusignStatusChipVariant(status: DocusignStatus) {
  const lookup: Record<DocusignStatus, ChipVariant> = {
    [DocusignStatus.Sent]: "info",
    [DocusignStatus.Delivered]: "info",
    [DocusignStatus.Completed]: "success",
    [DocusignStatus.Declined]: "warning",
    [DocusignStatus.Voided]: "danger",
  };

  return lookup[status];
}

export function getDocusignStatusText(docusignStatus: DocusignStatus) {
  const lookup: Record<DocusignStatus, string> = {
    [DocusignStatus.Sent]: "Sent",
    [DocusignStatus.Completed]: "Completed",
    [DocusignStatus.Declined]: "Declined",
    [DocusignStatus.Delivered]: "Delivered",
    [DocusignStatus.Voided]: "Voided",
  };

  return lookup[docusignStatus] || docusignStatus;
}

export const getPaymentStatusChip = (status: PaymentStatus): ChipCellValue => ({
  variant: {
    [PaymentStatus.Paid]: "success",
    [PaymentStatus.InProcess]: "info",
    [PaymentStatus.NoPayment]: "neutral",
    [PaymentStatus.Withdrawn]: "danger",
  }[status] as ChipVariant,
  label: {
    [PaymentStatus.Paid]: "Paid",
    [PaymentStatus.InProcess]: "In Process",
    [PaymentStatus.NoPayment]: "No Payment",
    [PaymentStatus.Withdrawn]: "Withdrawn",
  }[status],
});

export function getContractStatusChipVariant(status: ContractStatus) {
  const lookup: Record<ContractStatus, ChipVariant> = {
    [ContractStatus.Sent]: "info",
    [ContractStatus.Signed]: "success",
    [ContractStatus.Withdrawn]: "danger",
    [ContractStatus.Terminated]: "neutral",
  };

  return lookup[status];
}

export function getContractStatusChipText(status: ContractStatus) {
  const lookup: Record<ContractStatus, string> = {
    [ContractStatus.Sent]: "Sent",
    [ContractStatus.Signed]: "Signed",
    [ContractStatus.Withdrawn]: "Withdrawn",
    [ContractStatus.Terminated]: "Terminated",
  };

  return lookup[status];
}

export const getContractStatusChip = (
  status: ContractStatus,
): { variant: ChipVariant; label: string } => ({
  variant: getContractStatusChipVariant(status),
  label: getContractStatusChipText(status),
});
