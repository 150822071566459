import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { queryClient } from "~config/react-query";

import { generateUsersQueryKey } from "./get-users";

const deleteUser = (userId: string) => {
  return axios.delete(`/api/v1/admin/users/${userId}`);
};

export const useDeleteUserMutation = () =>
  useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateUsersQueryKey());
    },
  });
