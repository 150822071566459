import { useSessionContext } from "~features/auth";
import { AuthorizedSidebar } from "~features/navigation";

import Content from "./Content";
import Navbar, { NavbarBackButton, NavbarButton } from "./Navbar";
import * as styles from "./layout.css";
import { SLayout } from "./styled";

type NavbarProps = React.PropsWithChildrenRequired<{
  navbarTitle?: string;
  navbarButton?: NavbarButton;
  navbarBackButton?: NavbarBackButton;
}>;

type ContentProps = {
  padding?: boolean;
};

type Props = NavbarProps & ContentProps;

const Layout = ({
  children,
  navbarTitle,
  navbarButton,
  navbarBackButton,
  padding,
}: Props) => {
  const { isSignedIn } = useSessionContext();

  return (
    <SLayout isSidebarVisible={isSignedIn}>
      <Navbar
        title={navbarTitle}
        button={navbarButton}
        backButton={navbarBackButton}
      />

      {isSignedIn && <AuthorizedSidebar className={styles.sidebar} />}

      <Content padding={padding}>{children}</Content>
    </SLayout>
  );
};

export default Layout;
