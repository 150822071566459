import ActionAgreementButton, {
  OnActionAgreement,
} from "../action-agreement-button";

type Props = {
  isTerminating: boolean;
  onTerminate: OnActionAgreement;
};

const TerminateAgreementButton = ({ isTerminating, onTerminate }: Props) => (
  <ActionAgreementButton
    buttonActionText="Terminate agreement"
    textAreaLabel="Enter reason for terminate the agreement"
    buttonModalText="Add reason and terminate"
    isLoading={isTerminating}
    onActionAgreement={onTerminate}
    aria-label="Terminate agreement"
  />
);

export default TerminateAgreementButton;
