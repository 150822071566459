import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { ComboBox } from "@ag/design-system/molecules";
import { FormField } from "@ag/design-system/organisms";
import { useId } from "@ag/utils/hooks";

type Props = {
  children: React.ReactNode;
  emptyText: string;
  label: string;
  loadingText: string;
  value: string;
  testId?: string;

  autofocus?: boolean;
  error?: FieldError;
  menuTriggger?: "focus" | "input";
  optionsClassName?: string;
  placeholder?: string;
  tooltip?: string;

  isAsync?: boolean;
  isLoading?: boolean;

  onInput?: (value: string) => void;
  onChange: (value: string) => void;
};

// forwardRef is used to allow spreading the field prop (that includes ref) from the form context in the consumer
export const ComboBoxField = forwardRef<unknown, Props>(
  (
    {
      children,
      emptyText,
      label,
      value,
      loadingText,
      autofocus,
      menuTriggger,
      optionsClassName,
      tooltip,
      placeholder,
      testId,
      error,
      isAsync,
      isLoading,
      onInput,
      onChange,
    },
    _ref,
  ) => {
    const id = useId();

    return (
      <FormField.Root>
        {label && (
          <FormField.Label id={id} tooltip={tooltip}>
            {label}
          </FormField.Label>
        )}

        <ComboBox.Root
          value={value}
          emptyText={emptyText}
          loadingText={loadingText}
          ariaLabelledBy={id}
          autofocus={autofocus}
          menuTrigger={menuTriggger}
          placeholder={placeholder}
          optionsClassName={optionsClassName}
          isInvalid={Boolean(error)}
          isAsync={isAsync}
          isLoading={isLoading}
          onInput={onInput}
          onChange={onChange}
          testId={testId}
        >
          {children}
        </ComboBox.Root>

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

ComboBoxField.displayName = "ComboBoxField";
