import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import BackButton from "~components/BackButton";
import Table from "~components/table";
import { useBoundaryVerificationFarmsTable } from "~features/boundary-verification";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const boundaryVerificationFarms = {
  data: [
    {
      userId: "1",
      companyName: "Stanley inc.",
      farmId: "1",
      farmName: "Jesseses cool farm",
      fieldCount: "250",
      lastUpdated: "2021-10-01T00:00:00Z",
      levelOneFlagCount: "2",
      levelTwoFlagCount: "3",
    },
    {
      userId: "2",
      companyName: "Pickles inc.",
      farmId: "2",
      farmName: "Julie's cool farm",
      fieldCount: "1520",
      lastUpdated: "2021-10-01T00:00:00Z",
      levelOneFlagCount: "2",
      levelTwoFlagCount: "0",
    },
  ],
  meta: {
    itemCount: 1,
    totalItems: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
  },
};

const BoundaryVerification = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      farmId: "",
    },
  });
  const navigate = useNavigate();

  const [pagination, updatePagination] = usePagination({
    defaultLimit: 10,
  });

  const table = useBoundaryVerificationFarmsTable(
    boundaryVerificationFarms.data,
  );

  const onSubmit = (data: { farmId: string }) => {
    navigate("/carbon/boundary-verification/map", {
      state: { farmId: data.farmId },
    });
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle>Boundary verification</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <form onSubmit={handleSubmit(onSubmit)} className="flex gap-2">
            <Controller
              name="farmId"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  placeholder="Search for a FarmId"
                  leftAddon={<Icon name="search" />}
                />
              )}
            />
            <Button type="submit">Map view</Button>
          </form>
        </ListLayout.Header>
        <Table
          instance={table}
          meta={boundaryVerificationFarms?.meta}
          pagination={pagination}
          onPaginationChange={(...args) => {
            updatePagination(...args);
          }}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default BoundaryVerification;
