
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/components/boundaries-drawing-manager/boundaries-drawing-multi-polygon/multi-polygon-map-actions-tool/multi-polygon-map-actions-tool.css.ts", "@ag/carbon");
      import { style } from "@vanilla-extract/css";
import { colors, typography } from "@ag/design-system/tokens";
export const root = style([typography.h5, {
  position: "absolute",
  bottom: 20,
  left: "50%",
  transform: "translate(-50%, 0)",
  display: "flex",
  alignItems: "center",
  gap: 8
}], "root");
export const button = style({
  background: colors.white[100],
  color: colors.green[500]
}, "button");
      endFileScope();
      
    