import styled from "@emotion/styled";

import { ActionButton } from "@ag/components/buttons";
import { Button } from "@ag/design-system/atoms";
import { H4Typography, style } from "@ag/ui";

export const SNavbar = styled.header`
  grid-area: navbar;

  position: relative;
  z-index: 3; /* taken from old "global layers -> navbar" concept */

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${style.color.white[100]};
  border-bottom: 1px solid ${style.color.grey[100]};
`;

export const STitle = styled.h4`
  ${H4Typography};

  margin-bottom: 0;
`;

export const SButton = styled(ActionButton)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

export const SBackButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;
