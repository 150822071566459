
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/atoms/buttons/IconButton/icon-button.css.ts", "@ag/design-system");
      import { StyleRule, createVar, style } from "@vanilla-extract/css";
import { recipe } from "@vanilla-extract/recipes";
import { radius } from "~tokens";
import { primaryDangerDarkTheme, primaryDangerTheme, primaryDarkTheme, primaryTheme, secondaryDangerDarkTheme, secondaryDangerTheme, secondaryDarkTheme, secondaryTheme, themeVars } from "../button-themes.css";
import { ButtonSize, ButtonVariant } from "../types";
const paddingBlock = createVar("paddingBlock");
const paddingInline = createVar("paddingInline");
const spinnerHeight = createVar("spinnerHeight");
type RootVariants = {
  size: Record<ButtonSize, StyleRule>;
  variant: Record<ButtonVariant, StyleRule>;
  theme: {
    light: StyleRule;
    dark: StyleRule;
  };
  isDanger: {
    true: StyleRule;
  };
  isLoading: {
    true: StyleRule;
  };
};
export const root = recipe<RootVariants>({
  base: {
    display: "inline-grid",
    gridTemplateColumns: `${paddingInline} 1em ${paddingInline}`,
    gridTemplateRows: `${paddingBlock} auto ${paddingBlock}`,
    gridTemplateAreas: `
      ".  .   ."
      ". icon ."
      ".  .   ."
    `,
    placeItems: "center",
    padding: 0,
    color: themeVars.color,
    fontSize: "14px",
    background: themeVars.background,
    border: `1px solid ${themeVars.background}`,
    borderRadius: radius[100],
    selectors: {
      "&:hover": {
        color: themeVars.colorHover,
        background: themeVars.backgroundHover,
        cursor: "pointer"
      },
      "&:active, &:focus": {
        background: themeVars.backgroundActive,
        color: themeVars.colorActive
      },
      "&:disabled": {
        background: themeVars.backgroundDisabled,
        color: themeVars.colorDisabled,
        borderColor: themeVars.backgroundDisabled
      }
    }
  },
  variants: {
    size: {
      medium: {
        vars: {
          [paddingBlock]: "11px",
          [paddingInline]: "12px",
          [spinnerHeight]: "24px"
        }
      },
      small: {
        vars: {
          [paddingBlock]: "7px",
          [paddingInline]: "8px",
          [spinnerHeight]: "16px"
        }
      },
      "x-small": {
        vars: {
          [paddingBlock]: "3px",
          [paddingInline]: "4px",
          [spinnerHeight]: "16px"
        }
      }
    },
    // Note: themeVars settings are gathered in "compoundVariants" for better readability.
    variant: {
      primary: {},
      secondary: {
        borderColor: themeVars.color,
        selectors: {
          "&:disabled": {
            borderColor: themeVars.colorDisabled
          }
        }
      },
      text: {
        vars: {
          [paddingBlock]: "0px",
          [paddingInline]: "0px"
        },
        selectors: {
          "&, &:hover, &:active, &:focus, &:disabled": {
            background: "none",
            border: "none"
          }
        }
      }
    },
    isDanger: {
      true: {}
    },
    // Primary - isLoading
    isLoading: {
      true: {
        selectors: {
          "&, &:hover, &:active, &:focus, &:disabled": {
            color: themeVars.background,
            background: themeVars.background
          },
          "&:hover": {
            cursor: "wait"
          }
        }
      }
    },
    theme: {
      dark: {},
      light: {}
    }
  },
  compoundVariants: [
  // Primary
  {
    variants: {
      variant: "primary"
    },
    style: {
      vars: primaryTheme
    }
  }, {
    variants: {
      variant: "primary",
      isDanger: true
    },
    style: {
      vars: primaryDangerTheme
    }
  },
  // Primary -- dark theme
  {
    variants: {
      variant: "primary",
      theme: "dark"
    },
    style: {
      vars: primaryDarkTheme
    }
  }, {
    variants: {
      variant: "primary",
      theme: "dark",
      isDanger: true
    },
    style: {
      vars: primaryDangerDarkTheme
    }
  },
  // Secondary
  {
    variants: {
      variant: "secondary"
    },
    style: {
      vars: secondaryTheme
    }
  }, {
    variants: {
      variant: "secondary",
      isDanger: true
    },
    style: {
      vars: secondaryDangerTheme
    }
  },
  // Secondary -- dark theme
  {
    variants: {
      variant: "secondary",
      theme: "dark"
    },
    style: {
      vars: secondaryDarkTheme
    }
  }, {
    variants: {
      variant: "secondary",
      theme: "dark",
      isDanger: true
    },
    style: {
      vars: secondaryDangerDarkTheme
    }
  },
  // Text
  {
    variants: {
      variant: "text"
    },
    style: {
      vars: secondaryTheme
    }
  }, {
    variants: {
      variant: "text",
      isDanger: true
    },
    style: {
      vars: secondaryDangerTheme
    }
  },
  // Text -- dark theme
  {
    variants: {
      variant: "text",
      theme: "dark"
    },
    style: {
      vars: secondaryDarkTheme
    }
  }, {
    variants: {
      variant: "text",
      theme: "dark",
      isDanger: true
    },
    style: {
      vars: secondaryDangerDarkTheme
    }
  }]
}, "root");
export const spinner = style({
  gridColumn: "-1/1",
  gridRow: "-1/1",
  height: spinnerHeight,
  color: themeVars.color
}, "spinner");
export const icon = style({
  gridArea: "icon"
}, "icon");
      endFileScope();
      
    