import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { IconButton } from "@ag/design-system/atoms";
import { ButtonSelect, Select } from "@ag/design-system/molecules";
import { typography } from "@ag/design-system/tokens";
import { box, stack } from "@ag/design-system/utils";
import { ButtonSelectField, InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { FlagValue } from "~components/flag-select";
import { FlagSelectField } from "~components/flag-select-field";

import { AssuranceFlag } from "../entities/assurance";
import {
  CheckFilterKey,
  getFiltersConfig,
} from "../helpers/get-filters-config";
import { AssuranceFilterData } from "../types/filters";
import * as styles from "./fields-check-filters.css";

type Props = {
  form: UseFormReturn<AssuranceFilterData>;
  filtersValues: AssuranceFilterData;
  onClear: (name?: keyof AssuranceFilterData) => void;
};

export const FieldsCheckFilters = ({ form, filtersValues, onClear }: Props) => {
  const { control, register } = form;

  const [newFilter, setNewFilter] = useState<CheckFilterKey | null>(null);
  const [activeFilters, setActiveFilters] = useState<CheckFilterKey[]>([]);

  const filtersConfig = useMemo(getFiltersConfig, []);

  // Don't show filters that are already set when adding a new filter
  const availableFilters = useMemo(
    () =>
      (Object.keys(filtersConfig) as CheckFilterKey[]).filter(
        filterKey => !activeFilters.includes(filterKey),
      ),
    [activeFilters, filtersConfig],
  );

  // active filters are stored in ref, as we only want the initial values to be synced with internal state
  const activeFiltersKeys = useRef(
    Object.keys(filtersValues).filter(
      key => filtersValues[key as keyof AssuranceFilterData] !== undefined,
    ),
  );

  // Sync local active filters to show the correct inputs
  useEffect(() => {
    const newActiveFilters: CheckFilterKey[] = [];

    (Object.keys(filtersConfig) as CheckFilterKey[]).forEach(filterKey => {
      const isFilterSet = activeFiltersKeys.current.some(activeFilterKey =>
        activeFilterKey.includes(filterKey),
      );

      if (isFilterSet) {
        newActiveFilters.push(filterKey as CheckFilterKey);
      }
    });

    setActiveFilters(newActiveFilters);
  }, [filtersConfig]);

  const handleAddNewFilter = () => {
    if (!newFilter) return;

    setActiveFilters([...activeFilters, newFilter]);
    setNewFilter(null);
  };

  const handleRemoveFilter = (filterKey: CheckFilterKey) => {
    setActiveFilters(
      activeFilters.filter(activeFilter => activeFilter !== filterKey),
    );

    const filterConfig = filtersConfig[filterKey];

    // Reset filter values for the given filter type
    if (filterConfig.type === "range") {
      onClear(`${filterKey}.value.min` as keyof AssuranceFilterData);
      onClear(`${filterKey}.value.max` as keyof AssuranceFilterData);
    }

    if (filterConfig.type === "select" || filterConfig.type === "boolean") {
      onClear(`${filterKey}.value` as keyof AssuranceFilterData);
    }

    if (filterConfig.flag) {
      onClear(`${filterKey}.flag` as keyof AssuranceFilterData);
    }
  };

  return (
    <>
      <h3 className={typography.h4}>Checks</h3>

      <fieldset className={stack()}>
        {activeFilters.map(filterKey => {
          const filterConfig = filtersConfig[filterKey];

          return (
            <div key={filterKey} className={styles.filterGroup}>
              <div className={box({ align: "center", justify: "between" })}>
                <h5 className={typography.h5}>{filterConfig.label}</h5>

                <IconButton
                  icon="trash-can"
                  size="x-small"
                  isDanger
                  variant="secondary"
                  onClick={() => handleRemoveFilter(filterKey)}
                />
              </div>

              {filterConfig?.flag && (
                <Controller
                  name={`${filterKey}.flag` as keyof AssuranceFilterData}
                  control={control}
                  render={({ field, fieldState }) => (
                    <FlagSelectField
                      {...field}
                      {...fieldState}
                      value={(field.value as unknown as FlagValue[]) ?? ""}
                      label={I18n.t("js.admin.assurance.filters.flags")}
                      options={Object.values(AssuranceFlag)}
                      isDisabled={filterConfig.disabledFlags}
                    />
                  )}
                />
              )}

              {filterConfig?.type === "range" && (
                <div className={box()}>
                  <InputField
                    {...register(
                      `${filterKey}.value.min` as keyof AssuranceFilterData,
                    )}
                    step={0.01}
                    type="number"
                    label={I18n.t("js.shared.from")}
                  />

                  <InputField
                    {...register(
                      `${filterKey}.value.max` as keyof AssuranceFilterData,
                    )}
                    step={0.01}
                    type="number"
                    label={I18n.t("js.shared.to")}
                  />
                </div>
              )}

              {filterConfig?.type === "select" && (
                <Controller
                  name={`${filterKey}.value` as keyof AssuranceFilterData}
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectField
                      {...field}
                      {...fieldState}
                      value={(field.value as string) ?? ""}
                      label={I18n.t("js.shared.value")}
                    >
                      <Select.Option key="EMPTY_OPTION" value="">
                        {I18n.t("js.shared.all")}
                      </Select.Option>

                      {filterConfig.options.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </SelectField>
                  )}
                />
              )}

              {filterConfig?.type === "boolean" && (
                <Controller
                  name={`${filterKey}.value` as keyof AssuranceFilterData}
                  control={control}
                  render={({ field }) => (
                    <ButtonSelectField
                      label={I18n.t("js.shared.value")}
                      value={field.value}
                      onChange={field.onChange}
                    >
                      <ButtonSelect.Option value={true}>
                        {I18n.t("js.shared.yes")}
                      </ButtonSelect.Option>
                      <ButtonSelect.Option value={false}>
                        {I18n.t("js.shared.no")}
                      </ButtonSelect.Option>
                    </ButtonSelectField>
                  )}
                />
              )}
            </div>
          );
        })}

        {availableFilters.length > 0 && (
          <div className={box({ justify: "between", align: "end" })}>
            <div style={{ flex: 1 }}>
              <SelectField
                label={I18n.t("js.shared.column")}
                value={newFilter ?? ""}
                onChange={key => setNewFilter(key as CheckFilterKey)}
              >
                {availableFilters.map(filterKey => (
                  <Select.Option key={filterKey} value={filterKey}>
                    {filtersConfig[filterKey].label}
                  </Select.Option>
                ))}
              </SelectField>
            </div>

            <IconButton
              icon="plus"
              onClick={handleAddNewFilter}
              disabled={!newFilter}
            />
          </div>
        )}
      </fieldset>
    </>
  );
};
