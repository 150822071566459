import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Label } from "@ag/design-system/atoms";
import { typography } from "@ag/design-system/tokens";
import { stack } from "@ag/design-system/utils";

import {
  useSubmitCertificatesMutation,
  useUpdateRequestQuery,
  useUpdateRequestStore,
  useUpdateRequestSummaryQuery,
} from "~features/certificate";

import * as pageStyles from "../styles.css";
import * as styles from "./submit.css";

type Props = {
  onGoPrevStep: () => void;
  unblockRoute: () => void;
};

const SubmitStep = ({ onGoPrevStep, unblockRoute }: Props) => {
  const navigate = useRef(useNavigate());

  const {
    beneficiary,
    setBeneficiary,
    setValidationResult,
    setSuccessModalAction,
  } = useUpdateRequestStore();

  const { requestId } = useParams<{ requestId: string }>();

  const { data: updateRequest } = useUpdateRequestQuery(requestId);
  const { data: summary } = useUpdateRequestSummaryQuery(requestId);
  const submitCertificatesMutation = useSubmitCertificatesMutation();

  const handleSubmit = () => {
    if (!requestId || !updateRequest) throw new Error("Request Id is required");
    if (!beneficiary) throw new Error("Beneficiary is required");

    unblockRoute();

    submitCertificatesMutation.mutate(
      {
        updateRequestId: requestId,
        certificateBeneficiaryId: beneficiary.id,
      },
      {
        onSuccess: () => {
          setBeneficiary(null);
          setValidationResult(null);
          setSuccessModalAction(updateRequest.action);
          navigate.current("/ledger");
        },
      },
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={stack({ gap: 24 })}>
          <h2 className={pageStyles.title}>Review before submitting</h2>
          <p className={typography.p2} style={{ textAlign: "center" }}>
            Are you sure you want to perform this action?
          </p>
        </div>

        <div className={styles.card}>
          <div>
            <Label>Total certificates</Label>
            <h2 className={typography.h2}>{summary?.quantity ?? 0}</h2>
          </div>

          <div style={{ textAlign: "center" }}>
            <Label>
              {updateRequest?.action === "transfer" ? "Transferred" : "Retired"}{" "}
              from:
            </Label>
          </div>

          <div>
            <div className={styles.senderRecipient}>
              <div className={stack({ gap: 4 })}>
                <div className={styles.userBox()}>Agreena</div>
              </div>

              <span>to</span>

              <div className={stack({ gap: 4 })}>
                <div className={styles.userBox({ isActive: true })}>
                  {beneficiary?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className={pageStyles.footer}>
        <div className={pageStyles.prevButtonArea}>
          <Button
            icon="chevron-left"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className={pageStyles.nextButtonArea}>
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </footer>
    </>
  );
};

export default SubmitStep;
