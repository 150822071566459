import { z } from "zod";

import { ContractStatus } from "~features/sales-offers";

export const ResaleAgreementFiltersSchema = z
  .object({
    userId: z.string(),
    signerName: z.string(),
    status: z.nativeEnum(ContractStatus).or(z.literal("")),
    year: z.string(),
    countryCode: z.string(),
  })
  .partial();

export type ResaleAgreementFilters = z.infer<
  typeof ResaleAgreementFiltersSchema
>;
