import {
  OnChangeFn,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo } from "react";

import {
  BooleanCell,
  BooleanCellValue,
  LinkCell,
  LinkCellValue,
  TextCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useTable } from "~components/table";

import { CropType } from "../entities/crop-type";

type TableData = {
  id: LinkCellValue;
  name: TextCellValue;
  harvestYear: TextCellValue;
  methodologyVersion: TextCellValue;
  coolfarmIdentifier: TextCellValue;
  coolfarmName: TextCellValue;
  showResidue: BooleanCellValue;

  cropType: CropType;
};

type UseCropTypesTableState = {
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
};

export const useCropTypesTable = (
  data: CropType[] | undefined,
  state: UseCropTypesTableState,
) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => String(original.cropType?.id),
    state: {
      sorting: state.sorting,
    },
    onSortingChange: state.setSorting,
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: I18n.t("js.carbon.crop_type_attribute.id"),
      cell: LinkCell,
      enableSorting: true,
      enableMultiSort: false,
    }),
    columnHelper.accessor("name", {
      header: I18n.t("js.carbon.crop_type_attribute.name"),
      enableSorting: true,
      enableMultiSort: false,
    }),
    columnHelper.accessor("harvestYear", {
      header: I18n.t("js.carbon.crop_type_attribute.harvest_year"),
    }),
    columnHelper.accessor("methodologyVersion", {
      header: I18n.t("js.shared.methodology_version"),
    }),
    columnHelper.accessor("coolfarmIdentifier", {
      header: I18n.t("js.carbon.crop_type_attribute.coolfarm_identifier"),
    }),
    columnHelper.accessor("coolfarmName", {
      header: I18n.t("js.carbon.crop_type_attribute.coolfarm_name"),
    }),
    columnHelper.accessor("showResidue", {
      header: I18n.t("js.carbon.crop_type_attribute.show_residue"),
      cell: BooleanCell,
    }),
  ];
}

function getRowData(cropTypes: CropType[] | undefined): TableData[] {
  if (!cropTypes) return [];

  return cropTypes.map(cropType => ({
    id: {
      url: `/carbon/crop-types/${cropType.id}/edit`,
      title: cropType.id,
    },
    name: cropType.name,
    harvestYear: cropType.harvestYear,
    methodologyVersion: cropType.methodologyVersion,
    coolfarmIdentifier: cropType.coolfarmIdentifier,
    coolfarmName: cropType.coolfarmName,
    showResidue: cropType.showResidue,

    cropType,
  }));
}
