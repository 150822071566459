import * as Collapsible from "@radix-ui/react-collapsible";
import { createContext, useContext, useState } from "react";

import { Icon } from "@ag/design-system/assets";
import { createChildValidator } from "@ag/utils/helpers";

type CollapseBoxContentData = {
  isOpen: boolean;
};

const CollapseBoxContext = createContext<CollapseBoxContentData>({
  isOpen: false,
});

/* -------------------------------------------------------------------------------------------------
 * CollapseBox
 * -----------------------------------------------------------------------------------------------*/
const collapseBoxChildValidator = createChildValidator("CollapseBox", {
  Title: CollapseBoxTitle,
  Content: CollapseBoxContent,
});

type CollapseBoxProps = {
  children: React.ReactElement<
    typeof CollapseBoxTitle | typeof CollapseBoxContent
  >[];
  isInitiallyOpen?: boolean;
};

function CollapseBox({ children, isInitiallyOpen }: CollapseBoxProps) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen ?? false);

  const errorMsg = collapseBoxChildValidator(children);
  if (errorMsg) throw Error(errorMsg);

  return (
    <CollapseBoxContext.Provider value={{ isOpen }}>
      <Collapsible.Root
        className="rounded bg-white-100 shadow-100"
        open={isOpen}
        onOpenChange={setIsOpen}
      >
        {children}
      </Collapsible.Root>
    </CollapseBoxContext.Provider>
  );
}

/* -------------------------------------------------------------------------------------------------
 * CollapseBoxTitle
 * -----------------------------------------------------------------------------------------------*/
function CollapseBoxTitle({ children }: React.PropsWithChildrenRequired) {
  const { isOpen } = useContext(CollapseBoxContext);

  return (
    <Collapsible.Trigger asChild>
      <h4 className="flex items-center justify-between p-4 hover:cursor-pointer">
        {children}

        <Icon
          name={isOpen ? "chevron-up" : "chevron-down"}
          className="text-xs text-green-500"
        />
      </h4>
    </Collapsible.Trigger>
  );
}

/* -------------------------------------------------------------------------------------------------
 * CollapseBoxContent
 * ----------------------------------------------------------------------------------------------*/
function CollapseBoxContent({ children }: React.PropsWithChildrenRequired) {
  return (
    <Collapsible.Content>
      <div className="px-4 pb-4">{children}</div>
    </Collapsible.Content>
  );
}

const Root = CollapseBox;
const Title = CollapseBoxTitle;
const Content = CollapseBoxContent;

export default { Root, Title, Content };
