import {
  OnChangeFn,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";

import {
  ButtonCell,
  ButtonCellValue,
  DateCell,
  DateCellValue,
  LinkCell,
  LinkCellValue,
  TextCellValue,
} from "@ag/design-system/organisms";

import { RawDataCell } from "~components/RawDataCell";
import { useTable } from "~components/table";

import { BoundaryVerificationFarm } from "../entities/boundary-verification-farm";

type TableData = {
  userId: LinkCellValue;
  companyName: TextCellValue;
  farmId: TextCellValue;
  farmName: TextCellValue;
  fieldCount: TextCellValue;
  lastUpdated: DateCellValue;

  levelOneFlagCount: string;
  levelTwoFlagCount: string;

  actions: ButtonCellValue;

  boundaryVerificationFarm: BoundaryVerificationFarm;
};

type UseBoundaryVerificationFarmsTableState = {
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
};

export const useBoundaryVerificationFarmsTable = (
  data: BoundaryVerificationFarm[] | undefined,
  state?: UseBoundaryVerificationFarmsTableState,
) => {
  return useTable<TableData>({
    columns: getColumns(),
    data: getRowData(data),
    getRowId: original => String(original.boundaryVerificationFarm.farmId),
    state: {
      sorting: state?.sorting,
    },
    onSortingChange: state?.setSorting,
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("userId", {
      header: "User ID",
      cell: LinkCell,
    }),
    columnHelper.accessor("companyName", {
      header: "Company",
    }),
    columnHelper.accessor("farmId", {
      header: "Farm ID",
    }),
    columnHelper.accessor("farmName", {
      header: "Farm Name",
    }),
    columnHelper.accessor("fieldCount", {
      header: "No. of Fields",
    }),
    columnHelper.accessor("lastUpdated", {
      header: "Last Updated",
      cell: DateCell,
    }),
    columnHelper.accessor("levelOneFlagCount", {
      header: "L1 Flags",
      cell: RawDataCell,
      meta: {
        cellBackground: value => {
          if (Number(value) > 0) {
            return "red";
          } else {
            return;
          }
        },
      },
    }),
    columnHelper.accessor("levelTwoFlagCount", {
      header: "L2 Flags",
      cell: RawDataCell,
      meta: {
        cellBackground: value => {
          if (Number(value) > 0) {
            return "yellow";
          } else {
            return;
          }
        },
      },
    }),
    columnHelper.accessor("actions", {
      header: "",
      cell: ButtonCell,
      size: 110,
    }),
  ];
}

function getRowData(
  boundaryVerificationFarms: BoundaryVerificationFarm[] | undefined,
): TableData[] {
  if (!boundaryVerificationFarms) return [];

  return boundaryVerificationFarms.map(boundaryVerificationFarm => {
    return {
      userId: {
        url: `/users/${boundaryVerificationFarm.userId}`,
        title: boundaryVerificationFarm.userId,
      },
      companyName: boundaryVerificationFarm.companyName,
      farmId: boundaryVerificationFarm.farmId,
      farmName: boundaryVerificationFarm.farmName,
      fieldCount: boundaryVerificationFarm.fieldCount,
      lastUpdated: boundaryVerificationFarm.lastUpdated,

      levelOneFlagCount: boundaryVerificationFarm.levelOneFlagCount,
      levelTwoFlagCount: boundaryVerificationFarm.levelTwoFlagCount,

      actions: {
        to: "/carbon/boundary-verification/map",
        variant: "primary",
        children: "View map",
      },
      boundaryVerificationFarm,
    };
  });
}
