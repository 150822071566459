import { Controller, FieldValues } from "react-hook-form";

import { Filters } from "@ag/components/Filters";
import { ComboBox, Select } from "@ag/design-system/molecules";
import { ComboBoxField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { FieldValidationStatus } from "~features/field";
import { useUsersSearch } from "~features/user/hooks/use-users-search";
import { getTimeRangeOptions } from "~helpers/date";
import { getValidationStatusLabel } from "~pages/_legacy/carbon/helpers";

import { getValidationRequestStatusLabel } from "../helpers/lookups";
import { ValidationRequestStatus } from "../types";

type Props<TValues extends FieldValues> = {
  values: TValues;
  onChange: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: () => void;
};
export const ValidationRequestsFilters = <TValues extends FieldValues>({
  values,
  onChange,
  onClear,
}: Props<TValues>) => {
  const { data: users, isLoading, onSearch } = useUsersSearch();

  const fieldValidationStatusLabel = getValidationStatusLabel();

  const sentAtOptions = getTimeRangeOptions(["today", "past"]);

  return (
    <>
      <Filters.Root values={values} onChange={onChange}>
        <Filters.Item
          render={({ control }) => (
            <Controller
              control={control}
              name="userId"
              render={({ field, fieldState }) => (
                <ComboBoxField
                  {...field}
                  {...fieldState}
                  label="User"
                  value={field.value ?? ""}
                  emptyText="No users found"
                  loadingText="Loading users..."
                  isAsync
                  isLoading={isLoading}
                  onInput={onSearch}
                >
                  {users.map(user => (
                    <ComboBox.Item
                      key={user.id}
                      textValue={`${user.name} (ID: ${user.id})`}
                    >
                      {user.name} (ID: {user.id})
                    </ComboBox.Item>
                  ))}
                </ComboBoxField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              control={control}
              name="status"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.admin.validation_status")}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                  {Object.values(ValidationRequestStatus).map(status => (
                    <Select.Option key={status} value={status}>
                      {getValidationRequestStatusLabel(status)}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              control={control}
              name="carbonFieldValidationStatus"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.carbon.field_validation_status")}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                  {Object.values(FieldValidationStatus).map(status => (
                    <Select.Option key={status} value={status}>
                      {fieldValidationStatusLabel[status]}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Item
          render={({ control }) => (
            <Controller
              control={control}
              name="createdInRange"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.admin.nudging.sent_at")}
                >
                  <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

                  {sentAtOptions.map(range => (
                    <Select.Option key={range.value} value={range.value}>
                      {range.label}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}
        />

        <Filters.Reset onClear={() => onClear()} />
      </Filters.Root>
    </>
  );
};
