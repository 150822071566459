import { useMutation } from "@tanstack/react-query";

import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";
import { APIResponse } from "@ag/utils/types";

import { postRequest } from "~lib/axios";

import { UploadAttachmentResponse } from "./types";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
const uploadAttachments = async (files: File[]): Promise<string[]> => {
  const attachmentsResponses = await Promise.all<
    APIResponse<UploadAttachmentResponse>
  >(
    files.map(file => {
      const formData = new FormData();
      formData.append("file", file);

      return postRequest({
        path: "/api/v1/admin/attachments",
        headers: {
          "content-type": "multipart/form-data",
        },
        // TODO: Form data is not accepted as a body (in types, but it works) - need to find a solution for it
        // @ts-ignore
        body: formData,
      });
    }),
  );

  const attachments = attachmentsResponses.map(res => res.data.result.data.id);

  return attachments;
};

const uploadAttachment = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  const response: APIResponse<UploadAttachmentResponse> = await postRequest({
    path: "/api/v1/admin/attachments",
    headers: {
      "content-type": "multipart/form-data",
    },
    // TODO: Form data is not accepted as a body (in types, but it works) - need to find a solution for it
    // @ts-ignore
    body: formData,
  });

  return response.data.result.data.id;
};

/**
 * MUTATIONS HOOKS
 */
export const useUploadAttachmentsMutation = () =>
  useMutation(uploadAttachments, {
    onSuccess: () =>
      ToastNotification.success(I18n.t("js.admin.upload_attachments.success")),
  });

export const useUploadAttachmentMutation = () => useMutation(uploadAttachment);
