import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { StringParam, createEnumParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { parseSorting } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import { TimeRange } from "~constants/date";
import { FieldValidationStatus } from "~features/field";
import { AuthorizedSidebar } from "~features/navigation";
import {
  ValidationRequestStatus,
  ValidationRequestsFilters,
  useValidationRequestsQuery,
  useValidationRequestsTable,
} from "~features/validation-request";
import { getTimeRangeValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const ValidationRequestStatusParam = createEnumParam(
  Object.values(ValidationRequestStatus),
);
const FieldValidationStatusParam = createEnumParam(
  Object.values(FieldValidationStatus),
);
const CreatedInRangeParam = createEnumParam(Object.values(TimeRange));

const ValidationRequests = () => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [pagination, updatePagination] = usePagination();

  const filters = useFilters({
    userId: [StringParam, { isDebounced: true }],
    status: ValidationRequestStatusParam,
    carbonFieldValidationStatus: FieldValidationStatusParam,
    createdInRange: CreatedInRangeParam,
  });

  const { userId, status, carbonFieldValidationStatus, createdInRange } =
    filters.values;

  const { data: validationRequestsData, isLoading } =
    useValidationRequestsQuery({
      ...pagination,
      filters: {
        userId,
        status,
        carbonFieldValidationStatus,
        createdInRange: createdInRange
          ? getTimeRangeValue(createdInRange)
          : undefined,
      },
      sort: parseSorting(sorting),
    });

  const table = useValidationRequestsTable(validationRequestsData?.items, {
    sorting,
    setSorting,
  });

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Validation requests</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <ValidationRequestsFilters {...filters} />
          </ListLayout.Filters>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={validationRequestsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default ValidationRequests;
