import styled from "@emotion/styled";

import { SRow } from "@ag/components/styled";
import { style } from "@ag/ui";

export const SMap = styled.div`
  .field-map {
    width: 100%;
    height: 206px;

    border-radius: ${style.borderRadius}px;
  }
`;

export const SModalMap = styled.div`
  .field-map {
    width: 100%;
    height: 600px;

    border-radius: ${style.borderRadius}px;
  }
`;

export const SForm = styled.div`
  ${SRow} {
    margin-bottom: 16px;
  }
`;
