import React, {
  FormEvent,
  PropsWithChildren,
  useMemo,
  useRef,
  useState,
} from "react";

import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Drawer } from "@ag/design-system/organisms";

const MAX_SHOW_MORE_ITEMS = 3;

type BarProps<TValues extends object> = {
  values: TValues;
  renderItem?: (
    key: keyof TValues,
    value: TValues[keyof TValues],
  ) => React.ReactNode;
  onToggleOpen: () => void;
  onChange?: (name: keyof TValues, value: TValues[keyof TValues]) => void;
  onClear: (targetKey?: string | string[]) => void;
  onExportTableClick?: () => void;
};

type DrawerProps = {
  title?: string;
  isOpen: boolean;
  onSubmit: (e: FormEvent) => void;
  onClose: () => void;
  onClear: () => void;
};

const FiltersBar = <TValues extends object>({
  values,
  renderItem = (key, value) => `${key.toString()}: ${value}`,
  onClear,
  onToggleOpen,
  onExportTableClick,
}: BarProps<TValues>) => {
  const [isShowMoreActive, setIsShowMoreActive] = useState(false);

  const tags = useMemo(
    () =>
      Object.entries(values)
        .map(([key, value]) => ({
          key: key as keyof typeof values,
          value: value as (typeof values)[keyof typeof values],
        }))
        .filter(({ value }) => Boolean(value)),
    [values],
  );

  const visibleTags = useMemo(() => {
    if (isShowMoreActive) return tags;

    return tags.slice(0, MAX_SHOW_MORE_ITEMS);
  }, [tags, isShowMoreActive]);

  return (
    <div className="flex items-center gap-6">
      <div className="flex flex-wrap items-center gap-2">
        <Button
          icon="filter"
          variant="secondary"
          size="small"
          onClick={onToggleOpen}
          iconPosition="before"
          className="max-w-[80px]"
          data-testid="filters-button"
        >
          Filters
        </Button>

        {visibleTags.map(tag => (
          <div
            className="inline-flex flex-wrap items-center gap-1 rounded-sm bg-grey-300 px-2 py-1.5 text-h5 text-grey-800"
            key={tag.key.toString()}
          >
            {renderItem(tag.key, tag.value)}

            <button
              onClick={() => onClear(tag.key.toString())}
              className="-m-1.5 -mr-1 flex cursor-pointer border-0 bg-transparent px-1 py-1.5 text-inherit outline-none"
            >
              <Icon name="close" />
            </button>
          </div>
        ))}

        {tags.length > MAX_SHOW_MORE_ITEMS ? (
          <button
            className="inline-flex cursor-pointer items-center rounded-sm border-0 bg-grey-300 p-1.5 text-h5 text-grey-800"
            onClick={() => setIsShowMoreActive(value => !value)}
          >
            {isShowMoreActive
              ? "Show less"
              : `+${tags.length - MAX_SHOW_MORE_ITEMS}`}
          </button>
        ) : null}

        {tags.length ? (
          <Button variant="secondary" size="small" onClick={() => onClear()}>
            Clear filters
          </Button>
        ) : null}
      </div>

      {onExportTableClick && (
        <Button
          variant="text"
          icon="external-link"
          iconPosition="after"
          onClick={onExportTableClick}
        >
          Export table
        </Button>
      )}
    </div>
  );
};

const FiltersDrawer = ({
  children,
  title = "Filters",
  isOpen,
  onClose,
  onSubmit,
  onClear,
}: PropsWithChildren<DrawerProps>) => {
  const clearButtonRef = useRef<HTMLButtonElement>(null);

  const handleOnClear = () => {
    onClear();
    clearButtonRef.current?.blur();
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    onSubmit(e);
    onClose();
  };

  return (
    <Drawer.Root isOpen={isOpen} onClose={onClose}>
      <Drawer.Header>{title}</Drawer.Header>

      <form
        data-testid="filters-form"
        onSubmit={handleOnSubmit}
        className="flex h-full flex-col"
        noValidate
      >
        <Drawer.Content className="flex-grow overflow-auto">
          <section className="grid gap-4">{children}</section>
        </Drawer.Content>

        <Drawer.Footer>
          <Button
            ref={clearButtonRef}
            variant="secondary"
            onClick={handleOnClear}
            type="button"
          >
            Clear all
          </Button>
          <Button
            variant="primary"
            type="submit"
            data-testid="filters-apply-button"
          >
            Apply
          </Button>
        </Drawer.Footer>
      </form>
    </Drawer.Root>
  );
};

export const Filters = {
  Bar: FiltersBar,
  Drawer: FiltersDrawer,
};

export type { DrawerProps, BarProps };
