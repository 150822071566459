import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  StringParam,
  createEnumParam,
  objectToSearchString,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { transformInitialFilterValues } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import { Filters, useSearchParamForm } from "~components/filters";
import Table from "~components/table";
import { AuthorizedSidebar } from "~features/navigation";
import {
  UserManagementResourceClass,
  useUserManagementPermissions,
} from "~features/permission";
import {
  OfferStatus,
  SalesOffersFilters,
  SalesOffersFiltersSchema,
  useSalesOffersQuery,
  useSalesOffersTable,
} from "~features/sales-offers";
import {
  Contacted,
  KYCStatus,
} from "~features/sales-offers/entities/sales-offer";
import { transformEnumToLabels, transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const SalesOffersList = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);
  const [pagination, updatePagination, resetPagination] = usePagination();

  const { data: userManagementPermissions } = useUserManagementPermissions();

  const [query, setQuery] = useQueryParams({
    userId: withDefault(StringParam, undefined),
    contacted: withDefault(StringParam, undefined),
    status: withDefault(createEnumParam(Object.values(OfferStatus)), undefined),
    kycStatus: withDefault(
      createEnumParam(Object.values(KYCStatus)),
      undefined,
    ),
    harvestYear: withDefault(StringParam, undefined),
  });

  const {
    register,
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    values: transformInitialFilterValues<SalesOffersFilters>(query),
    resolver: zodResolver(SalesOffersFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<SalesOffersFilters>(query, setQuery, resetPagination);

  const { data, isLoading } = useSalesOffersQuery({
    filters: {
      ...query,
    },
    sort: parseSorting(sorting),
    ...pagination,
  });

  const canViewUser = userManagementPermissions?.read?.includes(
    UserManagementResourceClass.User,
  );

  const renderFilterBarItem = (
    key: keyof SalesOffersFilters,
    value: SalesOffersFilters[keyof SalesOffersFilters],
  ) => {
    const contactedValues = {
      true: "Yes",
      false: "No",
    };

    const label = (
      {
        userId: "User ID",
        contacted: "Contacted",
        kycStatus: "KYC status",
        harvestYear: "Harvest year",
        status: "Offer status",
      } as Record<keyof SalesOffersFilters, string>
    )[key];

    if (key === "contacted") {
      return `${label}: ${contactedValues[value as "true" | "false"]}`;
    }

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const contactedLabels = transformEnumToLabels(Contacted);
  const kycStatusLabels = transformEnumToLabels(KYCStatus);
  const offerStatusLabels = transformEnumToLabels(OfferStatus);

  const contactedValues = {
    [Contacted.YES]: "true",
    [Contacted.NO]: "false",
  };

  const table = useSalesOffersTable(
    data?.items,
    { sorting, setSorting },
    Boolean(canViewUser),
    objectToSearchString(query),
  );

  const handleFormFiltersClear = () => {
    resetForm();
    handleClearFiltersDrawer();
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Offers List</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField
            {...register("userId")}
            label="User ID"
            data-testid="userId-input"
          />

          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Offer Status"
                optionsClassName="z-modal"
                testId="status-input"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(OfferStatus).map(type => (
                  <Select.Option key={type} value={type}>
                    {offerStatusLabels[type]}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="kycStatus"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="KYC Status"
                optionsClassName="z-modal"
                testId="kycStatus-input"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(KYCStatus).map(type => (
                  <Select.Option key={type} value={type}>
                    {kycStatusLabels[type]}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="contacted"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Contacted"
                optionsClassName="z-modal"
                testId="contacted-input"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(Contacted).map(type => (
                  <Select.Option key={type} value={contactedValues[type]}>
                    {contactedLabels[type]}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <InputField
            {...register("harvestYear")}
            label="Harvest year"
            data-testid="harvestYear-input"
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default SalesOffersList;
