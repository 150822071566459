import styled from "@emotion/styled";

import { ActionButton } from "@ag/components/buttons";
import { SRow as SRowGlobal } from "@ag/components/styled";
import { style } from "@ag/ui";

export const SRow = styled(SRowGlobal)`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SToggleButton = styled(ActionButton)`
  margin: 0;
  color: ${style.color.green[500]};
`;
