import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { PageLinkButton } from "@ag/components/buttons";

import CenteredLayout from "~layouts/centered-layout";

import Image404 from "./assets/404.svg";

const Page404 = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    Sentry.captureMessage(`Route not found: ${pathname}`, "warning");
  }, [pathname]);

  return (
    <CenteredLayout.Root>
      <div className="flex flex-col items-center">
        <img src={Image404} />

        <h3 className="mt-4 text-h3">
          The page you are looking for does not exist
        </h3>

        <PageLinkButton to="/" className="mt-4">
          Go to homepage
        </PageLinkButton>
      </div>
    </CenteredLayout.Root>
  );
};

export default Page404;
