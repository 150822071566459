import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BasicStepper } from "@ag/design-system/organisms";
import { usePrompt } from "@ag/utils/hooks";

import BackButton from "~components/BackButton";
import {
  ProgressLostModal,
  useUpdateRequestQuery,
} from "~features/certificate";
import { AuthorizedSidebar } from "~features/navigation";
import { Page, withPageAccess } from "~features/permission";
import ListLayout from "~layouts/list-layout";

import * as styles from "../styles.css";
import ReviewStep from "./steps/review";
import SubmitStep from "./steps/submit";

const ApproveUpdateRequest = () => {
  const navigate = useNavigate();
  const [isShowProgressLostModal, setIsShowProgressLostModal] = useState(false);

  const { requestId } = useParams<{ requestId: string }>();
  const { data: updateRequest } = useUpdateRequestQuery(requestId);

  const { blockedRoute, unblockRoute } = usePrompt(
    true,
    setIsShowProgressLostModal,
  );

  const handleExitClick = () => {
    unblockRoute();
    const navigationPath = blockedRoute?.search ? "/ledger" : blockedRoute;
    if (blockedRoute && navigationPath) {
      navigate(navigationPath);
    } else {
      history.back();
    }
  };

  const handleCancel = () => {
    setIsShowProgressLostModal(false);
  };

  const title =
    updateRequest?.action === "transfer"
      ? "Transfer certificates"
      : "Retire certificates";

  return (
    <ListLayout.Root>
      <ProgressLostModal
        isOpen={isShowProgressLostModal}
        onClose={handleCancel}
        onExitClick={handleExitClick}
      />

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.TopBar>
        <BackButton onClick={() => navigate("/ledger")} />

        <ListLayout.TopBarTitle>{title}</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Content>
        <div className={styles.root}>
          <BasicStepper.Root>
            <BasicStepper.List>
              <BasicStepper.Trigger title="Review selection" value="review" />
              <BasicStepper.Trigger title="Final review" value="submit" />
            </BasicStepper.List>

            <BasicStepper.Content value="review">
              {({ goNextStep }) => (
                <ReviewStep
                  onGoNextStep={goNextStep}
                  unblockRoute={unblockRoute}
                />
              )}
            </BasicStepper.Content>

            <BasicStepper.Content value="submit">
              {({ goPrevStep }) => (
                <SubmitStep
                  unblockRoute={unblockRoute}
                  onGoPrevStep={goPrevStep}
                />
              )}
            </BasicStepper.Content>
          </BasicStepper.Root>
        </div>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withPageAccess(Page.ApproveUpdateRequest)(ApproveUpdateRequest);
