import { Navigate, useLocation } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";

import { getSearchParams } from "@ag/utils/helpers";

import FullPageSpinner from "~components/full-page-spinner";
import { addCurrentAdminToSentry } from "~config/sentry";
import { useSessionContext } from "~features/auth";
import { ampli } from "~lib/ampli";

import { useSessionRefresh } from "../hooks/use-session-refresh";

type Props = {
  children: React.ReactElement;
};
export const AuthorizedRoute = ({ children }: Props) => {
  const location = useLocation();

  const { currentAdmin, isSignedIn, isLoading } = useSessionContext();
  const { isSessionRefreshing } = useSessionRefresh();

  useEffectOnceWhen(() => {
    addCurrentAdminToSentry(currentAdmin);
    ampli.identify(currentAdmin!.email);

    return () => {
      ampli.client.reset();
    };
  }, isSignedIn);

  if (isLoading || (isSessionRefreshing && !currentAdmin)) {
    return <FullPageSpinner />;
  }

  if (!isSignedIn) {
    if (location.pathname === "/login") return null;

    const unauthorizedRedirectPath =
      "/login" +
      getSearchParams({
        redirect: location.pathname,
      });

    return <Navigate to={unauthorizedRedirectPath} replace />;
  }

  return children;
};
