import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button, Chip } from "@ag/design-system/atoms";
import { ButtonSelect, MultiSelect, Select } from "@ag/design-system/molecules";
import { typography } from "@ag/design-system/tokens";
import {
  ButtonSelectField,
  InputField,
  MultiSelectField,
  PhoneNumberInputField,
  SelectField,
} from "@ag/form-fields";
import I18n, { getLocaleName, getLocales } from "@ag/i18n";
import { getYesNoOptions } from "@ag/utils/helpers";

import { useCountries } from "~features/countries";
import { useInitialResourcesQuery } from "~features/initial-resources";

import { useCompanyLookupQuery } from "../../api/lookup-company";
import { createUserFormSchema } from "../../schema/create-user-form-schema";
import { UserCreationConsentModal } from "../user-creation-consent-modal";
import * as styles from "./create-user-form.css";
import { getUserRolesOptions } from "./helpers";

export type CreateUserFormValues = z.infer<
  ReturnType<typeof createUserFormSchema>
>;

type Props = {
  onSubmit: (values: CreateUserFormValues) => void;
};

export const CreateUserForm = ({ onSubmit }: Props) => {
  const {
    control,
    formState: { errors, isValid },
    trigger,
    register,
    getValues,
    setValue,
    handleSubmit,
  } = useForm<CreateUserFormValues>({
    resolver: zodResolver(createUserFormSchema()),
  });

  const { data: initialResources } = useInitialResourcesQuery();
  const { data: countries } = useCountries();
  const {
    isFetching: isCompanyLookupLoading,
    refetch: onCompanyLookupRefetch,
  } = useCompanyLookupQuery(
    {
      countryCode: getValues("company.address.countryCode"),
      vatNumber: getValues("company.vatNumber"),
    },
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: async companyLookup => {
        // Update form values
        setValue("company.name", companyLookup.name);
        setValue("company.address.address", companyLookup.address);
        setValue("company.address.city", companyLookup.city);
        setValue("company.address.zipCode", companyLookup.zipCode);

        // Re-trigger UI validation for updated feilds, to eliminate already shown errors
        await trigger(
          [
            "company.name",
            "company.address.address",
            "company.address.city",
            "company.address.zipCode",
          ],
          {
            shouldFocus: true,
          },
        );
      },
    },
  );

  const [isUserCreationConsentModalOpen, setIsUserCreationConsentModalOpen] =
    useState(false);

  const handleVatLookupButtonClicked = async () => {
    const countryCodeError = errors.company?.address?.countryCode;
    const vatNumberError = errors.company?.vatNumber;

    const countryCode = getValues("company.address.countryCode");
    const vatNumber = getValues("company.vatNumber");

    const isCountryCodeValid = countryCodeError === undefined && countryCode;
    const isVatNumberValid = vatNumberError === undefined && vatNumber;

    if (!isCountryCodeValid || !isVatNumberValid) {
      // Trigger UI validation, await is needed here for proper form refresh
      await trigger(["company.address.countryCode", "company.vatNumber"], {
        shouldFocus: true,
      });

      return;
    }

    onCompanyLookupRefetch();
  };

  const yesNoOptions = getYesNoOptions();
  const userRolesOptions = getUserRolesOptions();
  const locales = getLocales();
  const currenciesOptions = initialResources?.currencies;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className={styles.formSection}>
        <h2 className={clsx(typography.h4, styles.sectionHeader)}>
          {I18n.t("js.admin.create_user.profile_section_title")}
        </h2>

        <fieldset className={styles.fieldset}>
          <InputField
            {...register("email")}
            label={I18n.t("js.admin.create_user.form_attribute.email")}
            error={errors.email}
          />

          <span />

          <InputField
            {...register("password")}
            label={I18n.t("js.admin.create_user.form_attribute.password")}
            error={errors.password}
            type="password"
          />

          <InputField
            {...register("confirmPassword")}
            label={I18n.t(
              "js.admin.create_user.form_attribute.password_confirmation",
            )}
            error={errors.confirmPassword}
            type="password"
          />

          <InputField
            {...register("firstName")}
            label={I18n.t("js.admin.create_user.form_attribute.first_name")}
            error={errors.firstName}
          />

          <InputField
            {...register("lastName")}
            label={I18n.t("js.admin.create_user.form_attribute.last_name")}
            error={errors.lastName}
          />

          <PhoneNumberInputField
            label={I18n.t("js.admin.create_user.form_attribute.phone_number")}
            control={control}
          />

          <Controller
            name="preferredCurrency"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t(
                  "js.admin.create_user.form_attribute.preffered_currency",
                )}
              >
                {currenciesOptions?.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.value}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="locale"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.admin.create_user.form_attribute.locale")}
              >
                {locales?.map(locale => (
                  <Select.Option key={locale} value={locale}>
                    {getLocaleName(locale)}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="acceptedTerms"
            control={control}
            render={({ field, fieldState }) => (
              <ButtonSelectField
                {...field}
                error={fieldState.error}
                label={I18n.t(
                  "js.admin.create_user.form_attribute.accept_terms",
                )}
              >
                {yesNoOptions.map(option => (
                  <ButtonSelect.Option
                    key={option.identifier}
                    value={option.value}
                  >
                    {option.label}
                  </ButtonSelect.Option>
                ))}
              </ButtonSelectField>
            )}
          />

          <Controller
            name="acceptedNewsletter"
            control={control}
            render={({ field, fieldState }) => (
              <ButtonSelectField
                {...field}
                error={fieldState.error}
                label={I18n.t(
                  "js.admin.create_user.form_attribute.accept_newsletter",
                )}
              >
                {yesNoOptions.map(option => (
                  <ButtonSelect.Option
                    key={option.value.toString()}
                    value={option.value}
                  >
                    {option.label}
                  </ButtonSelect.Option>
                ))}
              </ButtonSelectField>
            )}
          />

          <Controller
            name="createCarbonUser"
            control={control}
            render={({ field, fieldState }) => (
              <ButtonSelectField
                {...field}
                error={fieldState.error}
                label={I18n.t(
                  "js.admin.create_user.form_attribute.create_carbon_user",
                )}
              >
                {yesNoOptions.map(option => (
                  <ButtonSelect.Option
                    key={option.identifier}
                    value={option.value}
                  >
                    {option.label}
                  </ButtonSelect.Option>
                ))}
              </ButtonSelectField>
            )}
          />

          <span />
        </fieldset>
      </section>

      <section className={styles.formSection}>
        <h2 className={clsx(typography.h4, styles.sectionHeader)}>
          {I18n.t("js.admin.create_user.company_section_title")}
        </h2>

        <fieldset className={styles.fieldset}>
          <MultiSelectField
            control={control}
            name="company.roles"
            label={I18n.t("js.admin.create_user.form_attribute.user_role")}
            renderSelection={selection =>
              selection?.length > 0 ? (
                <span className={styles.multiSelectValueContainer}>
                  {selection.map(selectedOption => (
                    <Chip key={String(selectedOption)} variant="info">
                      {selectedOption.props.textValue}
                    </Chip>
                  ))}
                </span>
              ) : null
            }
          >
            {userRolesOptions?.map(userRoleOption => (
              <MultiSelect.Item
                key={userRoleOption.value}
                textValue={userRoleOption.label}
              >
                {userRoleOption.label}
              </MultiSelect.Item>
            ))}
          </MultiSelectField>

          <Controller
            name="company.address.countryCode"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                onChange={e => {
                  /**
                   * When manually validating with a 'trigger',
                   * the trigger must be activated again after
                   * changing values to clear errors
                   */
                  field.onChange(e);
                  trigger("company.address.countryCode");
                }}
                error={fieldState.error}
                label={I18n.t(
                  "js.admin.create_user.form_attribute.country_code",
                )}
              >
                {countries?.map(country => (
                  <Select.Option key={country.code} value={country.code}>
                    {country.name}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <InputField
            {...register("company.vatNumber")}
            onChange={e => {
              /**
               * When manually validating with a 'trigger',
               * the trigger must be activated again after
               * changing values to clear errors
               */
              register("company.vatNumber").onChange(e);
              trigger("company.vatNumber");
            }}
            label={I18n.t("js.admin.create_user.form_attribute.vat_number")}
            error={errors.company?.vatNumber}
            type="number"
          />

          <div className={styles.fetchCompanyButtonContainer}>
            <Button
              type="button"
              variant="secondary"
              isLoading={isCompanyLookupLoading}
              onClick={handleVatLookupButtonClicked}
            >
              {I18n.t("js.admin.create_user.lookup_button")}
            </Button>
          </div>

          <InputField
            {...register("company.name")}
            label={I18n.t("js.admin.create_user.form_attribute.company_name")}
            error={errors?.company?.name}
          />

          <InputField
            {...register("company.address.address")}
            label={I18n.t("js.admin.create_user.form_attribute.address")}
            error={errors?.company?.address?.address}
          />

          <InputField
            {...register("company.address.city")}
            label={I18n.t("js.admin.create_user.form_attribute.city")}
            error={errors.company?.address?.city}
          />

          <InputField
            {...register("company.address.zipCode")}
            label={I18n.t("js.admin.create_user.form_attribute.zip_code")}
            error={errors.company?.address?.zipCode}
          />
        </fieldset>
      </section>

      <footer className={styles.footer}>
        <Button
          type="button"
          onClick={() => setIsUserCreationConsentModalOpen(true)}
          disabled={!isValid}
        >
          {I18n.t("js.shared.create")}
        </Button>
      </footer>

      <UserCreationConsentModal
        isOpen={isUserCreationConsentModalOpen}
        onClose={() => setIsUserCreationConsentModalOpen(false)}
        onSubmit={() => {
          handleSubmit(onSubmit)();
          setIsUserCreationConsentModalOpen(false);
        }}
      />
    </form>
  );
};
