import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SCard } from "@ag/components/styled";
import { H5Typography, H6Typography, style } from "@ag/ui";

import IconInProgress from "~assets/icon-in-progress.svg";

type SPartialBoxProps = {
  isErrorVisible?: boolean;
};

export const SPartialBox = styled(SCard)`
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 0;
  width: 100%;
  margin-bottom: 8px;
  margin-left: 0;
  padding: 0;

  word-wrap: break-word;
  background-clip: border-box;
  border: 2px solid transparent;

  ${({ isErrorVisible }: SPartialBoxProps): string => {
    let styles = ``;

    if (isErrorVisible) {
      styles += `border: 2px solid ${style.color.red[600]};`;
    }

    return styles;
  }};

  .collapse-box-collapse {
    transition: height 300ms;
  }
`;

export const SCollapseBoxHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const SCollapseBoxContent = styled.div`
  padding: 0 16px 16px;
`;

export const SCollapseBoxTitle = styled.span`
  ${H5Typography};
`;

export const SUnsavedIndicator = styled.span`
  ${H6Typography};

  text-transform: uppercase;
  margin-right: 10px;
  color: ${style.color.grey[400]};
`;

export const SGreenIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 24px;
  border-radius: 50%;
  background: ${style.color.opal[100]};
`;

export const SCheckIcon = styled(FontAwesomeIcon)`
  font-size: 10px;
  color: ${style.color.green[400]};
`;

export const SProgressIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 9px;
  margin-right: 24px;
  border-radius: 50%;
  color: ${style.color.white[100]};
  background: ${style.color.orange[100]};
`;

export const SLockIcon = styled(FontAwesomeIcon)`
  font-size: 10px;
  color: ${style.color.green[400]};
`;

type SToggleButtonProps = {
  isOpened: boolean;
};

export const SToggleButton = styled.button`
  display: flex;
  transition: transform 300ms;
  border: 0;
  background: transparent;
  padding: 0;
  color: ${style.color.green[500]};

  ${({ isOpened }: SToggleButtonProps) => {
    let styles = ``;

    if (isOpened) {
      styles += `transform: rotate(180deg);`;
    }

    return styles;
  }}

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`;

export const STitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const SInProgressIcon = styled(IconInProgress)`
  width: 16px;
  height: 16px;
`;
