import {
  OnChangeFn,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";

import {
  ButtonCell,
  ButtonCellValue,
  ChipCell,
  ChipCellValue,
  DateCell,
  DateCellValue,
  LinkCell,
  LinkCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useTable } from "~components/table";

import { ValidationRequest } from "../entities/validation-request";
import { getValidationRequestStatusLabel } from "../helpers/lookups";
import { ValidationRequestStatus } from "../types";

type TableData = {
  id: string;
  userId: LinkCellValue;
  userEmail: string;
  harvestYear: number;
  status: ChipCellValue;
  requestedFields: LinkCellValue;
  validatedFields: LinkCellValue;
  rejectedFields: LinkCellValue;
  sentAt: DateCellValue;
  haValidation: number;
  actions: ButtonCellValue;

  validationRequest: ValidationRequest;
};

type UseValidationRequestsTableState = {
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
};

export const useValidationRequestsTable = (
  data: ValidationRequest[] | undefined,
  state?: UseValidationRequestsTableState,
) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => String(original.validationRequest.id),
    enablePinning: true,
    state: {
      sorting: state?.sorting,
      columnPinning: {
        left: ["actions"],
      },
    },
    onSortingChange: state?.setSorting,
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("actions", {
      header: "Actions",
      cell: ButtonCell,
      size: 110,
    }),
    columnHelper.accessor("id", {
      header: I18n.t("js.admin.admins.id"),
    }),
    columnHelper.accessor("userId", {
      header: I18n.t("js.shared.user_id"),
      cell: LinkCell,
    }),
    columnHelper.accessor("userEmail", {
      header: I18n.t("js.shared.user_email"),
    }),
    columnHelper.accessor("harvestYear", {
      header: I18n.t("js.admin.harvest_year"),
    }),
    columnHelper.accessor("status", {
      header: I18n.t("js.admin.validation_status"),
      cell: ChipCell,
    }),
    columnHelper.accessor("haValidation", {
      header: I18n.t("js.validation_requests.ha_validation"),
    }),
    columnHelper.accessor("requestedFields", {
      header: I18n.t("js.admin.requested_fields"),
      cell: LinkCell,
    }),
    columnHelper.accessor("validatedFields", {
      header: I18n.t("js.carbon.validated_fields"),
      cell: LinkCell,
    }),
    columnHelper.accessor("rejectedFields", {
      header: I18n.t("js.admin.rejected_fields"),
      cell: LinkCell,
    }),
    columnHelper.accessor("sentAt", {
      header: I18n.t("js.admin.nudging.sent_at"),
      cell: DateCell,
    }),
  ];
}

function getRowData(
  validationRequests: ValidationRequest[] | undefined,
): TableData[] {
  if (!validationRequests) return [];

  const validationRequestStatusChip: Record<
    ValidationRequestStatus,
    ChipCellValue
  > = {
    new: {
      label: getValidationRequestStatusLabel(ValidationRequestStatus.New),
      variant: "info",
    },
    resolved: {
      label: getValidationRequestStatusLabel(ValidationRequestStatus.Resolved),
      variant: "success",
    },
  };

  return validationRequests.map(validationRequest => {
    const validationRequestFields = groupBy(
      validationRequest.carbonFields,
      carbonField => carbonField.validationStatus,
    );

    return {
      id: validationRequest.id,
      actions: {
        to:
          validationRequest.status === "new"
            ? `/carbon/validation-requests/${validationRequest.id}/fields`
            : null,
        variant: "primary",
        children: I18n.t("js.admin.validation_request.list.process"),
      },
      userId: {
        url: `/users/${validationRequest.user.id}`,
        title: validationRequest.user.id,
      },
      userEmail: validationRequest.user.email,
      harvestYear: validationRequest.harvestYear,
      status: validationRequestStatusChip[validationRequest.status],
      haValidation: validationRequest.haUnderValidation,
      requestedFields:
        [
          ...(validationRequestFields.new ?? []),
          ...(validationRequestFields.in_progress ?? []),
        ].map(field => ({
          url: `/carbon/fields/${field.id}`,
          title: field.id,
        })) ?? [],
      validatedFields:
        validationRequestFields.validated?.map(field => ({
          url: `/carbon/fields/${field.id}`,
          title: field.id,
        })) ?? [],
      rejectedFields:
        validationRequestFields.rejected?.map(field => ({
          url: `/carbon/fields/${field.id}`,
          title: field.id,
        })) ?? [],
      sentAt: validationRequest.createdAt,

      validationRequest,
    };
  });
}
