import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Chip, ChipVariant, InfoBox } from "@ag/design-system/atoms";
import { ButtonSelect } from "@ag/design-system/molecules";
import { Card } from "@ag/design-system/organisms";

import { useGenerateOfferAgreement } from "../api/generate-offer-agreement";
import { useUpdateSalesOfferDetailsMutation } from "../api/update-sales-offer-details";
import { KYCStatus } from "../entities/sales-offer";
import {
  SalesOfferDetails,
  SignerDetails,
} from "../entities/sales-offer-details";
import { getKycStatusVariant } from "../helpers/sales-offers-statuses";
import { AgreementType } from "../types/agreement-type";
import { OfferStatus } from "../types/offer-status";
import BuyoutCalculationsCard from "./buyout-calculations-card";
import ContractStep from "./contract-step";
import KycContractDetails from "./kyc-contract-details";
import LastModifiedInfo from "./last-modified-info";
import ResaleFeesCard from "./resale-calculations-card";
import SignerDetailsForm from "./signer-details-form";

const ContractGenerationForm = ({
  id,
  status,
  accepted,
  contacted,
  signerDetails,
  kycQuestionnaireData,
  offerDetails,
  kycStatus,
  kycValid,
  metadata,
}: Pick<
  SalesOfferDetails,
  | "id"
  | "status"
  | "accepted"
  | "contacted"
  | "signerDetails"
  | "offerDetails"
  | "kycStatus"
  | "kycValid"
  | "kycQuestionnaireData"
  | "status"
  | "metadata"
>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const updateSalesOfferDetails = useUpdateSalesOfferDetailsMutation();
  const generateOfferAgreement = useGenerateOfferAgreement();

  const kycStatusVariant = getKycStatusVariant(kycStatus) as {
    variant: ChipVariant;
    label: string;
  };

  // const signerDetailsComplete = useMemo(
  //   () =>
  //     Boolean(signerDetails.title) &&
  //     Boolean(signerDetails.name) &&
  //     Boolean(signerDetails.phoneNumber) &&
  //     Boolean(signerDetails.email),
  //   [signerDetails],
  // );

  const hasVatError = useMemo(
    () => kycStatus === KYCStatus.Approved && !kycValid,
    [kycStatus, kycValid],
  );

  // const contractReady = useMemo(
  //   () =>
  //     status === OfferStatus.Open &&
  //     kycStatus === KYCStatus.Approved &&
  //     Boolean(offerDetails.contractType) &&
  //     contacted &&
  //     accepted &&
  //     signerDetailsComplete &&
  //     signerDetails.verified &&
  //     kycQuestionnaireData &&
  //     kycValid,
  //   [
  //     status,
  //     kycStatus,
  //     offerDetails.contractType,
  //     contacted,
  //     accepted,
  //     signerDetailsComplete,
  //     signerDetails.verified,
  //     kycQuestionnaireData,
  //     kycValid,
  //   ],
  // );

  const handleContractTypeChange = (value: AgreementType) => {
    updateSalesOfferDetails.mutate({
      id,
      data: { offerDetails: { contractType: value } },
    });
  };

  const handleContactStatusChange = (value: boolean) => {
    updateSalesOfferDetails.mutate({
      id,
      data: { contacted: value },
    });
  };

  const handleOfferStatusChange = (value: boolean) => {
    updateSalesOfferDetails.mutate({
      id,
      data: { accepted: value },
    });
  };

  const handleSignerDetailsVerifiedChange = (value: boolean) => {
    updateSalesOfferDetails.mutate({
      id,
      data: { signerDetails: { ...signerDetails, verified: value } },
    });
  };

  const handleSetSignerDetails = (data: Omit<SignerDetails, "verified">) => {
    updateSalesOfferDetails.mutate({
      id,
      data: { signerDetails: data },
    });
  };

  const handleContractGeneration = () => {
    generateOfferAgreement.mutate(
      { id },
      {
        onSuccess: ({ data }) => {
          navigate(`/carbon/agreements/new/${data.id}`, {
            state: {
              ...location.state,
              totalPurchaseAmount: offerDetails.totalPurchaseAmount,
              type: offerDetails.contractType,
            },
          });
        },
      },
    );
  };

  const isOptionDisabled = (option: AgreementType) => {
    const { allowedContractType } = offerDetails;
    return !allowedContractType.includes(option);
  };

  const hasAgreementOption = (option: AgreementType) =>
    offerDetails.contractType === option ||
    (!offerDetails.contractType &&
      offerDetails.allowedContractType.includes(option));

  const suggestedContractDescription = useMemo(() => {
    const { suggestedContractType, allowedContractType } = offerDetails;
    const capitalize = (type: string) =>
      type.charAt(0).toUpperCase() + type.slice(1);

    const selectAgreementPrefix =
      allowedContractType.includes(AgreementType.Resale) &&
      allowedContractType.includes(AgreementType.Buyout)
        ? "Select agreement type, "
        : "";

    if (suggestedContractType.length === 0) {
      return `${selectAgreementPrefix}There is no recommended agreement for this user`;
    }

    const types = suggestedContractType.map(capitalize);
    const agreementType =
      types.length > 1 ? `${types.join(" and ")} are` : `${types[0]} is`;

    return `${selectAgreementPrefix}${agreementType} the recommended agreement for this user`;
  }, [offerDetails]);

  const offerNotOpen = status !== OfferStatus.Open;
  const isResaleAgreement = offerDetails.contractType === AgreementType.Resale;

  return (
    <section>
      <h3 className="mb-4 text-h3 text-grey-900">
        Verify details to generate contract
      </h3>

      <div className="flex flex-col gap-7">
        <div className="relative">
          <Card className="w-full" testid="agreement-type">
            <ContractStep
              title="Agreement type"
              description={suggestedContractDescription}
              completed={Boolean(offerDetails.contractType)}
            >
              <ButtonSelect.Root
                size="small"
                value={offerDetails.contractType}
                onChange={handleContractTypeChange}
                isDisabled={offerNotOpen}
              >
                <ButtonSelect.Option
                  value={AgreementType.Resale}
                  isDisabled={isOptionDisabled(AgreementType.Resale)}
                  aria-label="resale agreement"
                >
                  Resale
                </ButtonSelect.Option>

                <ButtonSelect.Option
                  value={AgreementType.Buyout}
                  isDisabled={isOptionDisabled(AgreementType.Buyout)}
                  aria-label="buyout agreement"
                >
                  Buyout
                </ButtonSelect.Option>
              </ButtonSelect.Root>
            </ContractStep>

            {hasAgreementOption(AgreementType.Resale) && (
              <ResaleFeesCard id={id} status={status} {...offerDetails} />
            )}
            {hasAgreementOption(AgreementType.Buyout) && (
              <BuyoutCalculationsCard {...offerDetails} />
            )}
          </Card>
          {isResaleAgreement && (
            <LastModifiedInfo
              owner={metadata?.lastModified?.offerDetails?.by.email}
              date={metadata?.lastModified?.offerDetails?.at}
            />
          )}
        </div>

        <div className="relative">
          <Card className="w-full" testid="contact-status">
            <ContractStep
              title="Contact status"
              description="Has the farmer been contacted by Customer Success?"
              completed={contacted}
            >
              <ButtonSelect.Root
                onChange={handleContactStatusChange}
                value={contacted}
                size="small"
                isDisabled={offerNotOpen}
              >
                <ButtonSelect.Option value={true} aria-label="contacted">
                  Yes
                </ButtonSelect.Option>

                <ButtonSelect.Option value={false} aria-label="not contacted">
                  No
                </ButtonSelect.Option>
              </ButtonSelect.Root>
            </ContractStep>
          </Card>
          <LastModifiedInfo
            date={metadata.lastModified?.contacted?.at}
            owner={metadata.lastModified?.contacted?.by.email}
          />
        </div>

        <div className="relative">
          <Card className="w-full" testid="offer-acceptance">
            <ContractStep
              title="Offer acceptance"
              description="Has the offer been accepted by the farmer?"
              completed={accepted}
            >
              <ButtonSelect.Root
                onChange={handleOfferStatusChange}
                value={accepted}
                size="small"
                isDisabled={offerNotOpen}
              >
                <ButtonSelect.Option value={true} aria-label="offer accepted">
                  Yes
                </ButtonSelect.Option>

                <ButtonSelect.Option
                  value={false}
                  aria-label="offer not accepted"
                >
                  No
                </ButtonSelect.Option>
              </ButtonSelect.Root>
            </ContractStep>
          </Card>
          <LastModifiedInfo
            date={metadata.lastModified?.accepted?.at}
            owner={metadata.lastModified?.accepted?.by.email}
          />
        </div>

        <div className="relative">
          <Card className="w-full" testid="signer-details">
            <>
              <ContractStep
                title="Signer details"
                description="Have the following signer details been verified with the farmer?"
                completed={signerDetails.verified}
              >
                <ButtonSelect.Root
                  isDisabled={offerNotOpen}
                  onChange={handleSignerDetailsVerifiedChange}
                  value={signerDetails.verified}
                  size="small"
                >
                  <ButtonSelect.Option
                    value={true}
                    aria-label="signer details verified"
                  >
                    Yes
                  </ButtonSelect.Option>

                  <ButtonSelect.Option
                    value={false}
                    aria-label="signer details not verified"
                  >
                    No
                  </ButtonSelect.Option>
                </ButtonSelect.Root>
              </ContractStep>

              <SignerDetailsForm
                {...signerDetails}
                editingDisabled={offerNotOpen || signerDetails.verified}
                onSubmit={handleSetSignerDetails}
              />
            </>
          </Card>
          <LastModifiedInfo
            date={metadata.lastModified?.signerDetails?.at}
            owner={metadata.lastModified?.signerDetails?.by.email}
          />
        </div>

        <div
          className="rounded-lg border border-grey-300 bg-transparent px-6 py-4"
          data-testid="kyc-status"
        >
          <div className="flex items-end justify-between">
            <div>
              <h4 className="pb-1 text-h4">KYC Status</h4>

              <p className="text-p2 text-grey-600">
                The status is generated by Avallone (a third party) and must be
                &ldquo;Approved&rdquo; in order to generate the contract.
              </p>
            </div>

            <Chip
              size="large"
              variant={kycStatusVariant.variant}
              data-testid="kyc-status-chip"
            >
              {kycStatusVariant.label}
            </Chip>
          </div>

          {kycStatus === KYCStatus.Approved && (
            <KycContractDetails {...kycQuestionnaireData} />
          )}

          {hasVatError && (
            <div className="mt-4 border-t border-grey-300 pt-4">
              <InfoBox icon="warning" variant="danger">
                The VAT number reviewed in the KYC process does not match the
                data on the benefits contract. A new benefits contract must be
                signed to generate buyout/resales agreements.
              </InfoBox>
            </div>
          )}
        </div>
      </div>

      <div className="mt-10 flex items-center justify-between gap-6 rounded bg-grey-300 px-6 py-4">
        <h5 className="w-full max-w-md text-h5">
          Review and select appropriate responses for each section before
          generating an agreement.
        </h5>

        <Button
          icon="file-contract"
          variant="primary"
          // TODO [ASS-267] => Enable contract generation
          disabled={true}
          // disabled={!contractReady}
          isLoading={generateOfferAgreement.isLoading}
          onClick={handleContractGeneration}
          aria-role="button"
          aria-label="Generate contract"
        >
          Generate contract in Docusign
        </Button>
      </div>
    </section>
  );
};

export default ContractGenerationForm;
