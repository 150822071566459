import styled from "@emotion/styled";

import { H8 } from "@ag/components/styled";
import { media } from "@ag/ui";

type SPartialsGroupTitleProps = {
  marginTop?: boolean;
};

export const SPartialsGroupTitle = styled(H8)`
  margin-bottom: 8px;

  ${({ marginTop }: SPartialsGroupTitleProps): string => {
    let styles = ``;

    if (marginTop) {
      styles += `
        margin-top: 16px;
      `;
    }

    return styles;
  }}
`;

export const SFieldDefinitionTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${media.md`
    flex-direction: column-reverse;
    padding-bottom: 32px;
  `}
`;
