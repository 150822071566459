import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Label } from "@ag/design-system/atoms";
import { typography } from "@ag/design-system/tokens";
import { stack } from "@ag/design-system/utils";
import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";
import {
  RejectModal,
  generateCertificateGroupsQueryKey,
  generateUpdateRequestsQueryKey,
  useCancelUpdateRequest,
  useSubmitUpdateRequestMutation,
  useUpdateRequestQuery,
  useUpdateRequestStore,
  useUpdateRequestSummaryQuery,
} from "~features/certificate";

import * as pageStyles from "../../styles.css";
import * as styles from "./submit.css";

type Props = {
  onGoPrevStep: () => void;
  unblockRoute: () => void;
};

const SubmitStep = ({ onGoPrevStep, unblockRoute }: Props) => {
  const navigate = useRef(useNavigate());

  const { requestId } = useParams<{ requestId: string }>();

  const { data: updateRequest } = useUpdateRequestQuery(requestId!);
  const { data: summary } = useUpdateRequestSummaryQuery(requestId);
  const submitUpdateRequestMutation = useSubmitUpdateRequestMutation();

  const cancelUpdateRequest = useCancelUpdateRequest();

  const { isRejectModalVisible, setIsRejectModalVisible } =
    useUpdateRequestStore();

  const handleRejectRequest = () => {
    try {
      unblockRoute();
      cancelUpdateRequest.mutate(requestId, {
        onSuccess: () => {
          navigate.current("/ledger");
          ToastNotification.success("Request rejected successfully");
        },
      });
      setIsRejectModalVisible(false);
    } catch (error) {
      ToastNotification.error(error);
    }
  };

  const handleOpenRejectModal = () => {
    setIsRejectModalVisible(true);
  };

  const handleSubmit = () => {
    if (!requestId) return;

    submitUpdateRequestMutation.mutate(requestId, {
      onSuccess: () => {
        ToastNotification.success(
          `${
            updateRequest?.action === "transfer" ? "Transfer" : "Retirement"
          } request has been approved`,
        );

        // Invalidate groups and update requests queries
        queryClient.invalidateQueries(generateUpdateRequestsQueryKey());
        queryClient.invalidateQueries(generateCertificateGroupsQueryKey());

        unblockRoute();
        navigate.current("/ledger");
      },
    });
  };

  return (
    <>
      {isRejectModalVisible && (
        <RejectModal onRejectTransfer={handleRejectRequest} />
      )}

      <div className={styles.container}>
        <div className={stack({ gap: 24 })}>
          <h2 className={pageStyles.title}>Review before submitting</h2>
          <p className={typography.p2} style={{ textAlign: "center" }}>
            Are you sure you want to perform this action?
          </p>
        </div>

        <div className={styles.card}>
          <div>
            <Label>Total certificates</Label>
            <h2 className={typography.h2}>{summary?.quantity ?? 0}</h2>
          </div>

          <div style={{ textAlign: "center" }}>
            <Label>
              {updateRequest?.action === "transfer" ? "Transferred" : "Retired"}{" "}
              from:
            </Label>
          </div>

          <div>
            <div className={styles.senderRecipient}>
              <div className={stack({ gap: 4 })}>
                <div
                  className={styles.userBox()}
                  data-testid="beneficiary-owner-original"
                >
                  Agreena
                </div>
              </div>

              <span>to</span>

              <div className={stack({ gap: 4 })}>
                <div
                  className={styles.userBox({ isActive: true })}
                  data-testid="beneficiary-owner-new"
                >
                  {updateRequest?.certificateBeneficiary?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className={pageStyles.footer}>
        <div className={pageStyles.prevButtonArea}>
          <Button
            icon="chevron-left"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className={pageStyles.nextButtonArea}>
          <div className={pageStyles.nextButtonAndRejectButtonArea}>
            <Button
              variant="secondary"
              isDanger
              onClick={handleOpenRejectModal}
            >
              Reject
            </Button>
            <Button
              isLoading={submitUpdateRequestMutation.isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </footer>
    </>
  );
};

export default SubmitStep;
