import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { StringParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { parseSorting } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import {
  CropTypesFilters,
  useCropTypesQuery,
  useCropTypesTable,
} from "~features/crop-type";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const filtersConfig = {
  name: [StringParam, { isDebounced: true }],
  coolfarmName: [StringParam, { isDebounced: true }],
  coolfarmIdentifier: [StringParam, { isDebounced: true }],
  harvestYear: StringParam,
  methodologyVersion: StringParam,
} as const;

const CropTypes = () => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);
  const [pagination, updatePagination, resetPagination] = usePagination();

  const filters = useFilters(filtersConfig);

  const {
    name,
    coolfarmName,
    coolfarmIdentifier,
    harvestYear,
    methodologyVersion,
  } = filters.values;

  const { data: cropTypesData, isLoading } = useCropTypesQuery({
    ...pagination,
    filters: {
      matchCropTypeName: name,
      matchCoolfarmName: coolfarmName,
      coolfarmIdentifier,
      harvestYear: harvestYear ? Number(harvestYear) : undefined,
      methodologyVersion,
    },
    sort: parseSorting(sorting),
  });

  const table = useCropTypesTable(cropTypesData?.data, {
    sorting,
    setSorting,
  });

  const handlePaginationChanged = (value: {
    limit?: number;
    page?: number;
  }) => {
    updatePagination(value);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Crop types</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <CropTypesFilters
              {...filters}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
              }}
              onClear={() => {
                filters.onClear();
                resetPagination();
              }}
            />
          </ListLayout.Filters>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={cropTypesData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChanged}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default CropTypes;
