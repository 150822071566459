import { H4 } from "@ag/components/styled";
import I18n from "@ag/i18n";

import Layout from "~components/Layout";
import { useSessionContext } from "~features/auth";

import { STitle } from "./styled";

const LandingPage = () => {
  const { currentAdmin } = useSessionContext();

  return (
    <Layout>
      <STitle>
        {I18n.t("js.landing_page.admin_greeting", {
          name: currentAdmin?.email,
        })}
      </STitle>

      <H4>
        {I18n.t("js.landing_page.read_more")}
        &nbsp;
        <a
          href="https://www.notion.so/agreena/Intro-to-Admin-6c86ce6144bf491da007075d5baaf978"
          rel="noreferrer"
          target="_blank"
        >
          {I18n.t("js.shared.here")}
        </a>
      </H4>
    </Layout>
  );
};

export default LandingPage;
