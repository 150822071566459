
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/atoms/buttons/button-themes.css.ts", "@ag/design-system");
      import { assignVars, createThemeContract } from "@vanilla-extract/css";
import { colors } from "~tokens";
export const themeVars = createThemeContract({
  background: "",
  backgroundHover: "",
  backgroundActive: "",
  backgroundDisabled: "",
  color: "",
  colorHover: "",
  colorActive: "",
  colorDisabled: ""
});
export const primaryTheme = assignVars(themeVars, {
  background: colors.green[500],
  backgroundHover: colors.green[700],
  backgroundActive: colors.green[900],
  backgroundDisabled: colors.grey[200],
  color: colors.white[100],
  colorHover: colors.white[100],
  colorActive: colors.white[100],
  colorDisabled: colors.grey[700]
});
export const primaryDangerTheme = assignVars(themeVars, {
  background: colors.red[700],
  backgroundHover: colors.red[800],
  backgroundActive: colors.red[900],
  backgroundDisabled: colors.grey[200],
  color: colors.white[100],
  colorHover: colors.white[100],
  colorActive: colors.white[100],
  colorDisabled: colors.grey[700]
});
export const primaryDarkTheme = assignVars(themeVars, {
  background: colors.white[100],
  backgroundHover: colors.white[100],
  backgroundActive: colors.white[100],
  backgroundDisabled: colors.grey[300],
  color: colors.green[500],
  colorHover: colors.green[500],
  colorActive: colors.green[500],
  colorDisabled: colors.grey[800]
});
export const primaryDangerDarkTheme = assignVars(themeVars, {
  background: colors.red[500],
  backgroundHover: colors.red[600],
  backgroundActive: colors.red[700],
  backgroundDisabled: colors.grey[300],
  color: colors.white[100],
  colorHover: colors.white[100],
  colorActive: colors.white[100],
  colorDisabled: colors.grey[800]
});
export const secondaryTheme = assignVars(themeVars, {
  background: "transparent",
  backgroundHover: colors.opal[100],
  backgroundActive: colors.opal[200],
  backgroundDisabled: "transparent",
  color: colors.green[500],
  colorHover: colors.green[700],
  colorActive: colors.green[900],
  colorDisabled: colors.grey[700]
});
export const secondaryDangerTheme = assignVars(themeVars, {
  background: "transparent",
  backgroundHover: colors.red[100],
  backgroundActive: colors.red[200],
  backgroundDisabled: "transparent",
  color: colors.red[700],
  colorHover: colors.red[800],
  colorActive: colors.red[900],
  colorDisabled: colors.grey[700]
});
export const secondaryDarkTheme = assignVars(themeVars, {
  background: "transparent",
  backgroundHover: "transparent",
  backgroundActive: "transparent",
  backgroundDisabled: "transparent",
  color: colors.white[100],
  colorHover: colors.white[100],
  colorActive: colors.white[100],
  colorDisabled: colors.grey[500]
});
export const secondaryDangerDarkTheme = assignVars(themeVars, {
  background: "transparent",
  backgroundHover: colors.red[100],
  backgroundActive: colors.red[200],
  backgroundDisabled: "transparent",
  color: colors.red[700],
  colorHover: colors.red[800],
  colorActive: colors.red[900],
  colorDisabled: colors.grey[500]
});
      endFileScope();
      
    