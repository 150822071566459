import { useCallback, useState } from "react";
import { StringParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Confirmation, Modal } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import Table from "~components/table";
import {
  AdminsFilters,
  useAdminsQuery,
  useAdminsTable,
  useDeleteAdminMutation,
} from "~features/admin";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const Admins = () => {
  const [adminToDelete, setAdminToDelete] = useState<{
    id: string | null;
    email: string | null;
  }>({
    id: null,
    email: null,
  });

  const [pagination, updatePagination, resetPagination] = usePagination();
  const filters = useFilters({
    email: [StringParam, { isDebounced: true }],
    role: StringParam,
  });

  const { role, email } = filters.values;

  const { data: adminsData, isLoading } = useAdminsQuery({
    ...pagination,
    filters: {
      email,
      adminRoles: role ? [role] : undefined,
    },
  });
  const deleteAdminMutation = useDeleteAdminMutation();

  const handleDeleteAdminClicked = useCallback(
    ({ id, email }: { id: string; email: string }) => {
      setAdminToDelete({ id, email });
    },
    [],
  );

  const handleAdminDeletionConfirmed = () => {
    if (!adminToDelete.id) return;

    deleteAdminMutation.mutate(adminToDelete.id, {
      onSuccess: () => {
        ToastNotification.success("The admin account has been deleted");
        setAdminToDelete({ id: null, email: null });
      },
    });
  };

  const table = useAdminsTable({
    data: adminsData?.items,
    onAdminDelete: handleDeleteAdminClicked,
  });

  return (
    <>
      <ListLayout.Root>
        <ListLayout.TopBar>
          <ListLayout.TopBarTitle>Admins</ListLayout.TopBarTitle>

          <ListLayout.TopBarActions>
            <Button to="/admins/add">New</Button>
          </ListLayout.TopBarActions>
        </ListLayout.TopBar>

        <ListLayout.Sidebar>
          <AuthorizedSidebar />
        </ListLayout.Sidebar>

        <ListLayout.Content>
          <ListLayout.Header>
            <ListLayout.Filters>
              <AdminsFilters
                {...filters}
                onChange={(...args) => {
                  filters.onChange(...args);
                  resetPagination();
                }}
                onClear={() => {
                  filters.onClear();
                  resetPagination();
                }}
              />
            </ListLayout.Filters>
          </ListLayout.Header>

          <Table
            instance={table}
            meta={adminsData?.meta}
            pagination={pagination}
            isLoading={isLoading}
            onPaginationChange={updatePagination}
          />
        </ListLayout.Content>
      </ListLayout.Root>

      <Modal.Root
        isOpen={Boolean(adminToDelete.id)}
        size="small"
        onRequestClose={() => setAdminToDelete({ id: null, email: null })}
      >
        <Modal.Content>
          <Confirmation.Root variant="danger">
            <Icon name="warning" fontSize="46" className="text-red-800" />

            <Confirmation.Title key="title">Delete admin</Confirmation.Title>

            <Confirmation.Description key="description">
              Are you sure you want to delete admin &quot;
              {adminToDelete.email || ""}&quot;?
            </Confirmation.Description>

            <Confirmation.Actions key="actions">
              <Confirmation.Cancel
                onClick={() => setAdminToDelete({ id: null, email: null })}
                variant="text"
              >
                Cancel
              </Confirmation.Cancel>
              <Confirmation.Confirm
                onClick={handleAdminDeletionConfirmed}
                isDanger
              >
                Confirm
              </Confirmation.Confirm>
            </Confirmation.Actions>
          </Confirmation.Root>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default Admins;
