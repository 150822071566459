import { format, isValid, parse } from "date-fns";
import isNil from "lodash/isNil";

import { Icon } from "@ag/design-system/assets";

export type ChangesCellValue = Record<string, [unknown, unknown]>;

type ChangeCellProps = {
  getValue: () => ChangesCellValue;
};

function ChangesCell({ getValue }: ChangeCellProps) {
  const changes = getValue();

  if (!changes) {
    return "-";
  }

  return (
    <div className="flex flex-col space-y-1.5">
      {Object.keys(changes).map(key => {
        const [oldValue, newValue] = changes[key];

        const formattedOldValue = formatChangeValue(oldValue);
        const formattedNewValue = formatChangeValue(newValue);

        return (
          <div
            key={key}
            className="flex flex-wrap items-center gap-1 break-words text-p3"
          >
            <span className="mr-1 rounded-sm border border-current px-1">
              {key}
            </span>

            <del className="text-red-600">{formattedOldValue}</del>

            <Icon name="arrow-right" className="font-light text-grey-800" />

            <ins className="text-green-600 no-underline">
              {formattedNewValue}
            </ins>
          </div>
        );
      })}
    </div>
  );
}

export const formatChangeValue = (value: unknown): string => {
  if (isNil(value)) {
    return "null";
  }

  if (Array.isArray(value) || typeof value === "object") {
    return JSON.stringify(value);
  }

  const parsedDate = parse(
    String(value),
    "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
    new Date(),
  );

  if (isValid(parsedDate)) {
    return format(parsedDate, "dd-MM-yyyy HH:mm");
  }

  return String(value);
};

export default ChangesCell;
