import { MAP_STYLES } from "@ag/map/constants";
import { style } from "@ag/ui";

export const MAP_OPTIONS: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  styles: [
    ...MAP_STYLES,
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: style.color.white[100],
        },
      ],
    },
  ],
  gestureHandling: "greedy",
  controlSize: 18,
  mapTypeId: "hybrid",
};
