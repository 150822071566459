import { z } from "zod";

export enum ContractType {
  Fixed = "fixed",
  Vesting = "vesting",
  V2022 = "v2022",
  NovationV2022 = "novation-v2022",
}

export const CreditingPeriodYears = [5, 10];

export enum ContractStatus {
  Signed = "signed",
  Sent = "sent",
  Withdrawn = "withdrawn",
  // TODO: ask API if "expired" is still valid
  Expired = "expired",
  Terminated = "terminated",
}

export enum RemovalReason {
  Churn = "churn",
  Duplicate = "duplicate",
}

export enum LockInPeriod {
  YearToYear = "year_to_year",
}

export enum Payouts {
  Vesting5Years = "vesting_5_years__10_15_20_25_30",
  YearToYear = "year_to_year_100_percent",
}

export enum Sanctions {
  Repayment3Years = "repayment_of_up_to_three_years",
  RetainFuturePayouts = "we_retain_future_payouts_for_all_years",
}

export const ContractSchema = z.object({
  id: z.string(),
  buffer: z.number(),
  carbonContractGroupId: z.string(),
  carbonContractGroup: z.object({
    carbonUserId: z.string(),
    contractType: z.nativeEnum(ContractType),
    id: z.string(),
    year: z.number(),
  }),
  creditingPeriod: z.number(),
  docusignEnvelopeId: z.string().nullable(),
  issuanceFee: z.number(),
  lockInPeriod: z.nativeEnum(LockInPeriod).nullable(),
  name: z.string(),
  payouts: z.nativeEnum(Payouts).nullable(),
  sanctions: z.nativeEnum(Sanctions).nullable(),
  contractUrl: z.string().nullable(),
  termsUrl: z.string().nullable(),
  createdAt: z.string(),
  deliveredAt: z.string().nullable(),
  expiresAt: z.string().nullable(),
  updatedAt: z.string(),
  sentAt: z.string().nullable(),
  signedAt: z.string().nullable(),
  companyVatNumber: z.string(),
  signerEmail: z.string(),
  signerPhoneNumber: z.string(),
  carbonUserId: z.string(),
  carbonFields: z.array(
    z.object({
      id: z.string(),
      sizeHa: z.number(),
      carbonFarmId: z.string(),
      name: z.string(),
    }),
  ),
  uncontractedFields: z.array(
    z.object({
      id: z.string(),
      carbonContractid: z.string(),
      carbonFieldId: z.string(),
      churnedAt: z.string().nullable(),
      removalReason: z.nativeEnum(RemovalReason),
      hasDocument: z.boolean(),
    }),
  ),
  contractType: z.nativeEnum(ContractType),
  userId: z.string(),
  totalFieldsSize: z.number(),
  status: z.nativeEnum(ContractStatus).nullable(),
});

export type Contract = z.infer<typeof ContractSchema>;
