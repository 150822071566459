import { z } from "zod";

import { FieldValidationStatus } from "~features/field";

import { ValidationRequestStatus } from "../types";

export const ValidationRequestSchema = z.object({
  id: z.string(),
  harvestYear: z.number(),
  status: z.nativeEnum(ValidationRequestStatus),
  createdAt: z.string(),
  haUnderValidation: z.number(),
  carbonFields: z.array(
    z.object({
      id: z.string(),
      validationStatus: z.nativeEnum(FieldValidationStatus),
      carbonFarm: z.object({
        id: z.string(),
        name: z.string(),
      }),
    }),
  ),
  user: z.object({
    id: z.string(),
    email: z.string(),
  }),
});

export type ValidationRequest = z.infer<typeof ValidationRequestSchema>;
