import { cva } from "class-variance-authority";
import { createContext, isValidElement, useContext } from "react";

import { Button, ButtonProps } from "~atoms";
import { cn } from "~utils";

export type ConfirmationVariant =
  | "success"
  | "warning"
  | "neutral"
  | "info"
  | "danger";

type RootProps = {
  variant: ConfirmationVariant;
  children: React.ReactElement<
    typeof Title | typeof Description | typeof Actions
  >[];
  className?: string;
};

const ConfirmationContext = createContext<{
  variant: ConfirmationVariant | undefined;
}>({
  variant: undefined,
});

export const Root = ({ variant, children, className }: RootProps) => (
  <ConfirmationContext.Provider value={{ variant }}>
    <div className={cn("flex flex-col items-center gap-4 p-6", className)}>
      {children}
    </div>
  </ConfirmationContext.Provider>
);

/* -------------------------------------------------------------------------------------------------
 * Title
 * -----------------------------------------------------------------------------------------------*/
type TitleProps = {
  children: string;
};

export const Title = ({ children }: TitleProps) => {
  const { variant } = useContext(ConfirmationContext);

  return (
    <h3
      className={cva("text-h3", {
        variants: {
          variant: {
            success: "text-green-500",
            warning: "text-orange-900",
            neutral: "text-gray-900",
            info: "text-blue-800",
            danger: "text-red-800",
          },
        },
      })({ variant })}
    >
      {children}
    </h3>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Description
 * -----------------------------------------------------------------------------------------------*/
type DescriptionProps = {
  children: string | string[];
};

export const Description = ({ children }: DescriptionProps) => (
  <p className="text-center text-p2">{children}</p>
);

/* -------------------------------------------------------------------------------------------------
 * Actions
 * -----------------------------------------------------------------------------------------------*/
type ActionsProps = {
  children: React.ReactElement<typeof Confirm | typeof Cancel>[];
};

export const Actions = ({ children }: ActionsProps) => {
  if (
    isValidElement(children) &&
    children.type !== Confirm &&
    children.type !== Cancel
  )
    throw new Error(
      "Confirmation.Actions: Only Confirmation.Confirm and/or Confirmation.Cancel is allowed as a child",
    );

  return <footer className="mb-2 flex gap-4">{children}</footer>;
};

/* -------------------------------------------------------------------------------------------------
 * Confirm
 * -----------------------------------------------------------------------------------------------*/
export const Confirm = (props: ButtonProps) => (
  <Button variant="primary" size="medium" {...props} />
);

/* -------------------------------------------------------------------------------------------------
 * Cancel
 * -----------------------------------------------------------------------------------------------*/
export const Cancel = (props: ButtonProps) => (
  <Button variant="secondary" size="medium" {...props} />
);
