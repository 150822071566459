import { useState } from "react";

import { Icon } from "@ag/design-system/assets";
import {
  Button,
  FileRejection,
  IconButton,
  InfoBox,
  Tooltip,
} from "@ag/design-system/atoms";
import { colors, typography } from "@ag/design-system/tokens";
import { stack } from "@ag/design-system/utils";
import { DropzoneField } from "@ag/form-fields";

import {
  DocumentationFile,
  useImportIsoVcuStore,
  useRemoveDocumentationIsoVcuMutation,
  useUploadDocumentationIsoVcuMutation,
} from "~features/import-vcu";

import * as pageStyles from "../../styles.css";
import * as styles from "../../upload-documentation.css";

type Props = {
  onGoNextStep: () => void;
  onGoPrevStep: () => void;
};

const UploadIsoDocumentation = ({ onGoNextStep, onGoPrevStep }: Props) => {
  const { setDocumentation, documentation, removeDocumentation, id } =
    useImportIsoVcuStore();
  const [filesError, setFilesError] = useState<string[]>([]);
  const uploadDocumentationIsoVcu = useUploadDocumentationIsoVcuMutation();
  const removeDocumentationIsoVcu = useRemoveDocumentationIsoVcuMutation();

  const handleFileDropped = (
    acceptedFiles: File[] | undefined,
    fileRejections: FileRejection[] | undefined,
  ) => {
    if (acceptedFiles?.length === 0 || fileRejections?.length === 0 || !id)
      return;
    setFilesError([]);

    if (fileRejections) {
      const errors = fileRejections.flatMap(fileRejection =>
        fileRejection.errors.map(error => {
          if (error.code === "file-too-large") {
            return "File is too large, please upload a file smaller than 500KB";
          }
          if (error.code === "file-invalid-type") {
            return "Invalid file type, please upload a pdf file";
          }

          return error.message;
        }),
      );

      setFilesError(currentErrors =>
        Array.from(new Set([...currentErrors, ...errors])),
      );
    }

    if (acceptedFiles) {
      const fileNames = new Set(documentation.map(doc => doc.filename));

      const files = acceptedFiles.filter(file => !fileNames.has(file.name));

      if (files.length > 0 && id) {
        uploadDocumentationIsoVcu.mutate(
          { id, files },
          {
            onSuccess: documentationFiles => {
              setDocumentation(documentationFiles);
            },
          },
        );
      }
    }
  };

  const deleteDocumentation = (file: DocumentationFile) => {
    if (!id) return;

    removeDocumentationIsoVcu.mutate(
      { id, fileId: file.id },
      {
        onSuccess: () => {
          removeDocumentation(file);
        },
      },
    );
  };

  return (
    <section className={pageStyles.root}>
      <div className={styles.container}>
        <h2 className={typography.h2}>Select ISO project documentation</h2>
        {filesError?.map(fileError => (
          <InfoBox key={fileError} variant="danger" icon="triangle-exclamation">
            {fileError}
          </InfoBox>
        ))}

        <div className={styles.dropzoneBox}>
          <DropzoneField
            onChange={handleFileDropped}
            isMultiple
            // 5MB
            maxSize={5 * 1024 * 1024}
            accept=".pdf"
            label=""
            value={undefined}
          >
            <div
              key="content"
              className={stack({
                justify: "center",
                align: "center",
                gap: 16,
              })}
            >
              <Icon
                name="file-pdf"
                fontSize={32}
                style={{
                  color: colors.grey[700],
                }}
              />

              <div className={styles.dropzoneBoxContent}>
                <h5 className={typography.h5}>
                  Drag and drop ISO project documentation{" "}
                </h5>
                <h6
                  className={typography.h6}
                  style={{
                    color: colors.grey[700],
                  }}
                >
                  PDF format only
                  <Tooltip
                    content="These project documents are required, and will be accessible to the general public in the Public Registry to communicate project methodology."
                    variant="rich"
                  >
                    <Icon
                      name="circle-info"
                      fontSize={14}
                      style={{
                        paddingLeft: 4,
                      }}
                    />
                  </Tooltip>
                </h6>
              </div>

              <span className={typography.h5}>or</span>

              <Button
                type="button"
                size="small"
                variant="text"
                onClick={() => null}
              >
                Select from folder
              </Button>
            </div>
          </DropzoneField>
        </div>

        {documentation && documentation?.length > 0 && (
          <div>
            <h5
              className={typography.h5}
              style={{
                textAlign: "center",
                margin: "8px 0px 16px 0px",
              }}
            >
              Uploded files
            </h5>

            <div className={styles.detailsBox}>
              {documentation.map(file => (
                <div
                  key={file.filename}
                  className={styles.documentationUploadedRow}
                >
                  <div className={styles.documentationUploadedRowContent}>
                    <h5
                      key={file.filename}
                      className={`${styles.documentationUploadedRowContentText} ${typography.h6}`}
                      data-testid="documentation-file-name"
                    >
                      {file.filename}
                    </h5>
                    <IconButton
                      type="button"
                      size="x-small"
                      variant="text"
                      onClick={() => deleteDocumentation(file)}
                      isDanger
                      icon="trash"
                      data-testid="documentation-delete-button"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <footer className={pageStyles.footer}>
        <div className={pageStyles.prevButtonBox}>
          <Button
            type="button"
            icon="chevron-left"
            iconPosition="before"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className={pageStyles.nextButtonBox}>
          <Button
            type="button"
            icon="chevron-right"
            iconPosition="after"
            onClick={onGoNextStep}
            disabled={documentation.length === 0}
            isLoading={uploadDocumentationIsoVcu.isLoading}
          >
            Next
          </Button>
        </div>
      </footer>
    </section>
  );
};

export default UploadIsoDocumentation;
