import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { MultiSelect } from "@ag/design-system/molecules";
import { stack } from "@ag/design-system/utils";
import {
  InputField,
  MultiSelectField,
  PhoneNumberInputField,
  formatParsePhoneNumber,
  getPhoneNumberPrefix,
} from "@ag/form-fields";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";
import { UserRole } from "@ag/utils/types";

import Card from "~components/card";
import { useAdminRolesQuery } from "~features/admin";
import { useUpdateUserMutation } from "~features/user";
import { User } from "~features/user/entities/user";
import { ampli } from "~lib/ampli";

const formSchema = z.object({
  email: z.string().email(),
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  phoneNumber: z.string().nonempty(),
  phoneCountryCode: z.string().nonempty(),
  roles: z.array(z.nativeEnum(UserRole)).nonempty(),
});

type FormValues = z.infer<typeof formSchema>;

type Props = {
  user: User;
};

export const UserEditProfileForm = ({ user }: Props) => {
  const { data: adminRoles } = useAdminRolesQuery();
  const updateUserMutation = useUpdateUserMutation();

  const parsedPhoneNumber = formatParsePhoneNumber(
    user.profile.phoneNumber ?? "",
  );

  const {
    control,
    register,
    formState,
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: user.email,
      firstName: user.profile.firstName,
      lastName: user.profile.lastName,
      phoneNumber: parsedPhoneNumber?.nationalNumber,
      phoneCountryCode: parsedPhoneNumber?.country,
      roles: user.roles,
    },
  });

  const handleSubmit = (data: FormValues) => {
    const phonePrefix = getPhoneNumberPrefix(data.phoneCountryCode);

    updateUserMutation.mutate(
      {
        ...data,
        phoneNumber: `+${phonePrefix}${data.phoneNumber}`,
        id: user.id,
      },
      {
        onSuccess: () => {
          if (data.email !== formState.defaultValues?.email) {
            ampli.adminUpdateUserEmailAddress();
          }

          ToastNotification.success(
            I18n.t("js.user_edit.profile.update_success"),
          );
        },
      },
    );
  };

  const { errors } = formState;

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <div className={stack()}>
        <Card.Root>
          <Card.Title>{I18n.t("js.user_edit.profile.title")}</Card.Title>

          <div className={stack()}>
            <InputField
              label={I18n.t("js.user_edit.profile.email.label")}
              {...register("email")}
              error={errors.email}
            />

            <InputField
              label={I18n.t("js.user_edit.profile.firstName.label")}
              {...register("firstName")}
              error={errors.firstName}
            />

            <InputField
              label={I18n.t("js.user_edit.profile.lastName.label")}
              {...register("lastName")}
              error={errors.lastName}
            />

            <PhoneNumberInputField
              control={control}
              label={I18n.t("js.user_edit.profile.phone.label")}
              {...register("phoneNumber")}
            />

            <MultiSelectField
              control={control}
              name="roles"
              label={I18n.t("js.user_edit.profile.roles.label")}
              placeholder={I18n.t("js.shared.select")}
              renderSelection={selection =>
                I18n.t("js.shared.multi_select.selection", {
                  count: selection.length,
                })
              }
              variant="full-width"
              // TODO: disabled for now till API will implement roles update
              disabled
            >
              {adminRoles?.map(role => (
                <MultiSelect.Item key={role.name} textValue={role.name}>
                  {role.name}
                </MultiSelect.Item>
              ))}
            </MultiSelectField>
          </div>
        </Card.Root>

        <Button
          type="submit"
          style={{ alignSelf: "flex-end" }}
          isLoading={updateUserMutation.isLoading}
        >
          {I18n.t("js.shared.save")}
        </Button>
      </div>
    </form>
  );
};
