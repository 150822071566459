import { ChipVariant } from "@ag/design-system/atoms";
import { ChipCellValue } from "@ag/design-system/organisms";

import { ContractStatus } from "~features/sales-offers";

export function getStatusChipVariant(status: ContractStatus) {
  const lookup: Record<ContractStatus, ChipVariant> = {
    [ContractStatus.Sent]: "info",
    [ContractStatus.Signed]: "success",
    [ContractStatus.Voided]: "neutral",
    [ContractStatus.Declined]: "danger",
    [ContractStatus.Canceled]: "neutral",
    [ContractStatus.Expired]: "neutral",
    [ContractStatus.Terminated]: "neutral",
  };

  return lookup[status];
}

export function getStatusChipText(status: ContractStatus) {
  const lookup: Record<ContractStatus, string> = {
    [ContractStatus.Sent]: "Sent",
    [ContractStatus.Signed]: "Signed",
    [ContractStatus.Voided]: "Voided",
    [ContractStatus.Declined]: "Declined",
    [ContractStatus.Canceled]: "Canceled",
    [ContractStatus.Expired]: "Expired",
    [ContractStatus.Terminated]: "Terminated",
  };

  return lookup[status];
}

export const getStatusChip = (status: ContractStatus): ChipCellValue => ({
  variant: getStatusChipVariant(status),
  label: getStatusChipText(status),
});
