import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "@ag/components/Spinner";
import { Button } from "@ag/design-system/atoms";
import { EmptyState } from "@ag/design-system/organisms";
import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import {
  TerminateAgreementButton,
  VoidAgreementButton,
} from "~components/action-agreement";
import { PdfViewer } from "~components/pdf-viewer";
import { AdminRole } from "~features/admin";
import { AuthorizedSidebar } from "~features/navigation";
import {
  useResaleAgreementDetailsQuery,
  useResaleAgreementPdfQuery,
  useVoidResaleAgreementMutation,
} from "~features/resale-agreements";
import { useTerminateResaleAgreementMutation } from "~features/resale-agreements/api/terminate-resale-agreement";
import { ContractStatus } from "~features/sales-offers";
import { formatCurrency } from "~features/sales-offers/helpers/formatters";
import useHasAdminRole from "~hooks/useHasAdminRole";
import OverviewLayout from "~layouts/overview-layout";

export const ResaleAgreement = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  if (!id) {
    throw new Error("Resale agreement ID is required");
  }

  const { data, isLoading } = useResaleAgreementDetailsQuery(id);
  const voidResaleAgreementMutation = useVoidResaleAgreementMutation();
  const terminateResaleAgreementMutation =
    useTerminateResaleAgreementMutation();
  const isCSCarbonAdmin = useHasAdminRole(AdminRole.CSCarbon);
  const isVoidable = data?.status === ContractStatus.Sent && isCSCarbonAdmin;
  const isDataEditorAdmin = useHasAdminRole(AdminRole.DataEditor);
  const canTerminateContract =
    data?.status === ContractStatus.Signed && isDataEditorAdmin;

  const contract = useMemo(
    () => data?.documents.find(doc => doc.type === "contract"),
    [data],
  );

  const { data: pdfUrl, isFetching } = useResaleAgreementPdfQuery(
    contract?.attachment.url || "",
    {
      enabled: Boolean(contract),
    },
  );

  const agreementRouteParams = useMemo(() => {
    return new URLSearchParams({
      url: contract?.attachment.url || "",
      apiSource: "node-carbon",
    });
  }, [contract]);

  const handleVoid = useCallback(
    (data: { comment: string }, options: { onSuccess: () => void }) => {
      voidResaleAgreementMutation.mutate(
        {
          id,
          reason: data.comment,
        },
        {
          onSuccess: () => {
            options.onSuccess();

            ToastNotification.success(`Resale agreement ${id} voided`);

            navigate("/carbon/agreements/resale");
          },
          onError: () => {
            ToastNotification.error(
              "There was an error voiding this agreement",
            );
          },
        },
      );
    },
    [navigate, id, voidResaleAgreementMutation],
  );

  const handleTerminate = useCallback(
    (data: { comment: string }, options: { onSuccess: () => void }) => {
      terminateResaleAgreementMutation.mutate(
        {
          id,
          reasonComment: data.comment,
        },
        {
          onSuccess: () => {
            options.onSuccess();

            ToastNotification.success(`Resale agreement ${id} terminated`);

            navigate("/carbon/agreements/resale");
          },
          onError: () => {
            ToastNotification.error(
              "There was an error terminating this agreement",
            );
          },
        },
      );
    },
    [navigate, id, terminateResaleAgreementMutation],
  );

  return (
    <OverviewLayout.Root>
      <OverviewLayout.Sidebar>
        <AuthorizedSidebar />
      </OverviewLayout.Sidebar>

      <OverviewLayout.TopBar>
        <OverviewLayout.TopBarTitle>
          Resale Agreement
        </OverviewLayout.TopBarTitle>
        <BackButton />
      </OverviewLayout.TopBar>

      {isLoading ? (
        <div className="flex h-full items-center justify-center pb-4 pt-4">
          <Spinner className="h-10" />
        </div>
      ) : data ? (
        <OverviewLayout.Content>
          <div className="grid grid-cols-2 items-center">
            <div className="flex items-center gap-4 pb-4">
              <span className="text-h3 text-grey-700">Resale Agreement ID</span>

              <span className="text-h4 text-black-900">{id}</span>
            </div>

            <div className="grid grid-flow-col gap-4 justify-self-end">
              {agreementRouteParams && (
                <Button
                  variant="secondary"
                  icon="fileArrowDown"
                  iconPosition="before"
                  size="x-small"
                  className="bg-white-100"
                  href={`/file?${agreementRouteParams}`}
                  data-testid="download-resale-agreement"
                >
                  Download Resale Agreement
                </Button>
              )}

              {isVoidable && (
                <VoidAgreementButton
                  isVoiding={voidResaleAgreementMutation.isLoading}
                  onVoid={handleVoid}
                />
              )}

              {canTerminateContract && (
                <TerminateAgreementButton
                  isTerminating={terminateResaleAgreementMutation.isLoading}
                  onTerminate={handleTerminate}
                />
              )}
            </div>
          </div>

          <div className="my-2 flex max-w-[450px] justify-between gap-2 rounded-sm bg-white-100 px-4 py-6">
            <div className="flex items-center gap-4">
              <span className="text-p2 text-grey-800">Percentage fee</span>

              <span className="text-p2 text-grey-900">
                {data?.contractDetails.brokerFee
                  ? `${data.contractDetails.brokerFee} %`
                  : "N/A"}
              </span>
            </div>

            <div className="flex items-center gap-4">
              <span className="text-p2 text-grey-800">
                Minimum price per credit
              </span>

              <span className="text-p2 text-grey-900">
                {data?.contractDetails.minimumPricePerUnit
                  ? formatCurrency(data.contractDetails.minimumPricePerUnit)
                  : "N/A"}
              </span>
            </div>
          </div>

          <PdfViewer
            title="Agreement"
            url={pdfUrl}
            isFetching={isFetching}
            errorState={
              <EmptyState.Root className="mx-auto box-content flex h-96 max-w-[300px] flex-col items-center justify-center gap-6 text-left">
                <EmptyState.Image key="image" />

                <EmptyState.Title key="title">
                  <h3 className="text-h3 text-grey-900">
                    Viewing the agreement is not possible
                  </h3>
                </EmptyState.Title>

                <EmptyState.Description key="description">
                  <p className="pb-4">
                    The document might not be loading for the following reasons:
                  </p>

                  <ul className="list-disc pb-4 pl-5">
                    <li>
                      It is a recently created agreement that is still being
                      processed.
                    </li>

                    <li>The document was manually signed.</li>

                    <li>There is a technical error.</li>
                  </ul>

                  <p>Please try again later.</p>
                </EmptyState.Description>
              </EmptyState.Root>
            }
          />
        </OverviewLayout.Content>
      ) : (
        <div>
          <EmptyState.Root className="mx-auto box-content flex h-96 max-w-[300px] flex-col items-center justify-center gap-6 text-left">
            <EmptyState.Image key="image" />

            <EmptyState.Title key="title">
              <h3 className="text-h3 text-grey-900">Agreement not found</h3>
            </EmptyState.Title>

            <EmptyState.Description key="description">
              <p className="pb-4">
                Please, go back to the resales agreements and try again.
              </p>
            </EmptyState.Description>
            <EmptyState.PrimaryButton>
              <Button
                size="small"
                variant="primary"
                onClick={() => navigate("/carbon/agreements/resale")}
              >
                Go back
              </Button>
            </EmptyState.PrimaryButton>
          </EmptyState.Root>
        </div>
      )}
    </OverviewLayout.Root>
  );
};
